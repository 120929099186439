export default {

    updateAssigneeLog (state, payload) {
        // console.log(payload)
        /*state.programData = payload
        payload.map((campus) => {
            //console.log(campus)
            state.campus.push(campus)
        })*/

    },
    setLogs (state, payload) {
        //state.logs = payload
        state.logs.push(...payload);
    },
    setTermsByYear (state, payload) {
        state.chartStatus = true
        state.termsByYear = []
        state.termsByYear.push(...payload);
    },
    setGenderByYear (state, payload) {
        state.genderChartStatus = true
        state.genderByYear = []
        state.genderByYear.push(...payload);
    },
    setColDepProgByYear (state, payload) {
        state.collegeStatus = true
        state.collegeInformationForYear = []
        state.collegeInformationForYear.push(...payload);
    },
    setPin(state, payload){
        state.pin = payload
    },
    setAgentId(state, payload){

        state.assignTo = payload !== null ? payload : ''
    },
    setIdNumber(state, payload){
        state.idnumber = payload
    },

    setAppStatuses(state, payload){
        console.log(payload)
        let zz = ""
        if(payload.length !== 0){
            for (let i = 0; i < payload.length; i++){
                //zz += payload[i]
                zz += `applicationstatus=${payload[i]}${i !== payload.length -1 ? ",": ""}`
            }
        }
        state.statuses = zz
    },
    setReportYear(state, payload){
        state.reportYear = payload.year
    }


}
