<template>
  <v-col cols="12" style="min-height: 100vh">

      <v-row  >
        <v-col cols="12" sm="12" ></v-col>
        <v-col cols="12" sm="12">
          <v-container  style="background: white; border-radius: 5px">
<!--            <v-col cols="12" xs="12">
              <div  class="d-flex justify-center">
                <img  src="../assets/heart.png" class="d-none d-lg-block ncu" alt="">
              </div>

            </v-col>
            <v-col cols="12" xs="12">
              <div class="d-flex justify-center">
                <img  class="d-lg-none ncu"  src="../assets/heart.png" alt="">
              </div>
            </v-col>-->
            <img-container />
            <v-col cols="12" >
              <h3 class="text-center heading_for_welcome" v-if="this.name !== ''" style="">Apply for Admission to {{ this.name }} </h3>
              <div class="mt-4" >
                <p>
                  Applying online at {{ this.name }} is pretty easy. An interactive guide will stay with you every step of the way,
                  and before you know it, you'll be on your way to earning an industry-current degree.
                </p>
                <p><b>Want help getting started? Contact Us</b></p>
                <p>
                  Contact an enrollment advisor to start your application for admission by calling {{ this.phone   }} or email {{ this.email }}.
                </p>
                <v-alert shaped dense text type="info" >
                  Choose which option below best suits you to get started.
                </v-alert>
              </div>
              <div >
                <v-list >
                  <v-list-item-group active-class="primary--text" >
                    <template v-for="(item, index) in items">
                      <v-list-item :key="item.id"  :to="item.url" class="listStyle">
                        <template v-slot:default="{ active }">
                          <v-list-item-content style="color: rgba(76,175,80, 1)">
                            <v-list-item-title class="text--white" ></v-list-item-title>
                            {{ item.headline }}
                                                    <v-list-item-subtitle >

                                                    </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <!--                        <v-list-item-action-text v-text="item.action"></v-list-item-action-text>-->

                            <v-icon

                                color="rgba(76,175,80, 1)"
                            >
                              mdi-chevron-right
                            </v-icon>
                          </v-list-item-action>
                        </template>
                      </v-list-item>

<!--                      <v-divider
                          v-if="index < items.length - 1"
                          :key="index"
                      ></v-divider>-->
                    </template>
                    <v-list-item  @click="$router.push('/login')" class="listStyle1 mt-4">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title class="text--white" style="color: #2196f3 !important">Take me to login</v-list-item-title>

                          <!--                        <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>-->
                        </v-list-item-content>

                        <v-list-item-action>
                          <!--                        <v-list-item-action-text v-text="item.action"></v-list-item-action-text>-->

                          <v-icon

                              color="#2196f3"
                          >
                            mdi-chevron-right
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-col>
          </v-container>
        </v-col>
        <v-col cols="12" sm="3"></v-col>
      </v-row>
  </v-col>
</template>

<script>
import ImgContainer from "@/components/imgContainer";
import {mapState} from "vuex";
export default {
  name: "Welcome",
  components: {ImgContainer},
  data: () => ({
    name: '',
    phone: '',
    email: '',
    items: [
      { id: 1, headline: "I know the name of my program", url: "/create_account_by_search" },
      //{ id: 2, headline: "I need help to find my program" },
      { id: 3, headline: "I know my area of interest but not my program", url: "/create_account_by_filter" },
    ]
  }),
  watch:{
    getSchoolInfo: {
      handler(n, o){
        console.log(n)
        this.name = n.schoolName;
        this.phone = n.admissionTelephone;
        this.email = n.admissionEmail;
      },
      deep: true
    },
    getSchoolName: {
      handler(n, o){
        //console.log(n)
        this.name = n
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      getSchoolInfo: state => state.utilities.schoolInfo,
      getSchoolLogo: state => state.utilities.schoolLogo,
      getSchoolName: state => state.utilities.schoolName,
    })
  },
  methods: {
    nextStep(choice){
      //console.log(choice)
      this.$router.push({ name: 'Create Account', query: { choice } })
    }
  },
  mounted() {
    this.$store.dispatch('utilities/GetSchoolInfo')
  }
}
</script>

<style scoped>
.bg_cover{
  background-image: url('~@/assets/bg4.jpg');
  min-height: 100vh;
}
.btn {
  text-transform: unset !important;
}
.listStyle{
  background: rgb(232, 245, 233) !important;
  border-radius: 5px;
  margin: 5px;
  color: rgba(76,175,80, 1);
}
.listStyle1{
  background: rgb(250, 250, 250) !important;
  border-radius: 5px;
  margin: 5px;

}


@media screen and (min-width:320px) {
  .heading_for_welcome{
    color: #317eac;
    font-size: 18px;
  }
  .cont {
    margin-top: 5vh;
  }
  .ncu{
    max-height: 100px;
    max-width: 100px;
    width: auto;
    height: auto;
  }
}
@media screen and (min-width:600px){
  .heading_for_welcome{
    color: #317eac;
    font-size: 20px;
  }
  .cont{
    margin-top: 10vh;
  }
  .ncu{
    max-height: 150px;
    max-width: 150px;
    width: auto;
    height: auto;
  }
}
@media screen and (min-width:1000px){
  .heading_for_welcome{
    color: #317eac;
    font-size: 24px;
  }
  .cont{
    margin-top: 15vh;
  }
  .ncu{
    max-height: 200px;
    max-width: 200px;
    width: auto;
    height: auto;
  }
}

</style>
