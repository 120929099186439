<template>
  <v-dialog
      v-model="dialog"
      max-width="600"

  >
    <template v-slot:activator="{ on, attrs }">

      <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on" text
      >
         Click here for help?
      </v-btn>
    </template>
    <v-card class="video-container">
      <iframe  :src="source"
                frameborder="0"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
               allowfullscreen></iframe>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "helpModal",
  props: {
    source: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      dialog: false,
    }
  },

}
</script>

<style scoped>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>