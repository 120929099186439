<template>
  <v-dialog
      v-model="dialog" persistent
      width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="success"
          dark
          v-bind="attrs"
          v-on="on"
      >
        New Application
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <div style="padding: 15px 0">
          <h3>
            Choose your another program
          </h3>
        </div>
      </v-card-title>
      <v-card-text>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">

            <fieldset style="border: none">

              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <div>
                    <ValidationProvider name="Area Of Interest" rules="required" v-slot="{ errors }" >

                      <v-select class="fix_overflow"
                                :error-messages="errors"
                                :items="getAreaOfInterest"
                                label="Area Of Interest"
                                hide-details="auto"
                                v-model="departmentId"
                                item-text="departmentName"
                                item-value="departmentId"
                      ></v-select>

                    </ValidationProvider>
                  </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" v-if="getDegreeLevels.length !== 0" >
                  <div>
                    <ValidationProvider name="Degree Level" rules="required" v-slot="{ errors }" >

                      <v-select  class="fix_overflow"
                                 :error-messages="errors"
                                 :items="getDegreeLevels"
                                 label="Degree Level"
                                 hide-details="auto"
                                 v-model="courseLevelId"
                                 item-text="courseLevelName"
                                 item-value="courseLevelId"
                      ></v-select>

                    </ValidationProvider>
                  </div>
                </v-col>

                <v-col cols="12" xs="12"   sm="12" md="12" v-if="getProgrammes.length !== 0" >
                  <div>
                    <ValidationProvider name="Programme Of Interest" rules="required" v-slot="{ errors }" >

                      <v-select  class="fix_overflow"
                                 :error-messages="errors"
                                 :items="getProgrammes"
                                 label="Programme Of Interest"
                                 hide-details="auto"
                                 v-model="programId"
                                 :item-text="item => item.degreeAbbreviation +' - '+ item.programName"
                                 item-value="programId" return-object
                      ></v-select>

                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12"  v-if="getCampuses.length !== 0" >
                  <div>
                    <ValidationProvider name="Campus" rules="required" v-slot="{ errors }" >

                      <v-select class="fix_overflow"
                                :error-messages="errors"
                                :items="getCampuses"
                                label="Where do you want to study?"
                                hide-details="auto"
                                v-model="campusId"
                                item-text="campusName"
                                item-value="campusId"
                      ></v-select>

                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12"  xs="12" sm="12" md="12"   v-if="getCampuses.length !== 0 ">
                  <div>
                    <ValidationProvider name="When do you want to start" rules="required" v-slot="{ errors }" >

                      <v-select
                          :error-messages="errors"
                          :items="getTerms"
                          label="Open Terms"
                          hide-details="auto"
                          v-model="termId"
                          item-text="termEntry"
                          item-value="termId"
                      ></v-select>

                    </ValidationProvider>
                  </div>
                </v-col>

              </v-row>
              <v-row>
                <v-col>
                  <div style="text-align: right">
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialog = false"
                        small
                    >
                      Cancel
                    </v-btn>
                    <v-btn

                        color="primary"
                        small
                        loading :loading="loading" :disabled="loading"
                        type="submit">{{ "Create Account"}}</v-btn>
                  </div>
                </v-col>
              </v-row>
            </fieldset>

        </form>
        </ValidationObserver>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: "createAccountModal",
  data () {
    return {
      dialog: false,
      campusId: '',
      termId: '',
      degreeId: '',
      courseLevelId: '',
      departmentId: '',
      programId: '',
      loading: false,
    }
  },
  methods: {

    onSubmit () {

      let self = this

        this.loading = true
        this.$store.dispatch('user/CreateApplicantPin', {
          emailAddress: '',
          campusId: this.campusId,
          degreeId: this.degreeId,
          departmentId: this.departmentId,
          termId: this.termId,
          programId: this.programId?.programId

        }).then(response => {
          this.loading = false
          this.modalTrigger = true
          console.log(response)
          localStorage.setItem('token', response.info.token)
          this.$store.commit('user/setPin')
          let self = this;
          setTimeout(function () {
            self.$store.dispatch('user/GetApplicantPins')
          }, 1000)

          this.dialog = false
        }).catch(error => {
          this.loading = false
          //console.log(error)
          this.text = error.info
          this.snackbar = true
          this.dialog = false
        })

    }
  },
  watch: {
    // whenever question changes, this function will run
    departmentId: function (newArea, oldArea) {
      //console.log(newArea)
      this.$store.dispatch('program/SetDegreeLevel',{
        id: this.departmentId
      })
      if(newArea !== this.ProgramGet.departmentId) {
        this.courseLevelId = ''
        this.campusId = ''
        this.programId = ''
      }
    },
    courseLevelId: function (newDg, oldDg) {

      this.$store.dispatch('program/SetProgramOfInterest', {
        id: this.courseLevelId
      })
      //alert("change")
      if(newDg !== this.ProgramGet.courseLevelId) {
        this.programId = ''
        this.campusId = ''
        this.termId = ''
      }
    },

    programId: function(newVal, oldVal) {
      //console.log(this.programId)
      //console.log(this.programId)
      this.degreeId = this.programId?.degreeId
      //alert(newVal)
      this.$store.dispatch('program/setCampuses',{
        programId: (typeof this.programId === "object") ? this.programId?.programId : this.programId
      })
      /* if (this.programId && this.campusId ){
         this.$store.dispatch('program/GetOpenTerms', {
           campusId: this.campusId,
           programId: this.programId
         })
       }*/
      if(newVal !== this.ProgramGet.programId?.programId){
        this.campusId = ''
        this.termId = ''
      }

    },
    campusId: function (newCampus, oldCampus) {
      if ((typeof this.programId === "object") ? this.programId?.programId : this.programId && this.campusId ){
        this.$store.dispatch('program/GetOpenTerms', {
          campusId: this.campusId,
          programId: (typeof this.programId === "object") ? this.programId?.programId : this.programId
        })

      }
      if(newCampus !== this.ProgramGet.campusId){
        this.termId = ''
      }
    },

  },
  computed: {
    ...mapState({

      getAreaOfInterest: state => state.program.programDataByAreaOfInterest,
      getGenders:  state => state.utilities.gender,
      getCampuses: state => state.program.campus,
      getDegreeLevels: state => state.program.degreeLevel,
      getDepartments: state => state.program.departments,
      getProgrammes: state => state.program.programmes,
      getTerms: state => state.program.terms
    }),
    ...mapGetters ({
      ProgramGet: 'user/programInfoGetter'
    })
  }
}
</script>

<style scoped>

</style>
