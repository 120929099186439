<script>
import ProspectDepartmentAssign from "@/components/admin/prospectDepartmentAssign.vue";
import ProspectCollegeAssign from "@/components/admin/prospectCollegeAssign.vue";

export default {
  name: "ProspectSetting",
  components: {ProspectCollegeAssign, ProspectDepartmentAssign},
  data () {
    return {
      tab: null
    }
  }
}
</script>

<template>
  <div class="pa-3">
    <h4>Prospect Settings</h4>
    <v-tabs
        v-model="tab"
        color="blue accent-4"
    >
      <v-tab href="#department">Department</v-tab>
      <v-tab href="#college">College</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item id="department">
        <ProspectDepartmentAssign />
      </v-tab-item>
    </v-tabs-items>
    <v-tabs-items v-model="tab">
      <v-tab-item id="college">
        <ProspectCollegeAssign />
      </v-tab-item>
    </v-tabs-items>

  </div>
</template>

<style scoped>

</style>