<template>
  <div>
     <div class="content">
       <h3>Your are being logged out. Please wait ...</h3>
       <div class="d-flex justify-center">
         <v-progress-circular
                              :size="70"
                              :width="7"
                              color="blue"
                              indeterminate
         ></v-progress-circular>
       </div>
     </div>
  </div>
</template>

<script>
export default {
  name: "Logout",
  mounted() {
    localStorage.removeItem('token')
    localStorage.removeItem('user_pin')
    localStorage.removeItem('isStaff')
    setTimeout(function () {
      location.replace("/login")
    }, 2000)
  }
}
</script>

<style scoped>
.content{
  position: absolute;
  width: auto;
  height: auto;
  padding: 30px;
  border-radius: 15px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white;
}
</style>
