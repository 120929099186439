<script>
import {
  Chart as ChartJS,
    ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar, Doughnut } from 'vue-chartjs'
import {mapGetters, mapState} from "vuex";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

export default {
  name: 'GenderReport',
  components: {
    Bar, Doughnut
  },
  props: {

  },
  data() {
    return {
      data: {
        labels: ['Male', 'Female'],
        datasets: [{
          data: [40, 20] ,
          hoverOffset: 4,
          backgroundColor: [
            'rgb(1, 87, 155)',
            'rgb(255, 235, 59)',
            'rgb(255, 135, 39)',
          ],
        }]
      },

      options: {
        responsive: true,


      }
    }
  },
  computed: {
    chartData() {
      return {
        labels: this.genderNames,
        datasets: [ {
          data: this.genderValues,
          label: 'Total Students by Terms ',
          backgroundColor: [
            'rgb(1, 87, 155)',
            'rgb(255, 235, 59)',
            'rgb(255, 135, 39)',
          ],
        } ]
      }
    },
    ...mapState({
      genders: state => state.staff.genderByYear,
      status: state => state.staff.genderChartStatus
    }),
    ...mapGetters({
      genderNames: 'staff/genderByYearNames',
      genderValues: 'staff/genderByYearValues',
    })
  }
}
</script>

<template>
<div>
  <h3>Gender </h3>
  <Doughnut :data="chartData"  v-if="status" />
</div>
</template>

<style scoped>

</style>