<template>
  <div>
    <div class="d-none d-md-block  video-container">
      <iframe  :src="source"
               title="YouTube video player" frameborder="0"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
               allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "helpVideo",
  props: {
    source: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>