import { render, staticRenderFns } from "./AccountVerification.vue?vue&type=template&id=22becd20&scoped=true"
import script from "./AccountVerification.vue?vue&type=script&lang=js"
export * from "./AccountVerification.vue?vue&type=script&lang=js"
import style0 from "./AccountVerification.vue?vue&type=style&index=0&id=22becd20&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22becd20",
  null
  
)

export default component.exports