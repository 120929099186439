export default {
    async GetLogs({ state, commit, rootState}, payload){

        let pin = rootState.staff.pin !== "" ? `pin=${rootState.staff.pin},` : ""
        let agentid = rootState.staff.assignTo !== "" ? `agentid=${rootState.staff.assignTo},` : ''
        let idnumber = rootState.staff.idnumber !== "" ? `idnumber=${rootState.staff.idnumber},` : ""
        let applicationstatus = rootState.staff.statuses !== "" ? `${rootState.staff.statuses}` : ""
        const url = `Staff/GetLogSummary?pageSize=10&pageNumber=1&orderValue=&filterKeyValuePairs=${pin}${idnumber}${agentid}${applicationstatus}&searchKey=`
        await fetch(rootState.api + url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            //body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    console.log(response)
                    commit('setLogs', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },
    async AssignAgentToInterest ({ state, commit, rootState}, payload) {
        //alert(payload.interestId)
        return new Promise(async (resolve, reject) => {
            const url = `Staff/AssignInterestToAgent`
            await fetch(rootState.api + url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async (data) => {
                    //const apiData = data.json()
                    await data.json().then(response => {
                        commit("updateAssigneeLog", payload)
                        resolve(response)
                    })

                })
                .catch(error => {
                    //console.log(error);
                });

        })
    },
    async AssignAgentToInterest1({ state, commit, rootState}, payload){
        console.log(payload)
        const url = `Staff/AssignInterestToAgent`
        await fetch(rootState.api + url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    console.log(response)
                    commit('setLogs', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },
    async ReportTermByYear({ state, commit, rootState}, payload){
        console.log(payload)
        rootState.staff.chartStatus = false
        const url = `Reports/ReportTermByYear?year=${payload.year}`
        await fetch(rootState.api + url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            //body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    console.log(response)
                    commit('setTermsByYear', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },
    async ReportGenderByYear({ state, commit, rootState}, payload){
        rootState.staff.chartStatus = false
        const url = `Reports/ReportGenderByYear?year=${payload.year}`
        await fetch(rootState.api + url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            //body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    console.log(response)
                    commit('setGenderByYear', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },
    async ReportColDepProgByYear({ state, commit, rootState}, payload){
        rootState.staff.chartStatus = false
        const url = `Reports/ReportColDepProgByYear?year=${payload.year}`
        await fetch(rootState.api + url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            //body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    console.log(response)
                    commit('setColDepProgByYear', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },
}
