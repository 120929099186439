<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "WhatWillYouLearn",
  data: () => ({
    CurrentLearningOpportunity: '',
  }),
  props: {
    programId: {
      required: true
    }
  }
})
</script>

<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12"   >
            <div>
              <ValidationProvider name="CurrentOpportunity" rules="required" v-slot="{ errors }" >
                <v-text-field
                    label="Add Learning Opportunity"
                    :error-messages="errors"
                    solo
                    v-model="CurrentLearningOpportunity"
                ></v-text-field>
              </ValidationProvider>
            </div>
          </v-col>

        </v-row>
        <div class="pa-3">
          <div class="text-right">
            <v-btn
                color="primary"
                type="submit">Add</v-btn>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<style scoped>

</style>
