<script setup>
import {ref} from "vue";
import AdminDashboardTable from "@/components/admin/AdminDashboardTable.vue";
import AdminAppBar from "@/components/admin/AdminAppBar.vue";
import ProspectSetting from "@/components/admin/ProspectSetting.vue";

const loading = ref(false)
const tab = ref(null)
const assignToMe = ref(false)
const items = [
  "lastName",
  "pin",
  "firstName",
  "assignTo",
  "Interest Type"
]
/*const pin = ref(null)
const assignTo = ref(null)
const applicationStatus = ref(null)
const idNumber = ref(null)*/




function onClick() {

}
</script>
<script>
  import {mapState} from "vuex";

  export default {
    data(){
      return {
        loading: false,
        pin: null,
        assignTo: null,
        applicationStatus: null,
        idNumber: null,
      }
    },
    computed: {
      ...mapState ({

        getAdminAgents: state => state.user.adminAgents,
      })
    },
    methods: {

    }
  }
</script>

<template>
  <div style="background-color: #F5F5F5;">
    <AdminAppBar/>

    <v-row>
      <v-tabs
          v-model="tab"
          color="deep-purple accent-4"
          right
      >
        <v-tab href="#1">Prospects</v-tab>
        <v-tab href="#2">Settings</v-tab>


      </v-tabs>
      <v-col cols="12" sm="12" style="height: calc(100vh - 65px); overflow-y: scroll">
        <div class="pa-3">

          <v-tabs-items v-model="tab">
            <v-tab-item id="1">
              <div class="pa-3">
                <AdminDashboardTable/>
              </div>
            </v-tab-item>
            <v-tab-item id="2">
              <ProspectSetting/>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>

    </v-row>
  </div>
</template>

<style scoped>

</style>
