<template>
  <div>
    <v-app>
      <v-container fluid>
        <v-row>
          <router-view />
        </v-row>

      </v-container>

    </v-app>
  </div>
</template>

<script>
export default {
  name: "LogoutLayout"
}
</script>

<style scoped>

</style>