<template>
  <div>
    <v-app  style="background: rgba(123,198,255,255)" >
      <v-container  style="background: white">
        <router-view />
      </v-container>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "create.layout"
}
</script>

<style scoped>

</style>