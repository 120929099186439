<template>
  <v-row>
    <v-col cols="auto">
      <v-dialog
          transition="dialog-top-transition"
          max-width="600" persistent v-model="dialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed
                 class="blue--text font-weight-bold"
              v-bind="attrs"
              v-on="on"

          >
            <v-icon left>
              mdi-plus
            </v-icon>
             External Pass</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
                color="primary"
                dark
            >Add External Pass</v-toolbar>
            <v-card-text>
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onSubmit)">
                  <v-row>
                    <v-col cols="12"><br>
                      <v-alert color="info" text dense>
                        If you have not received a result for an examination attempt as yet, just enter the word <strong>'pending'</strong> in the Grade Earned section
                      </v-alert>
                    </v-col>
                    <v-col   cols="12"  xs="12" sm="12" md="6"   >
                      <div>
                        <ValidationProvider name="Exam Board" rules="required" v-slot="{ errors }" >

                          <v-select
                              :error-messages="errors"
                              :items="getExamBoards"
                              label="Exam Board"
                              hide-details="auto"
                              v-model="examBoardId"
                              item-text="name"
                              item-value="id"
                          ></v-select>

                        </ValidationProvider>
                      </div>
                    </v-col>
                    <v-col  cols="12"  xs="12" sm="12" md="6"   >
                      <div>
                        <ValidationProvider name="Exam Proficiencies" rules="required" v-slot="{ errors }" >

                          <v-select
                              :error-messages="errors"
                              :items="getExamProficiencies"
                              label="Exam Proficiency"
                              hide-details="auto"
                              v-model="examProficiencyId"
                              item-text="name"
                              item-value="id"
                          ></v-select>

                        </ValidationProvider>
                      </div>
                    </v-col>

                    <v-col   cols="12"  xs="12" sm="12" md="6"   >
                      <div>
                        <ValidationProvider name="Subject" rules="required" v-slot="{ errors }" >
                          <v-autocomplete
                              v-model="subjectId"
                              :items="getSubjects"
                              :error-messages="errors"
                              hide-details="auto"
                              clearable
                              hide-selected
                              item-text="name"
                              item-value="id"
                              label="Subject"

                          >
                          </v-autocomplete>
                        </ValidationProvider>
                      </div>
                    </v-col>


                    <v-col  cols="12"  xs="12" sm="12" md="6" >
                      <div>
                        <ValidationProvider name="Grade" rules="required" v-slot="{ errors }" >
                          <v-text-field
                              label="Grade Earned"
                              :error-messages="errors"
                              hide-details="auto"
                              v-model="grade"
                          ></v-text-field>

                        </ValidationProvider>
                      </div>
                    </v-col>
                    <v-col  cols="12"  xs="12" sm="12" md="6" >
                      <div>
                        <ValidationProvider name="Date Started" rules="required" v-slot="{ errors }" >
                          <v-menu
                              ref="yearPicker"
                              v-model="yearPicker"
                              :close-on-content-click="false"
                              :return-value.sync="year"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="year"
                                  label="Year & month of attempt"
                                  prepend-icon="mdi-calendar"
                                  hide-details="auto"
                                  :error-messages="errors"
                                  readonly
                                  name="dateStarted"
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="year"
                                type="month"
                                no-title
                                scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="yearPicker = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.yearPicker.save(year)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>

                        </ValidationProvider>
                      </div>
                    </v-col>

                  </v-row>
                  <v-row>
                    <v-col class="d-flex justify-end mt-4" >

                      <v-btn
                          text
                          @click="dialog.value = false"
                      >Cancel</v-btn>
                      <v-btn
                          color="primary"
                          type="submit"> Add</v-btn>
                    </v-col>
                  </v-row>
                </form>
              </ValidationObserver>

            </v-card-text>

          </v-card>
        </template>
      </v-dialog>
      <snackbar-notifier  :color="color" :text="text" />
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import SnackbarNotifier from "@/components/SnackbarNotifier";
export default {
  name: "addPass",
  components: {SnackbarNotifier},
  data:() => ({
    active: false,
    dialog: false,
    schoolName: '',
    examBoardId: '',
    examProficiencyId: '',
    subjectId: '',
    year: '',
    grade: '',
    yearPicker: false,
    text: '',
    snackbar: false,
    color: ''
  }),
  watch: {

  },
  computed: {
    ...mapState({
      getExamProficiencies: state => state.utilities.examProficiency,
      getExamBoards: state => state.utilities.examBoards,
      getSubjects: state => state.utilities.subjects
    }),

  },
  methods: {
    onSubmit(){
      console.log(this.year)
      let self = this;
      this.$store.dispatch('user/AddExternalPass', {

        //"pin": string,
        "examBoardId": this.examBoardId,
        //"schoolName": "string",
        //"area": "string",
        "examProficiencyId": this.examProficiencyId,
        "subjectId": this.subjectId,
        "year": parseInt(this.formatDate(this.year)),
        "grade": this.grade
      }).then(response =>{

        self.text = response.subjectName + " was successfully added "
        self.color = "success"
        self.snackbar = true
        self.active = false
        self.dialog = false
        self.$store.dispatch('user/GetExternalPasses')
      }).catch(error=> {
        self.text = "Something went wrong, please ensure that all fields are filled out properly"
        self.color = "red"
        self.snackbar = true
      })
    },
    formatDate(x){
      let y = x.split('-')

      return  y[0]
    }
  }
}
</script>

<style scoped>

</style>