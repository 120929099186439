
<script>
import {mapState} from "vuex";

export default {
  name: "MatriculationsByProgram",
  data: () => ({
    matriculations: [{matriculation: ''}]
  }),
  computed: {
    ...mapState({
      //getPrograms: state => state.program.searchPrograms,
      currentProgram: state => state.program.programInfo
    }),
  },
  methods: {
    add(index) {
      this.currentProgram.matriculations.push({ matriculation: '', programId: this.$route.params.id });
    },
    remove(index, id) {
      this.currentProgram.matriculations.splice(index, 1);
      this.$store.dispatch("program/RemoveProgramInformationMatriculation", id)
    }
  }
}
</script>

<template>
  <div style="padding: 15px; background: white">


    <v-row  v-for="(input,k) in currentProgram.matriculations" :key="k" style="margin-bottom: -30px">

      <v-col cols="12" sm="11">
        <div>
          <v-text-field
              label="Enter Requirement"
              solo
              v-model="input.matriculation"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12" sm="1">
        <v-btn color="red" class="mt-2" icon dark @click="remove(k, input.id)" v-show="k || ( !k && matriculations.length > 0)">
          <v-icon class=""  >mdi-close</v-icon>
        </v-btn>
      </v-col>


    </v-row>
    <div style="text-align: right">
      <v-btn  @click="add()" color="secondary" dark small>
        <v-icon class=" mr-3">mdi-plus</v-icon> Add
      </v-btn>
    </div>
  </div>
</template>

<style scoped>

</style>