export default {
    setGenders (state, payload) {
        state.gender = payload
    },
    setAgents (state, payload) {
        state.staffAgents = payload
    },

    setMaritalStatuses(state, payload){
        state.maritalStatuses = payload
    },
    setNationalities(state, payload){
        state.nationalities = payload
    },
    setReligions(state, payload){
        state.religions = payload
    },
    setDenominations(state, payload){
        state.denominations = payload
    },
    setRelationTypes(state, payload){
        state.relationTypes = payload
    },
    setParishes(state, payload){
        state.parishes = payload
    },
    setCountries(state, payload){
        state.countries = payload
    },
    setAvailableSchoolTypes (state, payload) {
        state.schoolTypes = []
        state.schoolTypes = payload
    },
    setAvailablePreviousSchoolsWithType (state, payload) {
        state.schoolsListByTypes = []
        state.schoolsListByTypes = payload
    },
    setAvailableExamProficiencies (state, payload) {
        state.examProficiency = []
        state.examProficiency = payload
    },
    setAvailableExamBoards (state, payload) {
        state.examBoards = []
        state.examBoards = payload
    },
    setAvailableSubjects (state, payload) {
        state.subjects = []
        state.subjects = payload
    },
    setAvailableSourceOfFunds (state, payload) {
        state.sourcesOfFunding = []
        state.sourcesOfFunding = payload
    },
    setAvailableAppDeclarations (state, payload) {
        state.declarations = []
        state.declarations = payload
    },
    setAvailablePaymentTypes (state, payload) {
        state.paymentTypes = []
        state.paymentTypes = payload
    },
    setFileTypes (state, payload) {
        state.fileTypes = payload
    },
    setSchoolInfo (state, payload) {
        state.schoolInfo = payload
    },
    setDepartments (state, payload) {
        state.departments = payload
    },
    setColleges (state, payload) {
        state.colleges = payload
    },

    filterDenominations(state, payload){
        state.filteredDenominations = state.denominations.filter(denomination => denomination.religionId === payload.id)
    },
    filterSchoolByType(state, payload){
        state.filteredSchool = []
        state.searchFilteredSchool = [];
          state.schoolsListByTypes.filter(school => {
            if (school.schoolTypeId === payload.id) {
                //console.log(school.schools)
                  state.filteredSchool = school.schools
            }
        })
        //state.filteredSchool = state.schoolsListByTypes.filter(school => school.schoolTypeId === payload.id)
    },
    filterSchoolListByQuery(state, payload){
        state.searchFilteredSchool = [];
        //console.log({fCount : state.filteredSchool.length });
        //console.log({sfCount : state.searchFilteredSchool.length });
        let list = state.filteredSchool.filter(sc => sc.schoolName.toLowerCase().includes(payload.query.toLowerCase()));
        state.searchFilteredSchool = (list <= 5)
            ? list
            : list.slice(0,5);
    }

}
