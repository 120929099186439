<template>
    <v-row style="min-height: 100vh;">
      <v-col cols="12" xs="12"  class="content">
        <v-row class="cont">
          <img-container />
          <v-col cols="12" xs="12">
            <h1 style="text-align: center;  color: #317eac;">Online Application </h1>

              <h2 class="heading_for_welcome" style="color: #555">Login</h2><br>
            <v-alert shaped dense text type="info" >Please remember to verify your account .
            </v-alert>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, handleSubmit, validate }">
              <v-form @submit.prevent="handleSubmit(onSubmit)">
                <div>
                  <ValidationProvider name="Email address" :rules="{ required: true }" v-slot="{ errors }" >
                    <v-text-field
                        label="Email Address"
                        :error-messages="errors"


                        v-model="email"
                    ></v-text-field>

                  </ValidationProvider>
                </div>
                <div >
                  <ValidationProvider name="Password" rules="required" v-slot="{ errors }" >
                    <v-text-field
                        type="password"
                        label="Password"
                        :error-messages="errors"

                        :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        v-model="password"

                    ></v-text-field>
                    <div class="d-flex justify-space-between " style="margin-top: 0px; z-index: 9999">
                      <router-link to="/resend_verification" style="font-size: 14px; text-decoration: none;">Resend Verification</router-link>
                      <router-link to="/forgot_password" style="font-size: 14px; text-decoration: none">Forgot Password</router-link>
                    </div>
                  </ValidationProvider>
                </div>
                <div style="margin-top: -15px">

                  <br>
                </div>

                <div style="text-align: center">

                  <v-btn

                      color="primary"
                      loading :loading="loading" :disabled="loading"
                      block rounded
                      type="submit"
                  >
                    Login
                  </v-btn>
                  <div style="margin-top: 5px">
                    <v-btn
                        text
                        color="primary"
                        to="/welcome"
                    >
                      I don't have an applicant <br class="d-sm-none"> account?
                    </v-btn>

                  </div>
                </div>
              </v-form>
            </ValidationObserver>
            <v-snackbar
                v-model="snackbar" multi-line
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </v-col>
        </v-row>


      </v-col>
      <snackbar-notifier :color="color" :text="text" />
    </v-row>
</template>

<script>
import SnackbarNotifier from "@/components/SnackbarNotifier";
import {mapState} from "vuex";
import ImgContainer from "@/components/imgContainer";
export default {
  name: "LoginView",
  components: {ImgContainer, SnackbarNotifier},
  data:() => ({
    imageBase: '',
    loading: false,
    email: '',
    password: '',
    hasVisiblePassword: false,
    text: '',
    snackbar: false,
    color: '',
    show1: false,
   // api: process.env.VUE_APP_API_ADDRESS
  }),
  methods: {
    onSubmit() {
      this.$refs.observer.validate()
      this.loading = true
      this.$store.dispatch('user/Login', {
        emailAddress: this.email,
        password: this.password
      }).then(response =>{

        this.text = "Login was successful"
        this.color = "success"
        this.snackbar = true
        localStorage.setItem('token', response)



        this.$store.dispatch('user/IsStaff').then(r =>{
          /*console.log(r)
          console.log(typeof r)*/
          if(r){
            this.$router.push('/admin/prospects')
            this.loading = false
          }else {
            this.$router.push('/current_application')
            this.loading = false
          }

        }).catch(error=>{

        })
      }).catch(error => {
        this.loading = false
        //console.log(error)
        this.text = error
        this.color = "red"
        this.snackbar = true
      })
    }
  },

  computed: {
    getProgress() {
      let progress = 0

      // at least one number

      if (/\d/.test(this.password)) {
        progress += 20
      }

      // at least one capital letter

      if (/(.*[A-Z].*)/.test(this.password)) {
        progress += 20
      }

      // at menons a lowercase

      if (/(.*[a-z].*)/.test(this.password)) {
        progress += 20
      }

      // more than 5 digits

      if (this.password.length >= 6) {
        progress += 20
      }

      // at least one special character

      if (/[^A-Za-z0-9]/.test(this.password)) {
        progress += 20
      }

      return progress
    },
    ...mapState({

    })
  },
}
</script>

<style scoped>
.bg_cover{
  background-image: url('~@/assets/bg4.jpg');
  min-height: 100vh;
}
.content {
 /* position: absolute;
  width: auto;
  height: auto;
  padding: 30px;
  border-radius: 15px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white;*/
}
.cont{
  margin: 15px;
  margin-top: 5vh;
  background: white;
  padding: 0;
  border-radius: 5px;

}
img{
  max-width: 400px;
  max-height: 400px;
  height: auto;
  width: auto;
}

@media screen and (min-width: 320px) {
  img {
    max-width: 500px;
    max-height: 500px;
    height: auto;
    width: auto;
  }
}

@media screen and (min-width: 1000px){
  img{
    max-width: 400px;
    max-height: 400px;
    height: auto;
    width: auto;
  }
  .heading_for_welcome{
    color: #317eac;
    font-size: 24px;
  }
  .cont{
    margin-top: 3vh;
  }
}
@media screen and (min-width: 1440px) {
  img {
    max-width: 500px;
    max-height: 500px;
    height: auto;
    width: auto;
  }
}

/*img{
  max-width: 600px;
  max-height: 400px;
  height: auto;
  width: auto;
}*/
</style>
