<template>
  <v-container>
    <v-row >
<!--      <v-col v-if="Object.keys(getSources).length === 0" cols="12">
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-col>-->
      <v-col cols="12" v-if="Object.keys(getSources).length !== 0">
<!--        <h3>Sources of Funds</h3>-->
        <div v-for="item in SourceGet">
          <v-banner
              single-line

          >



            <v-list
                three-line
                subheader
            >

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.sourceOfFundsName }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                      "Amount: $" + item.amount +
                      (item.head ? ", Organization: " + item.head : "") +
                      (item.name ? ", Sponsor Name: " + item.name : "") +
                      (item.phone ? ", Sponsor Phone: " + item.phone : "") +
                      (item.position ? ", Sponsor Type: " + item.position : "")  +
                      (item.level ? ", Sponsor Type: " + item.level : "")

                    }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>


            <template v-slot:actions>

              <div>

                <v-btn
                    color="red lighten-1" icon
                    text @click="deleteFile(item.id)"
                >
                  <v-icon size="22">mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </v-banner>

        </div>

        <snackbar-notifier  :color="color" :text="text" />

      </v-col>
      <v-col cols="12">
        <div class="d-flex justify-end">

          <v-btn
              color="primary"
              class="white--text"
          >
            Mark Complete
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import SnackbarNotifier from "@/components/SnackbarNotifier";

export default {
  name: "SourceOfFunds",
  components: {SnackbarNotifier},
  data:() => ({
    picked: null,
    loader: null,
    selectedFile:  [],
    hidden: true,
    loading: false,
    snackbar: false,
    text: '',
    color: '',
    currentFileType: null,

  }),
  methods: {


    deleteFile(x){
      this.$store.dispatch('user/DeleteApplicantSourceOfFunds', {
        id: x
      })
          .then(response =>{
            this.text = "The source of funding was deleted"
            this.color = "success"
            this.snackbar = true
            this.active = false
            //this.$store.dispatch('user/GetSourceOfFunding')
            this.$store.dispatch('user/MoveToNextStep', {route: this.$route})
          }).catch(error=> {
            this.text = error
            this.color = "red"
            this.snackbar = true
        this.$store.dispatch('user/GetSourceOfFunding')
      })
    },

  },
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 2000)

      this.loader = null
    },
    picked: function (n, o) {
     // console.log(n)
      this.currentFileType = this.getDocumentListing.filter(item => item.documentID === n)
      //console.log(this.currentFileType[0])
    }

  },
  computed: {
    sources: function (){
      return this.$store.state.user.sourceOfFunds
    },
    ...mapState({
      getSources: state => state.user.sourceOfFunds,
      getDocuments: state => state.user.documentList
    }),
    ...mapGetters({
      SourceGet: 'user/sourceInfoGetter'
    })

  },

}
</script>

<style scoped>

</style>
