<script>
export default {
  name: "AdminAppBar"
}
</script>

<template>
  <v-app-bar
      color="#1e87f0" dense
      elevate-on-scroll
      style="height: 55px" dark
  >
    <!--      <v-app-bar-nav-icon></v-app-bar-nav-icon>-->

    <v-toolbar-title>{{ this.$route.name }}</v-toolbar-title>

    <v-spacer></v-spacer>


  </v-app-bar>
</template>

<style scoped>

</style>