import  store  from '../store'

//
export function updateProcessStep(x, y) {
    return new Promise(function(resolve, reject){
        store.dispatch('user/UpdateApplicantProcessStep',{
            "applicationProcessId": x,
            "isCompleted": y
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export function  formSubmitter(url, payload) {
    return new Promise(function(resolve, reject){
        store.dispatch(url, payload).then(response =>{
            resolve(response)
        }).catch(error =>{
            reject(error)
        })

    })
}