import { getData } from "@/services/storeService";

export default {

    async GetAdminAgents ({ state, commit, rootState }) {
        getData('Staff/GetActiveAgents', 'utilities/setAgents' )
        /*await fetch(rootState.api + 'Utilities/GetGenders')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setGenders', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetGenders ({ state, commit, rootState }) {
        getData('Utilities/GetGenders', 'utilities/setGenders' )
        /*await fetch(rootState.api + 'Utilities/GetGenders')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setGenders', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetSchoolInfo ({ state, commit, rootState }) {
        getData('Utilities/GetSchoolInfo', 'utilities/setSchoolInfo' )
        /*await fetch(rootState.api + 'Utilities/GetGenders')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setGenders', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetMaritalStatus ({ state, commit, rootState }) {
        getData('Utilities/GetMaritalStatuses', 'utilities/setMaritalStatuses' )
        /*await fetch(rootState.api + 'Utilities/GetMaritalStatuses')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setMaritalStatuses', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetNationalities ({ state, commit, rootState }) {
        getData('Utilities/GetNationalities', 'utilities/setNationalities' )
        /*await fetch(rootState.api + 'Utilities/GetNationalities')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setNationalities', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetReligions ({ state, commit, rootState }) {
        getData('Utilities/GetReligions', 'utilities/setReligions' )
        /*await fetch(rootState.api + 'Utilities/GetReligions')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setReligions', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetDenominations ({ state, commit, rootState }) {
        getData('Utilities/GetDenominations', 'utilities/setDenominations' )
        /*await fetch(rootState.api + 'Utilities/GetDenominations')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setDenominations', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetRelationTypes ({ state, commit, rootState }) {
        getData('Utilities/GetRelationTypes', 'utilities/setRelationTypes' )
        /*await fetch(rootState.api + 'Utilities/GetRelationTypes')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setRelationTypes', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetParishes ({ state, commit, rootState }) {
        getData('Utilities/GetParishesStates', 'utilities/setParishes' )
        /*await fetch(rootState.api + 'Utilities/GetParishesStates')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setParishes', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetCountries ({ state, commit, rootState }) {
        getData('Utilities/GetCountries', 'utilities/setCountries' )
        /*await fetch(rootState.api + 'Utilities/GetCountries')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setCountries', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetAvailablePreviousSchoolTypes ({ state, commit, rootState }) {
        getData('Utilities/GetAvailablePreviousSchoolTypes', 'utilities/setAvailableSchoolTypes' )
        /*await fetch(rootState.api + 'Utilities/GetAvailablePreviousSchoolTypes')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setAvailableSchoolTypes', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetAvailablePreviousSchoolsWithType ({ state, commit, rootState }) {
        getData('Utilities/GetAvailablePreviousSchoolsWithType', 'utilities/setAvailablePreviousSchoolsWithType' )
        /*await fetch(rootState.api + 'Utilities/GetAvailablePreviousSchoolsWithType')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setAvailablePreviousSchoolsWithType', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetAvailableExamProficiencies ({ state, commit, rootState }) {
        getData('Utilities/GetAvailableExamProficiencies', 'utilities/setAvailableExamProficiencies' )
        /*await fetch(rootState.api + 'Utilities/GetAvailableExamProficiencies')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setAvailableExamProficiencies', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetAvailableExamBoards ({ state, commit, rootState }) {
        getData('Utilities/GetAvailableExamBoards', 'utilities/setAvailableExamBoards' )
        /*await fetch(rootState.api + 'Utilities/GetAvailableExamBoards')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setAvailableExamBoards', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
     GetAvailableSubjects ({ state, commit, rootState }) {
        getData('Utilities/GetAvailableSubjects', 'utilities/setAvailableSubjects' )
    },
    GetAvailablePaymentTypes ({ state, commit, rootState }) {
        getData('Utilities/GetAvailablePaymentTypes', 'utilities/setAvailablePaymentTypes' )
    },
    GetDepartments ({ state, commit, rootState }) {
            getData('Utilities/GetDepartments', 'utilities/setDepartments' )
        },
    GetColleges ({ state, commit, rootState }) {
            getData('Utilities/GetCollegesSchools', 'utilities/setColleges' )
        },
    GetFileTypes ({ state, commit, rootState }) {
        getData('api/Files', 'utilities/setFileTypes' )
    },


    async GetAvailableSourceOfFunds ({ state, commit, rootState }) {
        getData('Utilities/GetAvailableSourceOfFunds', 'utilities/setAvailableSourceOfFunds' )
        /*await fetch(rootState.api + 'Utilities/GetAvailableSourceOfFunds')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setAvailableSourceOfFunds', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    async GetAvailableAppDeclarations ({ state, commit, rootState }) {
        getData('Utilities/GetAvailableAppDeclarations', 'utilities/setAvailableAppDeclarations' )
        /*await fetch(rootState.api + 'Utilities/GetAvailableAppDeclarations')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setAvailableAppDeclarations', response)
                })

            })
            .catch(error => {
                console.log(error);
            });*/
    },
    //GetAvailablePaymentTypes


    async FilterDenominations ({ state, commit, rootState }, payload) {
        commit('filterDenominations', payload)
    },
    async FilterSchoolByType ({ state, commit, rootState }, payload) {
        commit('filterSchoolByType', payload)
    },
    async FilterSchoolListByQuery({state, commit, rootState}, payload){
        commit('filterSchoolListByQuery',payload)
    }



}
