<script>
import {mapState} from "vuex";

export default {
  name: "CollegeDepartmentReport",
  computed:{
    ...mapState({
      collegeDepartment: state => state.staff.collegeInformationForYear
    })
  }
}
</script>

<template>
  <div>
<!--    <v-list
        two-line
        subheader
    >
      <v-subheader>General</v-subheader>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Profile photo</v-list-item-title>
          <v-list-item-subtitle>Change your Google+ profile photo</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Show your status</v-list-item-title>
          <v-list-item-subtitle>Your status is visible to everyone</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>-->

    <div class="pa-3">
      <h2>College/Department Breakdown </h2>
      <br><br>
      <v-row >
<!--        <v-col cols="12" >
          <div class="pa-3" v-for="(item,i) in this.collegeDepartment" :key="i">
            <div style="background-color: #777777; color: white; padding: 15px; border-radius: 5px">
              <h3>{{ item.category }}</h3>
              Total Students: {{ item.amount }}
            </div>
            <v-divider></v-divider>
            <div class="pa-3" v-for="(dep, j) in item.childCategories" :key="j">
              <div>
                <h4>Departments: {{ dep.category }}</h4>
                <div>Total Students: {{ dep.amount}}</div>
                <v-divider></v-divider>
                <div class="pa-3" v-for="(program, k) in dep.childCategories">
                  <div>
                    <h5>Programmes: {{ program.category }}</h5>
                    <h5>Total Students: {{ program.amount }}</h5>
                  </div>
                  <v-divider></v-divider>
                </div>
              </div>
            </div>
          </div>
        </v-col>-->
        <v-expansion-panels accordion multiple >
          <v-expansion-panel
              v-for="(item,i) in this.collegeDepartment"
              :key="i"
          >
            <v-expansion-panel-header style="background-color: #f5f5f5">
              <div >
                <div>
                  <h4><span style="font-size: small" class="mr-1">College: </span>{{ item.category }}</h4>
                </div>
                <div>
                  Total Students: {{ item.amount }}
                </div>
              </div>

            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <v-expansion-panels accordion>
                  <v-expansion-panel
                      v-for="(dep,j) in item.childCategories"
                      :key="j"
                  >
                    <v-expansion-panel-header style="background-color: #1976d2; color: white">
                      Department: {{ dep.category}}
                      <div>
                        Amount: {{ dep.amount }}
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <v-list two-line>
                          <template v-for="(program, index) in dep.childCategories">
                            <v-list-item
                                :key="index"
                            >
                              <v-list-item-content>
                                <v-list-item-title >Program: {{ program.category }}</v-list-item-title>
                                <v-list-item-subtitle >Amount:  {{ program.amount }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider
                            ></v-divider>
                          </template>
                        </v-list>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>

      <br><br>
    </div>
  </div>
</template>

<style scoped>
  h4{
    color: #1976d2;
  }
</style>