<template>
  <v-col style="min-height: 100vh">
    <v-container >
      <br><br>
      <v-row no-gutters>
        <v-col cols="12" sm="2"></v-col>
        <v-col cols="12" sm="8">
          <v-card
              class="pa-2"
              outlined
              tile
          >
            <div style="padding: 15px">
              <h2>Welcome back </h2>
              <p style="color: #666666; font-size: small">What do you wish to do today?</p>
            </div>
            <div v-if="this.applications.length === 0">
              <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
              <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
              <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            </div>
            <div  v-else>
              <div>
                <v-divider></v-divider>
                <div style="padding: 15px">
                  <v-row>
                    <v-col cols="12" md="8">
                      <h3 style="color: #666">Apply for a new program</h3>
                    </v-col>
                    <v-col cols="12" md="4">
                      <div style="text-align: right">
                        <create-account-modal />
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <v-divider></v-divider>
              </div>
              <v-list two-line>
                <v-list-item-group >
                  <template v-for="(item, index) in applications" >
                    <v-list-item @click="setApplicationPin(item.pin)">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.degreeAbbreviation + ' - ' + item.programName" style="font-size: large; font-weight: bold; color: #2196f3"></v-list-item-title>
                        <v-list-item-subtitle
                            class="text--primary"
                            v-text="item.campusName"
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle v-text="item.termEntry"></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text >
                          <v-icon size="32" color="red">mdi-chevron-right</v-icon>
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                        v-if="index < applications.length - 1"
                        :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-card>
        </v-col>

      </v-row>
    </v-container>

  </v-col>

</template>

<script>
import {formSubmitter} from "@/services/userService";
import {mapState} from "vuex";
import CreateAccountModal from "@/components/createAccountModal";

export default {
  name: "CurrentApplication",
  components: {CreateAccountModal},
  data: () => ({
    selected: [2],
    applications: [],
  }),
  watch: {
    pinsInfo: {
      handler(n, o){
        this.applications = n
        //console.log(n)
      },
      deep: true
    }
  },
  methods: {
    setApplicationPin(pin){
      localStorage.setItem("user_pin", pin)
      this.$router.push('/')
    }
  },
  computed: {
    ...mapState({
      pinsInfo: state => state.user.applicationPins
    })
  },
  mounted() {
    if(localStorage.getItem("user_pin") !== null){
      this.$store.commit('user/setPin')
      this.$router.push('/')
    }else{
      this.$store.dispatch('user/GetApplicantPins').then(response => {
        console.log(response)
      }).catch(error=>{
        localStorage.removeItem('token')
        this.$router.push("/login")
      })
    }


  }

}
</script>

<style scoped>

</style>
