import program from "../../../legacy/src/views/Program.vue";

export default {
    FilterProgram: (state) => async (instanceId) => {
        //console.log("Program Instance: " + instanceId)
       // console.log(state.programmes.filter(program => program.programInstanceId === instanceId))
        //return "blue"

        return state.programmes.filter(program => {
            return program.programInstanceId === instanceId
            //console.log(program)
        })

    },
    CreateListFromCommaString: (state) => async (data, programId, keyName) => {

        let x = data.split(",")
        let newArr = []
       // let b = keyName
            await x.forEach(function (info, index) {
                newArr.push({[keyName]: info, programId: programId});
            });
        return newArr
    }
}