<template>
  <div>
    <AdminAppBar />
    <div class="pa-3">
      <div>
        <v-select
            :items="years"
            label="Choose a year for report"
            dense
            solo
            v-model="year"
        ></v-select>
      </div>
      <v-row>
        <v-col cols="12" sm="4">
          <GenderReport :year="year" />
        </v-col>
        <v-col cols="12" sm="8">
          <TermReports :year="year" />
        </v-col>
        <v-col cols="12" sm="12">
          <CollegeDepartmentReport :year="year" />
        </v-col>

      </v-row>

    </div>
  </div>
</template>

<script>
import AdminAppBar from "@/components/admin/AdminAppBar.vue";
import GenderReport from "@/components/admin/GenderReport.vue";
import TermReports from "@/components/admin/TermReports.vue";
import CollegeDepartmentReport from "@/components/admin/CollegeDepartmentReport.vue";

export default {
  name: "AdminReports",
  components: {CollegeDepartmentReport, TermReports, GenderReport, AdminAppBar},
  data() {
    return {
      year: 2024,
      years: []
    }
  },
  methods: {
    getYears: function (){

      console.log(new Date().getFullYear())
      this.years.push(new Date().getFullYear())
    }
  },
  watch: {
    year: function (n, o) {
      //alert(n)
      //this.$store.commit("staff/setReportYear", { year: n})
      this.$store.dispatch("staff/ReportTermByYear", { year: this.year})
      this.$store.dispatch("staff/ReportGenderByYear", { year: this.year})
      this.$store.dispatch("staff/ReportColDepProgByYear", { year: this.year})
    }
  },
  mounted() {
    this.$store.dispatch("staff/ReportTermByYear", { year: this.year})
    this.$store.dispatch("staff/ReportGenderByYear", { year: this.year})
    this.$store.dispatch("staff/ReportColDepProgByYear", { year: this.year})
  }
}
</script>

<style scoped>

</style>