<template>
  <v-row style="min-height: 100vh">
    <div v-if="this.$route.query.code === null || this.$route.query.code === '' || this.$route.query.code === undefined && show">

      <div class="content">
        <div style="text-align: center; margin-top: 5%; color: #880000">
          No password recovery code found. Please check your email for your verification link and try again

        </div>
      </div>
    </div>
    <v-col cols="12" v-else>
      <v-row class="cont">
      <img-container />
        <!--        <v-col cols="12" xs="12">
          <div  class="d-flex justify-start">
            <img  src="../assets/ncu_logo.png" class="d-none d-lg-block" alt="">
          </div>

        </v-col>
        <v-col cols="12" xs="12">
          <div class="d-flex justify-center">
            <img  class="d-lg-none"  src="../assets/ncu_logo_small.png" alt="">
          </div>
        </v-col>-->
        <v-col cols="12" xs="12">
          <h2 class="heading_for_welcome"  style="color: #317eac">Recover Password</h2><br>
          <v-alert type="info" text dense >Please recover the information below to update your password</v-alert>
          <ValidationObserver ref="observer" v-slot="{ invalid, validated, handleSubmit, validate }">
            <v-form @submit.prevent="handleSubmit(onSubmit)">
              <div>
                <ValidationProvider name="Email address" rules="required|email" v-slot="{ errors }" >
                  <v-text-field
                      label="Email Address"
                      :error-messages="errors"
                      v-model="email"
                  ></v-text-field>

                </ValidationProvider>
              </div>
              <div >
                <ValidationProvider name="Password" rules="required" v-slot="{ errors }" >
                  <v-text-field
                      type="password"
                      label="Password"
                      :error-messages="errors"

                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      v-model="password"

                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider name="Password confirm" rules="required" v-slot="{ errors }" >
                  <v-text-field
                      type="password"
                      label="Confirm Password"
                      :error-messages="errors"

                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      v-model="confirm"

                  ></v-text-field>

                </ValidationProvider>
              </div>
              <div style="margin-top: -15px">

                <br>
              </div>

              <div style="text-align: center">

                <v-btn

                    color="primary"
                    loading :loading="loading" :disabled="loading"
                    block rounded
                    type="submit"
                >
                  Update my password
                </v-btn>
                <div style="margin-top: 5px">
                  <v-btn
                      text
                      color="primary"
                      to="/login"
                  >
                    I remember my password
                  </v-btn>

                </div>
              </div>
            </v-form>
          </ValidationObserver>
        </v-col>
        <v-dialog
            v-model="modalTrigger"
            persistent
            max-width="600"
        >

          <v-card>
            <v-card-title class="text-h5">
              Password was successfully updated
            </v-card-title>
            <v-card-text>
              <v-alert
                  text
                  color="info"
              >
                <span >Please continue to login</span>
              </v-alert>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  color="primary"
                  text
                  @click="modalTrigger = false"
                  to="/login"
              >
                Take me to Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <snackbar-notifier  :color="color" :text="text"/>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import SnackbarNotifier from "@/components/SnackbarNotifier";
import ImgContainer from "@/components/imgContainer";
export default {
  name: "AccountVerification",
  components: {ImgContainer, SnackbarNotifier},
  data:() => ({
    email: '',
    password: '',
    confirm: '',
    loading: false,
    show1: false,
    text: 'Please wait while we verify your account ...',
    color: '#42b983',
    dialog: false,
    show: false,
    modalTrigger: false
  }),

  methods: {
    onSubmit() {
      this.$refs.observer.validate()
      this.loading = true
      this.$store.dispatch('user/ChangePassword', {
        code: this.$route.query.code,
        emailAddress: this.email,
        password: this.password,
        ConfirmPassword: this.confirm
      }).then(response =>{
        this.loading = false
        this.modalTrigger = true
      }).catch(error=> {
        this.loading = false
        this.text = error
        this.color = "red"
        this.snackbar = true
      })
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
@media screen and (min-width: 320px){
  .cont{
    margin: 15px;
    margin-top: 15vh;
    background: white;
    padding: 0;
    border-radius: 5px;

  }
}
@media screen and (min-width: 600px){
  .cont{
    margin: 15px;
    margin-top: 6vh;
    background: white;
    padding: 0;
    border-radius: 5px;

  }
}

img{
  max-width: 400px;
  max-height: 400px;
  height: auto;
  width: auto;
}

</style>