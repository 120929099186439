import store from "@/store";


export function getData(url, mutation) {
    fetch(store.state.api + url)
        .then(async (data) => {
            await data.json().then(response => {
                store.commit(mutation, response)
            })
        })
        .catch(error => {
            //console.log(error);
        })
}
export function getAuthData(url, mutation){
    fetch(store.state.api + url + new URLSearchParams({ pin: store.state.user.pin }), {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("token")
        }
    }).then(async (data) =>{
        await data.json().then(response => {
            store.commit(mutation, response)
        })
    }).catch(error => {
        //console.log(error);
    })
}


export function postData(url, mutation){

}

