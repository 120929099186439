<template>
  <div>
    <v-app class="bg_cover" :style="`background-image: url(/${this.$store.state.photoUrl}) ; background-size: cover;`">
      <v-container fluid style=" background-image: linear-gradient(to right, rgba(128,0,0,.3), rgba(3,56,96,1));">
        <v-row >
          <v-col  cols="12" md="6" lg="7" class="d-none d-md-block">
            <div class="d-flex justify-center pa-4" >
              <div style="margin-top: 85vh">
                <img class="aeorion" src="../assets/aeorion_black1.png" alt="">
              </div>

            </div>
          </v-col>
          <v-col cols="12" md="6" lg="5" style="background: white" >
            <router-view />
          </v-col>

        </v-row>

      </v-container>

    </v-app>
  </div>
</template>

<script>
export default {
  name: "guest.layout",
  mounted() {
    if(localStorage.getItem("token") !== null){
      this.$router.push("/")
    }

    this.$store.dispatch('utilities/GetGenders')
    this.$store.dispatch('program/GetProgram')
    this.$store.dispatch("program/GetProgramDataForSearch")
  }
}
</script>

<style scoped>
.bg_cover{
  background-image: url('~@/assets/mico_bg3.jpg');
  background-size: cover;
}
.aeorion{
  max-height: 200px;
  max-width: 200px;
  width: auto;
  height: auto;
}
.ncu{
  max-height: 100px;
  max-width: 100px;
  width: auto;
  height: auto;
}

</style>
