export default {
    setApplicationProcess(state, payload){
       state.appProcesses = []
       state.appProcesses = payload
    },
    setPin (state, payload) {
        //state.pin = payload.pin
        state.pin = localStorage.getItem('user_pin')
    },
    setPersonalInformation (state, payload) {
        console.log(payload)
        parseInt(payload["emergencyContactRelationId"]) === 0 ? payload["emergencyContactRelationId"] = '' : ''
        parseInt(payload["religionId"]) === 0 ? payload["religionId"] = '' : ''
        state.personal = payload
    },
    setContactInformation (state, payload) {
       // console.log(payload)
        state.contact = payload
    },
    setProgram (state, payload) {
        //console.log(payload)
        state.program = payload
    },
    setPreviousSchools (state, payload) {
        state.previousSchools = []
        state.previousSchools = payload
    },
    setExternalPasses (state, payload) {
        state.externalPasses = []
        state.externalPasses = payload
    },
    setRequiredDocuments (state, payload) {
        state.documentListing = []
        state.documentListing = payload
    },
    setApplicationDocuments (state, payload) {
        state.documentList = []
        state.documentList = payload
    },

    setApplicationCost (state, payload) {
        state.applicationCost = []
        state.applicationCost = payload
    },

    setSourceOfFunds (state, payload) {
        state.sourceOfFunds = []
        state.sourceOfFunds = payload
    },
    setApplicantDeclarations (state, payload) {
        state.decs = []
        state.decs = payload
    },
    setAppDeclarations (state, payload) {
        state.declarations = []
        state.declarations = payload
    },
    setApplicantPercent (state, payload) {
        state.percent = payload
    },
    setApplicantUserInfo (state, payload) {
        state.email = payload
    },
    setApplicationPaymentStatus (state, payload) {
        state.paymentStatus = payload
    },
    setOtherDocuments (state, payload) {
        state.otherDocuments = payload
    },
    setReceiptInfo (state, payload) {
        state.receipt = payload
    },

    setApplicationOtherUploadedDocuments (state, payload) {
        state.otherUploadedDocuments = payload
    },
    setApplicantPins (state, payload) {
        state.applicationPins = payload
    },
    SetStaffStatus (state, payload) {
        if (payload){
            localStorage.setItem('isStaff', payload)
        }
        //console.log(payload)
        state.staffStatus = payload.staffStatus
    },
    setAgents (state, payload) {
        /*console.log("--------------------")
        console.log(payload)*/
        state.adminAgents = []
        state.adminAgents = payload

    },
    setCurrentNavIndex(state, payload){

        let y = state.appProcesses.filter( x => {
            return x.stepOrder === payload
        })
        /*console.log("-------------------")
        console.log(y)*/
        if (y.length > 0 ){
            state.currentPageIndex = state.appProcesses.findIndex(x =>{
                /*console.log("****************")
                console.log(x.stepOrder, " 0")
                console.log(y[0].stepOrder , "d")
                console.log("****************")*/
                return x.stepOrder === (y[0].stepOrder + 1)
            })
           // console.log(y)
            /*console.log(state.currentPageIndex)*/
            //state.currentPageIndex = (payload + 1) > state.appProcesses.length ? state.currentPageIndex : payload + 1
        }else{
            //console.log("didnt go in ")
        }

        /*if (payload.length !== 0){
            state.currentPageIndex = 1 + state.appProcesses.findIndex(x => {
                console.log(x)
                return x.processName === payload[0].processName
            })
        }*/

    }

}
