export default {
    activationLink: process.env.VUE_APP_API_ADDRESS + "AccountVerification?code=",
    pin: null,
    applicationPins: [],
    appProcesses: [],
    personal: {
        firstName: "",
        lastName: ""
    },
    contact: {},
    program: {},
    previousSchools: [],
    externalPasses: [],
    applicationCost: [],
    documentListing: [],
    documentList: [],
    decs: [],
    declarations: [],
    sourceOfFunds: [],
    percent: 0,
    paymentStatus: {
        paymentStatus: false
    },
    receipt: {},
    otherDocuments: [],
    adminAgents: [],
    otherUploadedDocuments: [],
    email: '',
    staffStatus: false,
    currentPageIndex: null
}
