<template>
  <v-app id="inspire">
    <v-overlay
        :z-index="zIndex"
        :value="overlay"
        :opacity="0.9"
        color="white"
    >
      <v-row
          class="fill-height"
          align-content="center"
          justify="center"
      >
        <v-col
            class="text-subtitle-1 text-center green--text"
            cols="12"
        >
            We are getting your information

        </v-col>
        <v-col cols="6">
          <v-progress-circular
              :size="50"
              color="green"
              indeterminate

          ></v-progress-circular>
        </v-col>
      </v-row>


    </v-overlay>
    <v-system-bar app dark  color="#081229">
      <div class="d-block d-md-flex d-lg-none">
        <span class="mr-3">Pin: </span><span  style="color: gold">{{ userPin }}</span><br>
      </div>

      <v-spacer></v-spacer>

    </v-system-bar>

    <v-navigation-drawer
        v-model="drawer"
        app dark
        color="#081229"
    >
      <v-list>
        <v-list-item>
          <div   class="d-flex justify-center ml-10 mb-4" >
            <v-img :src="'data:application/octet-stream;base64,' + this.imageBase" style="max-height: 100px; max-width: 100px; width: auto; height: auto"></v-img>
          </div>

        </v-list-item>

        <v-list-item link dense>
          <v-list-item-content>
            <v-list-item-title class="text-h9 yellow--text" >
              {{ userName.firstName + " " + userName.lastName || ''}}
            </v-list-item-title>
            <v-list-item-subtitle >Completion:
              <v-progress-linear
                  color="light-green darken-4"
                  height="10"
                  :value="appPercent"
                  striped
              ><strong>{{ Math.ceil(appPercent) }}%</strong></v-progress-linear>
              <br />
              Email: {{ appEmail }}
              <br />
              Pin: {{ userPin }}
              <br />
              Status: {{ getStatus.statusName }}</v-list-item-subtitle>
          </v-list-item-content>

        </v-list-item>
      </v-list>
<!--      <div class="d-flex justify-center mb-4">
        <img src="../assets/ncu_logo_small.png" alt="" style="max-width: 150px; max-height: 150px; height: auto; width: auto" >
      </div>-->

      <v-divider></v-divider>

      <sidebar />
    </v-navigation-drawer>

    <v-main>
      <v-container
          class="py-8 px-6"
          fluid
      >
        <v-row>
          <v-col
              cols="12"
          >

              <top-bar />
             <router-view />

          </v-col>
        </v-row>
        <CompletionPage />
      </v-container>
    </v-main>

  </v-app>

</template>

<script>
import Sidebar from "@/components/sidebar";
import TopBar from "@/components/TopBar";
import {mapState} from "vuex";
import CompletionPage from "@/views/CompletionPage";
import {bus} from "@/main";

export default {
  name: "auth.layout",
  data: () => ({
    completionStatus: false,
    percentStatus: false,
    programStatus: false,
    imageBase: '',
    overlay: false,
    zIndex: 99,
    drawer: null,
    group: null,
    links: [
      { id: 1, icon: "", text: 'Help'}
    ],
    items: [
      { title: 'Dashboard', icon: 'mdi-view-dashboard' },
      { title: 'Photos', icon: 'mdi-image' },
      { title: 'About', icon: 'mdi-help-box' },
    ],
  }),
  computed: {
    programInfo: function () {
      return this.$store.state.user.program
    },
    appCompleted: function (){

    },
    ...mapState({
      appPercent: state => state.user.percent,
      appEmail: state => state.user.email,
      userName: state => state.user.personal,
      userPin: state => state.user.pin,
      getSchoolInfo: state => state.utilities.schoolInfo,
      getProgram: state => state.user.program,
      getStatus: state => state.user.paymentStatus

    })
  },
  watch:{
    getSchoolInfo: {
      handler(n, o){
        //console.log(n)
        this.imageBase = n.logo
      },
      deep: true
    },
    appPercent:{
      handler(n, o){

        if(n === 100 && (Object.keys(this.getProgram).length !== 0 || this.getProgram)){
          this.completionStatus = true
        }
      },
      deep: true
    },
    programInfo:{
      handler(n, o){
       // console.log(Object.keys(n).length)
        //console.log(this.appPercent)
        if(this.AppPercent === 100 && Object.keys(n).length !== 0){
          this.completionStatus = true
        }
      },
      deep: true
    },
    completionStatus(n, o){
      if(n){
        setTimeout(function () {
          bus.$emit("checkCompletion", n)
        }, 1000)
      }
    }
  },
  components: {CompletionPage, TopBar, Sidebar},
  mounted() {

    if(localStorage.getItem("token") === null){
      this.$router.push("/welcome")
    }else{
      if(localStorage.getItem('user_pin') === null){
        this.$router.push('/current_application')
      }else{
        this.overlay = true
        this.$store.dispatch('user/GetApplicantPin').then(pin =>{
          //this.$store.dispatch('user/SetPin', {pin: pin})
          let self = this
          setTimeout(function (){
            self.$store.dispatch('user/GetApplicationProcesses')
            self.$store.dispatch('user/GetApplicantPercent')
            self.$store.dispatch('user/GetApplicationPaymentStatus')
            self.$store.dispatch('user/GetApplicantUserInfo')
            self.$store.dispatch('utilities/GetGenders')
            self.$store.dispatch('utilities/GetMaritalStatus')
            self.$store.dispatch('utilities/GetNationalities')
            self.$store.dispatch('utilities/GetReligions')
            self.$store.dispatch('utilities/GetDenominations')
            self.$store.dispatch('utilities/GetRelationTypes')
            self.$store.dispatch('utilities/GetParishes')
            self.$store.dispatch('utilities/GetCountries')
            self.$store.dispatch('utilities/GetAvailablePreviousSchoolTypes')
            self.$store.dispatch('utilities/GetAvailablePreviousSchoolsWithType')
            self.$store.dispatch('utilities/GetAvailableExamProficiencies')
            self.$store.dispatch('utilities/GetAvailableExamBoards')
            self.$store.dispatch('utilities/GetAvailableSubjects')
            self.$store.dispatch('utilities/GetAvailableSourceOfFunds')
            self.$store.dispatch('utilities/GetAvailablePaymentTypes')
            //self.$store.dispatch('utilities/GetFileTypes')
            self.$store.dispatch('utilities/GetSchoolInfo')
            self.$store.dispatch('user/GetApplicantDeclarations')
            self.$store.dispatch("program/GetProgram")
            self.$store.dispatch("program/GetProgramDataForSearch")
            self.$store.dispatch('user/GetApplicantRequiredDocuments')
            self.$store.dispatch('user/GetApplicantUploadedOtherDocuments')
            self.$store.dispatch('user/GetApplicantOtherDocuments')
            self.$store.dispatch('user/GetPersonalInformation')
            self.$store.dispatch('user/GetContactInformation')
            self.$store.dispatch('user/GetProgramInformation')
            self.$store.dispatch('user/GetExternalPasses')
            self.$store.dispatch('user/GetPreviousSchools')
            self.$store.dispatch('user/GetSourceOfFunding')
            self.$store.dispatch('user/GetApplicantRequiredDocuments')
            self.$store.dispatch('user/GetApplicantDocuments')
            self.$store.dispatch('user/GetApplicationCost')

            setTimeout(function () {
              self.overlay = false
            }, 4000)
            /*

             self.$store.dispatch('user/GetApplicationCost')
             self.$store.dispatch('user/GetApplicantDocuments')*/
          }, 1000)
        }).catch(error=>{
          localStorage.removeItem('token')
          this.$router.push("/login")

        })
      }

    }
  }
}
</script>

<style scoped>
  .logoStyle{
    max-width: 300px;
    max-height: 300px;
    height: auto;
    width: auto;
  }
</style>
