export default {
    logs: [],
    termsByYear: [],
    genderByYear: [],
    chartStatus: false,
    genderChartStatus: false,
    collegeStatus: false,
    collegeInformationForYear: [],
    pin: "",
    assignTo: '',
    idnumber: '',
    statuses: "",
    appStatuses: [
        "NOT SUBMITTED",
        "PENDING REVIEW",
        "PROSPECT",
        "REVIEWED",
        "ACCEPTED",
        "WITHDRAWN",
        "DENIED",
    ],
    reportYear: 2024
}
