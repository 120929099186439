export default {
    setProgramData (state, payload) {
       // console.log(payload)
        state.programData = payload
        payload.map((campus) => {
            //console.log(campus)
            state.campus.push(campus)
        })
    },
    setProgramDataForSearch (state, payload) {
        // console.log(payload)
        state.searchPrograms = payload

    },
    setProgramDataByAreaOfInterest (state, payload) {
        state.programDataByAreaOfInterest = payload

        payload.map((department) => {
            //console.log(campus)
            state.departments.push(department)
        })
    },

    setDegreeLevel (state, payload) {
        state.degreeLevel = []
        state.campus = []
        state.programmes = []
        state.terms = []
        state.programDataByAreaOfInterest.map((level) =>{
            if (level.departmentId === payload.id){
                //console.log(level.degreeLevels)
                state.degreeLevel = level.degreeLevels
            }
        })
    },
    setAreaOfInterest (state, payload) {
        //state.departments = []
        //state.programmes = []
        state.degreeLevel.map((level) =>{
            if (level.courseLevelId === payload.id){
                //console.log(level.departments)
                state.departments = level.departments
            }
        })
    },
    setProgramOfInterest (state, payload) {
        state.campus = []
        state.terms = []
        /*console.log(payload.id)
        console.log(state)*/
        state.degreeLevel.map((level) =>{
            //console.log(level)
            if (level.courseLevelId === payload.id){
                //console.log(level)
                state.programmes = level.programs
            }
        })
    },
    setCampuses (state, payload) {
        state.terms = []
        //alert(payload.programId)
        /*
        console.log(state)*/
        //console.log(payload.programId)
        //console.log(state.programmes)
        state.programmes.map((level) =>{
            /*console.log(level.programId)
            console.log(payload.programId)*/
            if (level.programId === payload.programId){
                //console.log(level)
                state.campus = level.campuses
            }
        })
    },

    setOpenTerms (state, payload) {
        state.terms = []
        state.terms = payload
    },
    setLogs (state, payload) {
        //state.logs = payload
        state.logs.push(...payload);
    },
    setCurrentLog (state, payload) {
        state.log = {}
        if (payload.length !== 0 ){
            state.log = payload[0]
        }
    },
    setCurrentProgramInfo (state, payload) {
        state.programInfo = {}
        state.programInfo = payload

    },







}
