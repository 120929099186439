<template>
    <v-col cols="12">

        <v-row cols="12" v-if="this.getPaymentStatus.paymentStatus">
            <v-col cols="12" v-if="this.getPaymentStatus.paymentTypeId === 2">
                <v-row>
                    <v-col cols="12"
                           sm="4"
                           offset-sm="4"
                           md="4"
                           offset-md="4">
                        <br><br>
                        <v-sheet elevation="4" class="pa-4" style="border-radius: 15px">
                            <div class="d-flex justify-center">
                                <v-icon color="green" size="60">mdi-check-decagram</v-icon>
                            </div>
                            <p>Paid by: {{ receipt.name }}</p>
                            <p>Order Date: {{ receipt.orderDate }}</p>
                            <p>Order Id: {{ receipt.orderId }}</p>
                            <p>Card Number: {{ receipt.paddedCardNumber }}</p>
                            <p>Ammount Paid: {{ receipt.totalPaid }}</p>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" v-else-if="getPaymentStatus.paymentTypeId === 3">
                <v-row>
                    <v-col cols="12">
                        <v-alert dense
                                 text
                                 type="success">
                            You have chosen to <strong>Submit Payment Later</strong>. Please remember this does not mean that payment is waived but that you are agreeing to
                            pay the application fee before you start classes or in-conjunction with your tuition fee.
                        </v-alert>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" v-else-if="getPaymentStatus.paymentTypeId === 1">
                <br><br>
                <v-alert dense
                         text
                         type="success">
                    Your payment receipt was uploaded. This step will now be made complete by the admissions team and your application will automatically be submitted
                    provided that you have completed all the other steps.
                    <br><br>
                    <span style="color: crimson"> If your receipt is not accepted we will re-open the payment section and alert you via email</span>
                </v-alert>
            </v-col>

        </v-row>
        <v-row v-else-if="!this.getPaymentStatus.paymentStatus">
            <v-col cols="12">
                <div>
                    The cost of your application fee is <span style="font-weight: 400; color: green">${{ getAppFee.cost + " " + getAppFee.currencyDescription + " (" + getAppFee.currencyName + ") "  }}.</span>
                </div>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
                <v-sheet class="pa-4" rounded
                         color="white"
                         elevation="2">
                    <h4>
                        How will you pay your application fee. <span style="color: #880000">
                            You won't be able to change after you submit a choice.
                        </span>
                    </h4>
                    <v-radio-group v-model="paymentChoice"
                                   row>
                        <v-radio v-for="(item, i) in getPaymentTypes" :key="i"
                                 :label="item.name"
                                 :value="item.id"></v-radio>

                    </v-radio-group>
                </v-sheet>

            </v-col>

            <v-col cols="12" v-if="paymentChoice === 3">
              <div>
                <v-alert dense
                         text
                         type="success">
                  You have chosen to submit your application without payment. This means that you will have to make your payment at a later date and not that payment is no longer required.
                </v-alert>
                <br>
                <v-btn block class="success" loading :loading="loading" :disabled="loading" @click="submitWithoutPayment">Submit without Payment</v-btn>

              </div>
            </v-col>
            <v-col cols="12" v-if="paymentChoice === 1">
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(uploadReceipt)">
                        <v-row>
                            <v-col cols="12" v-if='offlineMessage === "true"'>
                                You can pay the application fee at the following locations:
                                <ul>
                                    <li>Any Paymaster or BillExpress</li>
                                    <li>Any Bank of Nova Scotia (BNS) branch, or Scotia online bill payment</li>
                                    <li>Any National Commercial Bank (NCB) branch</li>
                                    <li>
                                        The NCU Cashier (8:15 am-4:00 pm Monday-Thursday & Friday 8:15 am-1:00 pm).<br />
                                        Payments are accepted using only bank cards, manager's cheques or cashier's cheques.<br />
                                        Cheques should be made payable to Northern Caribbean University.<br />
                                        The account number to make the payment to is: 1115166171 for undergraduate or ISGDEAN01 for graduate and post graduate applications.
                                    </li>
                                    <li>If accounts are being settled by mail, following the guidelines on cheques, letters should be addressed to the Director, Student Finance, Northern Caribbean University, Manchester Road, Mandeville, Jamaica WI.</li>
                                </ul>
                                <strong>N.B. Place your application PIN {{ getPin }} on the payment voucher.</strong>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <v-alert dense
                                         type="info"
                                         text color="primary">
                                    After you upload your receipt we will then review and update this step for you.
                                </v-alert>
                                <div>
                                    <ValidationProvider name="Document" rules="required" v-slot="{ errors }">
                                        <v-file-input show-size
                                                      label="Click to upload your payment receipt"
                                                      :error-messages="errors"
                                                      hide-details="auto"
                                                      v-model="receiptUpload" outlined></v-file-input>

                                    </ValidationProvider>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="d-flex justify-end ">
                                <v-btn :loading="loading1"
                                       :disabled="loading1"
                                       color="primary"
                                       class="ma-2 white--text"
                                       type="submit">
                                    Upload
                                    <v-icon right
                                            dark>
                                        mdi-cloud-upload
                                    </v-icon>
                                </v-btn>
                                <!--              <v-btn
                                                  color="primary"

                                                  type="submit"> Save & Next</v-btn>-->
                            </v-col>
                        </v-row>
                    </form>
                </ValidationObserver>
            </v-col>
            <v-col cols="12" v-if="paymentChoice === 2">

                <v-row>
                    <v-col cols="12">

                    </v-col>
                    <v-col cols="12">
                        <v-sheet elevation="2" class="pa-4" color="rgb(225, 245, 254)" style="border-radius: 15px">

                            <br>
                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, handleSubmit, validate }">
                                <v-form @submit.prevent="handleSubmit(onSubmit)">
                                    <v-row dense>
                                        <v-col cols="12" xs="12" sm="12" md="6">
                                            <ValidationProvider name="First name" rules="required" v-slot="{ errors }">

                                                <v-text-field label="First Name"
                                                              :error-messages="errors"
                                                              outlined
                                                              dense
                                                              v-model="firstName"></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6">
                                            <ValidationProvider name="Last name" rules="required" v-slot="{ errors }">

                                                <v-text-field label="Last Name"
                                                              :error-messages="errors" outlined
                                                              v-model="lastName" dense></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6">
                                            <ValidationProvider name="Email address" rules="required|email" v-slot="{ errors }">

                                                <v-text-field label="Email Address"
                                                              :error-messages="errors" outlined
                                                              v-model="email" dense></v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="6">
                                            <ValidationProvider name="Card number" rules="required" v-slot="{ errors }">

                                                <v-text-field label="Card Number"
                                                              :error-messages="errors" outlined
                                                              v-model="cardNumber" dense></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="3">
                                            <ValidationProvider name="Cv code" rules="required" v-slot="{ errors }">
                                                <v-text-field label="Cv Code"
                                                              :error-messages="errors" outlined
                                                              v-model="cvCode" dense></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6">
                                            <div>
                                                <ValidationProvider name="Month" rules="required" v-slot="{ errors }">
                                                    <v-select v-model="expirationMonth"
                                                              :items="months"
                                                              label="Month" outlined
                                                              dense
                                                              item-text="month"
                                                              item-value="id"></v-select>

                                                </ValidationProvider>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="6">
                                            <div>
                                                <ValidationProvider name="Year" rules="required" v-slot="{ errors }">
                                                    <v-select v-model="expirationYear"
                                                              :items="years"
                                                              label="Year" outlined
                                                              dense></v-select>

                                                </ValidationProvider>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="d-flex justify-end " style="margin-top: -15px">


                                            <v-btn color="primary" :loading="loading2"
                                                   :disabled="loading2"
                                                   type="submit"> Pay Fee</v-btn>
                                        </v-col>
                                    </v-row>

                                </v-form>
                            </ValidationObserver>
                        </v-sheet>

                    </v-col>
                    <v-col cols="12" v-if="status === 1" style="margin-top: -15px">
                        <p style="border: 1px solid crimson; color: crimson; padding: 10px; border-radius: 10px; font-size: 12px">
                            The card entered was not processed, please check to ensure that the correct information was entered.
                        </p>
                    </v-col>
                </v-row>

            </v-col>

        </v-row>
        <v-row>
            <v-col cols="12">
                <v-dialog overlay-color="white" overlay-opacity="1"
                          v-model="modalTrigger"
                          persistent
                          max-width="600">

                    <v-card>
                        <v-card-title class="text-h5">
                            Contact or Program Information is required
                        </v-card-title>
                        <v-card-text>
                            Please ensure that you have filled in your contact or program information before proceeding to this step
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1"
                                   text
                                   @click="modalTrigger = false"
                                   to="/ProgrammeSelection">
                                Program Information
                            </v-btn>


                            <v-btn color="green darken-1"
                                   text
                                   @click="modalTrigger = false"
                                   to="/ContactInformation">
                                Contact Information
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { formSubmitter, updateProcessStep } from "@/services/userService";

    export default {
        name: "PayApplicationFee",
        data: () => ({
            offlineMessage: process.env.VUE_APP_SHOW_OFFLINE_PAYMENT_MESSAGE,
            receiptUpload: [],
            loading: false,
            loading1: false,
            loading2: false,
            modalTrigger: false,
            cost: 0,
            paymentChoice: null,
            firstName: '',
            lastName: '',
            cardNumber: '',
            cvCode: '',
            months: [
                { month: "January", id: 1 },
                { month: "February", id: 2 },
                { month: "March", id: 3 },
                { month: "April", id: 4 },
                { month: "May", id: 5 },
                { month: "June", id: 6 },
                { month: "July", id: 7 },
                { month: "August", id: 8 },
                { month: "September", id: 9 },
                { month: "October", id: 10 },
                { month: "November", id: 11 },
                { month: "December", id: 12 },
            ],
            years: [],
            expirationMonth: '',
            expirationYear: '',
            email: '',
            falseMessage: [],
            status: 0,
            receipt: {},
            currentProcess: false,
            contactInfoStatus: false
        }),
        watch: {
            processGetter: {
                deep: true,
                handler() {
                    let y = this.processGetter.filter(process => process.applicationProcessId === 2)
                    this.contactInfoStatus = y[0].isComplete
                    let z = this.processGetter.filter(process => process.applicationProcessId === 9)
                    this.currentProcess = z[0].isComplete
                    //console.log(this.$store.state.user.appProcesses)
                    //console.log(this.currentProcess)
                    z[0].isComplete === false && y[0].isComplete === false ? this.modalTrigger = true : this.modalTrigger = false
                }
            },
          getPaymentStatus: {
              handler(n, o) {
                //console.log(this.getPaymentStatus)
                if (n.paymentStatus && n.paymentTypeId === 2){
                  this.$store.dispatch('user/GetApplicationPaymentReceipt', { referenceNo: n.referenceNo})
                }

              },
              deep: true
          },
          receiptData: {
              handler(n, o){
                this.receipt = n
              },
            deep: true
          },
          offlineMessage: {
              handler(n, o){
                console.log(o)
                console.log(n)
              }
          }
        },
        methods: {
            submitWithoutPayment() {
                this.loading = true
                let self = this
                formSubmitter('user/UpsertApplicantPayment', {
                    "isPaid": true,
                    "paymentTypeId": this.paymentChoice,
                    //"paymentTypeName": "string",
                    //"referenceNo": "string"
                }).then(async response => {
                  // self.loading = false
                  //updateProcessStep(9, true)
                  updateProcessStep((await this.$store.getters["user/getCurrentAppProcess"](this.$route)), true)
                }).then(response => {
                  this.$store.dispatch('user/GetApplicationPaymentStatus')
                }).then(response => {
                  this.$store.dispatch('user/GetApplicantPercent')
                  this.loading = false
                }).catch(error => {
                    this.loading = false
                })
            },
            uploadReceipt() {
               this.loading1 = true
                const formData = new FormData();
                formData.append("File", this.receiptUpload)
                console.log(this.receiptUpload)
                formData.append("DocumentId", 0)
                formData.append("documentName", this.receiptUpload.name)
                formSubmitter('user/UpsertPaymentReceipt', formData)
                  .then(async response => {
                    //this.loading = false
                    // updateProcessStep(9, true)
                    updateProcessStep((await this.$store.getters["user/getCurrentAppProcess"](this.$route)), true)
                  }).then(response => {
                  this.$store.dispatch('user/GetApplicationPaymentStatus')
                }).then(response => {
                    this.$store.dispatch('user/GetApplicantPercent')
                    this.loading1 = false
                }).catch(error => {
                    this.loading1 = false
                })

            },
            onSubmit() {
                this.loading2 = true
                formSubmitter('user/PayApplicationFee', {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    cardNumber: this.cardNumber,
                    cvCode: this.cvCode,
                    expirationMonth: this.expirationMonth,
                    expirationYear: this.expirationYear,
                    email: this.email,
                    currencyCode: this.getAppFee.currencyCode,
                    amount: this.getAppFee.cost,
                    responseUrl: ""
                }).then(async response => {
                  console.log(response)
                  if (response.code === 3) {
                    formSubmitter('user/UpsertApplicantPayment', {
                      "isPaid": true,
                      "paymentTypeId": this.paymentChoice,
                      //"paymentTypeName": "string",
                      "referenceNo": response.data.receipt.authorizationCode
                    })
                    this.receipt = response.data.receipt
                    this.status = response.code
                    //updateProcessStep(9, true)
                    await updateProcessStep((await this.$store.getters["user/getCurrentAppProcess"](this.$route)), true)
                  } else if (response.code === 1) {
                    this.falseMessage = response.data.failedMessages
                    this.status = response.code
                    throw new Error(response.data.failedMessages);
                  }
                }).then(response => {
                  this.$store.dispatch('user/GetApplicationPaymentStatus')
                }).then(response => {
                  this.$store.dispatch('user/GetApplicantPercent')
                  this.loading2 = false
                }).catch(error => {
                  this.loading2 = false
                })
            }
        },
        computed: {
            processGetter: function () {
                return this.getAppProcess
            },
            ...mapState({
              getPaymentTypes: state => state.utilities.paymentTypes,
              getAppFee: state => state.user.applicationCost,
              getAppProcess: state => state.user.appProcesses,
              getPaymentStatus: state => state.user.paymentStatus,
              getPin: state => state.user.pin,
              receiptData: state => state.user.receipt
            })
        },
        mounted() {
            this.$store.dispatch('user/GetApplicantPercent')
            this.$store.dispatch('user/GetApplicationCost')
            if (this.getPaymentStatus.paymentStatus && this.getPaymentStatus.paymentTypeId === 2){
              this.$store.dispatch('user/GetApplicationPaymentReceipt', { referenceNo: this.getPaymentStatus.referenceNo})
            }
            this.receipt = this.receiptData
            let currYear = new Date().getFullYear();
            for (let i = 0; i < 10; i++) {
                this.years.push(currYear + i)
            }
            console.log(this.getPaymentStatus)
        }
    }
</script>

<style scoped>
</style>
