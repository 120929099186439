<template>
  <v-snackbar
      v-model="toggle" multi-line :color="color"
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="toggle = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackbarNotifier",
  props: {
    text: { required: true, type: String },
    color: { required: true, type: String }
  },
  data:() => ({
    toggle: false
  }),
  watch: {
    text: function (n, o) {
      this.toggle = true
    }
  }
}
</script>

<style scoped>

</style>