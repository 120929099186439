var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"py-3 fill-height"},[_c('div',{staticClass:"d-flex justify-md-space-between"},[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$route.name))])]),_c('small-nav',{staticClass:"d-lg-none"})],1),_c('help-modal',{attrs:{"source":this.$route.path === '/PersonalInformation' ? _vm.tutorials[0] :
       this.$route.path === '/ContactInformation' ? _vm.tutorials[1] :
       this.$route.path === '/Declaration&Agreement' ? _vm.tutorials[2] :
       this.$route.path === '/ProgrammeSelection' ? _vm.tutorials[3] :
       this.$route.path === '/RequiredDocuments' ? _vm.tutorials[4] :
       this.$route.path === '/PreviousSchools' ? _vm.tutorials[5] :
       this.$route.path === '/PayApplicationFee' ? _vm.tutorials[6] :
       this.$route.path === '/OtherDocuments' ? _vm.tutorials[4] :
       _vm.tutorials[0]}}),_c('v-spacer'),(_vm.$route.path === '/PreviousSchools')?_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticStyle:{"margin-right":"10px"}},[_c('add-pass')],1),_c('div',[_c('add-school')],1)]):_vm._e(),(_vm.$route.path === '/SourceofFunding')?_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticStyle:{"margin-right":"10px"}},[_c('add-source-of-funding')],1)]):_vm._e()],1),_c('v-divider')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }