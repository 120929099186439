<template>
  <v-col cols="12">
    <v-row >
      <v-col cols="12">
        <ValidationObserver ref="observer" v-slot="{  handleSubmit }">
          <v-form @submit.prevent="handleSubmit(onSubmit)">
            <v-row no-gutters>
              <v-col cols="12" v-for="(item, index) in getDeclarations" :key="index">
                <div class="d-flex mt-5 ">
                  <div>
                    <ValidationProvider :name="'terms' + (index + 1)" rules="required|checkTrue" v-slot="{ errors }" >
                      <v-checkbox
                          :error-messages="errors"
                          hide-details="auto"
                          v-model="terms[index] = item.agreed"

                      ></v-checkbox>
                    </ValidationProvider>
                  </div>
                  <div v-html="item.appDeclaration">

                  </div>
                </div>
              </v-col >


            </v-row>
            <v-row>
              <v-col class="d-flex justify-end mt-4" >


                <v-btn
                    color="primary"
                    loading :loading="loading" :disabled="loading"
                    type="submit"> Save & Next</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { extend } from 'vee-validate';
extend('checkTrue', value => {
  return value !== false;
});

import {mapState} from "vuex";
import {formSubmitter, updateProcessStep} from "@/services/userService";

export default {
  name: "DeclarationAgreements",
  data:() => ({
    loading: false,
    terms: [],

  }),
  methods: {

    onSubmit() {
      let z = {}
      for(let i = 0; i < this.terms.length; i++){
        z['terms' +[i+1]] = {
          "appDeclarationId": this.getDeclarations[i].appDeclarationId,
          "agreed": this.terms[i]
        }
        //console.log(z)
      }
      //console.log(z)
      this.loading = true
      formSubmitter('user/UpsertApplicantDeclarations', z).then(async response => {
        this.loading = false
        updateProcessStep((await this.$store.getters["user/getCurrentAppProcess"](this.$route)), true).then(response => {
          this.$store.dispatch('user/GetApplicantPercent')
        })
        //this.$router.push('/PayApplicationFee')
        this.$store.dispatch('user/MoveToNextStep', {route: this.$route})
      }).catch(error=> {
        this.loading = false

      })
    }
  },
  computed: {
    ...mapState({
      getDeclarations: state => state.user.declarations
    })
  },
  mounted() {
    this.$store.dispatch('user/GetApplicantPercent')
  }
}
</script>

<style scoped>

</style>
