<template>
  <span >
    <v-btn text
        color="pink"
        dark outlined
        @click.stop="drawer = !drawer"
    >
      <v-icon >mdi-menu</v-icon>
      <div style="font-size: 12px">Menu</div>
    </v-btn>
    <v-navigation-drawer
        v-model="drawer"
        absolute dark
        temporary
        color="#081229"
    >
      <v-list-item>
          <div class="d-flex justify-center mb-4" style="text-align: center">
            <v-img src="../assets/ncu_logo_small.png" style="max-height: 150px; max-width: 150px; width: auto; height: auto"></v-img>
          </div>

        </v-list-item>



      <v-divider></v-divider>

      <v-list dense>
        <v-list-item link dense>
          <v-list-item-content>
            <v-list-item-title class="text-h9 yellow--text" >
              {{ userName.firstName + " " + userName.lastName || ''}}
            </v-list-item-title>
            <v-list-item-subtitle >Completion:
              <v-progress-linear
                  color="light-green darken-4"
                  height="10"
                  :value="appPercent"
                  striped
              ><strong>{{ Math.ceil(appPercent) }}%</strong></v-progress-linear>
              <br />
              Email: {{ appEmail }}
              <br />
              Pin: {{ userPin }}
              <br />
              Status: {{ getStatus.statusName }}</v-list-item-subtitle>
          </v-list-item-content>

        </v-list-item>
        <v-list-item
            link
            color="primary lighten-1" to="/"
        >
        <v-list-item-icon >
          <v-icon size="16" color="primary">mdi-human-greeting</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="">
            Welcome
          </v-list-item-title>
        </v-list-item-content>

      </v-list-item>

        <v-list-item
            v-for="(n, index) in getLinks"
            :key="n.applicationProcessId"
            link dense
            :to='n.processName.split(" ").join("")'
        >
          <v-list-item-icon>
              <v-icon v-if="n.isComplete" color="green lighten-1">mdi-check</v-icon>
              <v-icon v-else color="red lighten-1">mdi-close</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="n.processName"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            link
            color="primary lighten-1" @click="restart()"
            active-class="primary "
        >
      <v-list-item-icon >
        <v-icon size="16" color="primary">mdi-plus</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="color: #1976d2">
          New Application
        </v-list-item-title>
      </v-list-item-content>

    </v-list-item>
        <v-list-item
            link
            color="red lighten-1" to="/logout"
            active-class="red "
        >
      <v-list-item-icon >
        <v-icon size="16" color="red">mdi-logout</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="color: crimson">
          Logout
        </v-list-item-title>
      </v-list-item-content>

    </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </span>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "smallNav",
  data () {
    return {
      drawer: null,
      items: [
        { title: 'Home', icon: 'mdi-view-dashboard' },
        { title: 'About', icon: 'mdi-forum' },
      ],
    }
  },
  computed: {
    ...mapState ({
      getLinks: state => state.user.appProcesses,
      appPercent: state => state.user.percent,
      appEmail: state => state.user.email,
      userName: state => state.user.personal,
      userPin: state => state.user.pin,
      getSchoolInfo: state => state.utilities.schoolInfo,
      getProgram: state => state.user.program,
      getStatus: state => state.user.paymentStatus
    })
  }
}
</script>

<style scoped>

</style>
