import { render, staticRenderFns } from "./PreviousSchools.vue?vue&type=template&id=2eca35f6&scoped=true"
import script from "./PreviousSchools.vue?vue&type=script&lang=js"
export * from "./PreviousSchools.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eca35f6",
  null
  
)

export default component.exports