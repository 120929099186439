<script>
import {mapState} from "vuex";

export default {
  name: "prospectDepartmentAssign",
  data(){
    return {
      departmentId: null,
      collegeId: null,
      agentId: null,
      assignOnlyNewInterest: false,
    }
  },
  computed: {
    ...mapState ({
      getDepartments: state => state.utilities.departments,
      getColleges: state => state.utilities.colleges,
      getAdminAgents: state => state.user.adminAgents,
    })
  },
  methods: {
    onSubmit: function (){
      this.$refs.observer.validate().then(()=>{
        this.$store.dispatch("user/AssignInterestToAgentByDepartment", {
          //"interestId": "string",
          "agentId": this.agentId,
          "departmentId": this.departmentId,
          //"collegeId": 0,
          //"programId": 0,
          "assignOnlyNewInterest": this.assignOnlyNewInterest
        }).then(response => {
          console.log(response)
        })
        /*this.$store.dispatch('program/UpsertProgramInformation', {
          programName: this.ProgramName,
          overview: this.ProgramOverview,
          description: this.ProgramDescription,
          tuitionCost: this.TuitionCost,
          tuitionCurrency: this.TuitionCurrency,
          duration: this.Duration,
          durationType: this.DurationType,
          careers: this.currentProgram.careers,
          learn: this.currentProgram.learn,
          faqs: this.currentProgram.faqs,
          matriculations: this.currentProgram.matriculations,
          programId: parseInt(this.$route.params.id),
        })*/
      })


    },
  }
}
</script>

<template>
<div>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <br>
      <div>
        <ValidationProvider name="departmentId" rules="required" v-slot="{ errors }" >
          <v-select
              label="Choose department"
              :error-messages="errors"
              solo
              :items="getDepartments"
              item-text="departmentName"
              item-value="departmentId"
              v-model="departmentId"
          ></v-select>
        </ValidationProvider>
      </div>
      <div>
        <ValidationProvider name="collegeId" rules="required" v-slot="{ errors }" >
          <v-autocomplete
              label="Choose Agent Name"
              :error-messages="errors"
              solo
              :items="getAdminAgents"
              :item-text="value => { return value.firstName + ' ' + value.lastName}"
              item-value="id"
              v-model="agentId"
          ></v-autocomplete>
        </ValidationProvider>
      </div>
      <div>
        <ValidationProvider name="assignOnlyNewInterest" rules="required" v-slot="{ errors }" >
          <v-checkbox
              v-model="assignOnlyNewInterest"
              label="Assign all predating"
          ></v-checkbox>
        </ValidationProvider>
      </div>
      <div>
        <v-btn color="primary" type="submit">Update</v-btn>
      </div>
    </form>
  </ValidationObserver>
</div>
</template>

<style scoped>

</style>