export default  {
    api_dev: process.env.VUE_APP_API_ADDRESS + "Utilities",
    api: process.env.VUE_APP_API_ADDRESS + "Utilities",
    gender: [],
    degreeLevel: [],
    areaOfInterest: [],
    programOfInterest: [],
    maritalStatuses: [],
    nationalities: [],
    religions: [],
    denominations: [],
    filteredDenominations: [],
    relationTypes: [],
    parishes: [],
    countries: [],
    schoolTypes: [],
    schoolsListByTypes: [],
    filteredSchool: [],
    searchFilteredSchool:[],
    examProficiency: [],
    examBoards: [],
    subjects: [],
    sourcesOfFunding: [],
    declarations: [],
    paymentTypes: [],
    fileTypes: [],
    schoolInfo: [],
    schoolLogo: '',
    schoolName: '',
    staffAgents: [],
    departments: [],
    colleges: [],
}
