<template>
  <div>

    <component :is="layout">

      <router-view  />
    </component>
  </div>
</template>

<script>
const default_layout = 'default'
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: process.env.VUE_APP_SCHOOL_META,
    // all titles will be injected into this template
    //titleTemplate: '%s | My Awesome Webapp'
  },
  name: "App",
  components: {},

  computed:{
    layout(){
      return (this.$route.meta.layout || default_layout) + '-layout';
    }
  },
  mounted(){
    if(process.env.VUE_APP_CHAT_STATUS === 'true'){
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', '//code.jivosite.com/widget/ZRlWqIbkvw')
      document.head.appendChild(recaptchaScript)
    }

  }
}
</script>
