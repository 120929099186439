<script>
import {mapState} from "vuex";
import CareersByProgram from "@/components/admin/CareersByProgram.vue";
import 'mavon-editor/dist/css/index.css'
import Editor from '@tinymce/tinymce-vue'
import AdminAppBar from "@/components/admin/AdminAppBar.vue";
import LearningByProgram from "@/components/admin/LearningByProgram.vue";
import FrequentlyAskedQuestionByProgram from "@/components/admin/FrequentlyAskedQuestionByProgram.vue";
import MatriculationsByProgram from "@/components/admin/MatriculationsByProgram.vue";

export default {
  name: "ManageProgramInformation",
  components: {
    MatriculationsByProgram,
    FrequentlyAskedQuestionByProgram, LearningByProgram, AdminAppBar, CareersByProgram, Editor},
  data: () => ({
    programFilter: "",
    ProgramDescription: '',
    ProgramName: '',
    TuitionCost: '',
    TuitionCurrency: '',
    Duration: '',
    DurationType: '',
    ProgramOverview: '',
    Careers: '',
    Learning: '',
    matriculations: '',

    items: [
      'Info', 'FAQ', 'What you will learn', 'Careers',
    ],
    tab: null,
  }),
  watch: {
    currentProgram: {
      async handler(n, o){
        this.ProgramName = n?.programName
        this.ProgramOverview = n?.overview
        this.ProgramDescription = n?.description
        this.TuitionCost = n?.tuitionCost
        this.TuitionCurrency = n?.tuitionCurrency
        this.Duration = n?.duration
        this.DurationType = n?.durationType
        //this.Careers = Object.keys(n?.careers).map(function(k){return n?.careers[k].career}).join(",")
      }
    }
  },
  methods: {
    onSubmit: function (){
      this.$refs.observer.validate().then(()=>{
        this.$store.dispatch('program/UpsertProgramInformation', {
          programName: this.ProgramName,
          overview: this.ProgramOverview,
          description: this.ProgramDescription,
          tuitionCost: this.TuitionCost,
          tuitionCurrency: this.TuitionCurrency,
          duration: this.Duration,
          durationType: this.DurationType,
          careers: this.currentProgram.careers,
          learn: this.currentProgram.learn,
          faqs: this.currentProgram.faqs,
          matriculations: this.currentProgram.matriculations,
          programId: parseInt(this.$route.params.id),
        })
      })


    },
    removeWhatYouWillLearn: function () {
      this.$store.dispatch("program/RemoveProgramInformationLearn", this.$route.params.id)
    },
    removeCareers: function () {
      this.$store.dispatch("program/RemoveProgramInformationLearn", this.$route.params.id)
    },

  },
  computed: {
    ...mapState({
      //getPrograms: state => state.program.searchPrograms,
      currentProgram: state => state.program.programInfo
    }),
  },
  mounted() {
    //TODO: Check conditional if params exist add redirect to home page if no params exists
    this.$store.dispatch("program/GetAdditionalProgramInformation", this.$route.params.id)
  }
}
</script>

<template>
  <div style="background: #F5F5F5">
    <AdminAppBar />
    <br><br>
    <v-row >
      <v-col cols="12" sm="3">

      </v-col>
      <v-col cols="12" sm="6" >
        <div class="pa-3">

          <ValidationObserver ref="observer" v-slot="{ invalid, validated, handleSubmit, validate }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <h2>General Information</h2>
                  <div>
                    <ValidationProvider name="ProgramName" rules="required" v-slot="{ errors }" >
                      <label>Program Name</label> <br>
                      <v-text-field
                          label="Program Name"
                          :error-messages="errors"
                          solo dense
                          hide-details="auto"
                          v-model="ProgramName"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <div>
                    <ValidationProvider name="ProgramDescription" rules="required" v-slot="{ errors }" >
                      <label>Program Description</label> <br>
                      <Editor
                          api-key="wnteolqswgv349fjrxqsdm693hxo4u49sim4kq80kmvzqqy9"
                          :init="{
                          menubar: false,
                          plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions  tableofcontents footnotes mergetags autocorrect typography inlinecss markdown',
                          toolbar: 'undo | blocks fontfamily fontsize header h1 h2 h3 h4 | bold italic underline strikethrough | checklist numlist bullist indent outdent | link image media table spellcheckdialog a11ycheck typography | align lineheight  | emoticons charmap | removeformat',
                       }"

                          v-model="ProgramDescription"
                      />
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6"   >
                  <div>
                    <ValidationProvider name="TuitionCost" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Tuition Cost"
                          :error-messages="errors"
                          solo dense
                          hide-details="auto"
                          v-model="TuitionCost"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6"   >
                  <div>
                    <ValidationProvider name="TuitionCurrency" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Tuition Currency"
                          :error-messages="errors"
                          solo dense
                          hide-details="auto"
                          v-model="TuitionCurrency"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6"   >
                  <div>
                    <ValidationProvider name="Duration" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Duration"
                          :error-messages="errors"
                          solo dense
                          hide-details="auto"
                          v-model="Duration"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6"   >
                  <div>
                    <ValidationProvider name="DurationType" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Duration Type"
                          :error-messages="errors"
                          solo dense
                          hide-details="auto"
                          v-model="DurationType"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <div>
                    <ValidationProvider name="ProgramOverview" rules="required" v-slot="{ errors }" >
                      <Editor
                          api-key="wnteolqswgv349fjrxqsdm693hxo4u49sim4kq80kmvzqqy9"
                          :init="{
                          menubar: false,
                          plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions  tableofcontents footnotes mergetags autocorrect typography inlinecss markdown',
                          toolbar: 'undo | blocks fontfamily fontsize header h1 h2 h3 h4 | bold italic underline strikethrough | checklist numlist bullist indent outdent | link image media table spellcheckdialog a11ycheck typography | align lineheight  | emoticons charmap | removeformat',
                       }"
                          v-model="ProgramOverview"
                      />
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <h3>Careers</h3>
                  <div>
                    <CareersByProgram />

                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <h3>What will you learn</h3>
                  <div>
                    <LearningByProgram />
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <h3>Frequently Asked Questions</h3>
                  <div>
                    <FrequentlyAskedQuestionByProgram />
                  </div>

                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <h3>Matriculation Requirements</h3>
                  <div>
                    <MatriculationsByProgram />
                  </div>

                </v-col>

              </v-row>
              <div class="pa-3">
                <div class="d-flex">
<!--                  <v-btn color="red" dark @click="removeCareers()" class="mr-3">Remove Careers</v-btn>
                  <v-btn color="red" dark @click="removeWhatYouWillLearn()" class="mr-3">Remove Learn</v-btn>-->
                  <v-btn color="primary" dark type="submit">Update</v-btn>
                </div>
              </div>
            </form>
          </ValidationObserver>
<!--          {{ this.currentProgram }}-->

        </div>
      </v-col>
      <v-col cols="12" sm="3"></v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>