<template>
  <v-row>
    <v-col cols="12" xs="12">
      <div  class="d-flex justify-center" v-if="this.imageBase !== ''" style="text-align: center">
        <!--              <img  src="../assets/ncu_logo.png" class="d-none d-lg-block" alt="">-->
        <img  class="d-none d-lg-block"  :src="'data:application/octet-stream;base64,' + this.imageBase" alt="" >
      </div>

    </v-col>
    <v-col cols="12" xs="12">
      <div class="d-flex justify-center" v-if="this.imageBase !== ''">
        <img  class="d-lg-none"  :src="'data:application/octet-stream;base64,' + imageBase" alt=""  >
      </div>
    </v-col>
<!--    <v-col cols="12" xs="12">
      <div  class="d-flex justify-center">
        <img  src="../assets/heart.png" class="d-none d-lg-block ncu" alt="">
      </div>

    </v-col>
    <v-col cols="12" xs="12">
      <div class="d-flex justify-center">
        <img  class="d-lg-none ncu"  src="../assets/heart.png" alt="">
      </div>
    </v-col>-->
  </v-row>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "imgContainer",
  data:() => ({
    imageBase: '',
  }),
  watch:{
    getSchoolInfo: {
      handler(n, o){
        //console.log(n)
        this.imageBase = n.logo
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      getSchoolInfo: state => state.utilities.schoolInfo,
      getSchoolLogo: state => state.utilities.schoolLogo,
      getSchoolName: state => state.utilities.schoolName,
    })
  },
  mounted() {
    this.$store.dispatch('utilities/GetSchoolInfo')
  }
}
</script>

<style scoped>
img{
  max-width: 400px;
  max-height: 400px;
  height: auto;
  width: auto;
}

@media screen and (min-width:320px) {

  .ncu{
    max-height: 100px;
    max-width: 100px;
    width: auto;
    height: auto;
  }
}
@media screen and (min-width:600px){

  .ncu{
    max-height: 150px;
    max-width: 150px;
    width: auto;
    height: auto;
  }
}
@media screen and (min-width:1000px){

  .ncu{
    max-height: 200px;
    max-width: 200px;
    width: auto;
    height: auto;
  }
}

</style>
