export default {
    async GetProgramData ({ state, commit, rootState }) {
        await fetch(rootState.api + 'Programs/GetCampusDegreeLevelsDepartmentsPrograms')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setProgramData', response)
                })

            })
            .catch(error => {
               // console.log(error);
            });
    },
    async GetProgramDataForSearch ({ state, commit, rootState }) {
        await fetch(rootState.api + 'Programs/GetProgramCampus')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setProgramDataForSearch', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },

    async GetProgram ({ state, commit, rootState }) {
        await fetch(rootState.api + 'Programs/GetDepartmentLevelProgramCampus')
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    //console.log(response)
                    commit('setProgramDataByAreaOfInterest', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },
    async SetDegreeLevel ({ state, commit, rootState }, payload) {
        commit("setDegreeLevel", payload)
    },
    async SetAreaOfInterest ({ state, commit, rootState }, payload) {
        commit("setAreaOfInterest", payload)
    },
    async SetProgramOfInterest ({ state, commit, rootState }, payload) {
        commit("setProgramOfInterest", payload)
    },
    async setCampuses ({ state, commit, rootState }, payload) {
        commit("setCampuses", payload)
    },

    async GetOpenTerms ({ state, commit, rootState }, payload) {

        const url = 'Programs/ShowOpenTerms'
        await fetch(rootState.api + url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    commit('setOpenTerms', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },

    async GetLogs({ state, commit, rootState}, payload){
        console.log(payload)
        const url = `Staff/GetLogSummary?pageSize=10&pageNumber=1`
        await fetch(rootState.api + url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            //body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    console.log(response)
                    commit('setLogs', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },
    async GetLog({ state, commit, rootState}, payload){
        const url = `Staff/GetLogSummaryByPin?pin=${payload.pin}`
        await fetch(rootState.api + url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            //body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    console.log(response)
                    commit('setCurrentLog', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },

    async GetAdditionalProgramInformation({ state, commit, rootState}, payload){
        console.log(payload)
        const url = `Programs/GetProgramInformation?programId=${payload}`
        await fetch(rootState.api + url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            //body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    console.log(response)
                    commit('setCurrentProgramInfo', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },

    async UpsertProgramInformation({state, commit, rootState, rootGetters}, payload){
        //console.log(payload)
        //payload.careers = await rootGetters["program/CreateListFromCommaString"](payload.careers, payload.programId, "career")
        //payload.learn = await rootGetters["program/CreateListFromCommaString"](payload.learn, payload.programId, "topic")
       // console.log(payload.learn)
        const url = `Programs/UpsertProgramInformation`
        await fetch(rootState.api + url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    console.log(response)
                    //commit('setCurrentProgramInfo', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },
    async RemoveProgramInformationLearn({state, commit, rootState, rootGetters}, payload){

        const url = `Programs/RemoveProgramInformationLearn`
        await fetch(rootState.api + url + "?id=" + payload, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            //body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    console.log(response)
                    //commit('setCurrentProgramInfo', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },
    async RemoveProgramInformationFaq({state, commit, rootState, rootGetters}, payload){

        const url = `Programs/RemoveProgramInformationFaq`
        await fetch(rootState.api + url + "?id=" + payload, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            //body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    console.log(response)
                    //commit('setCurrentProgramInfo', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },
    async RemoveProgramInformationCareer({state, commit, rootState, rootGetters}, payload){

        const url = `Programs/RemoveProgramInformationCareer`
        await fetch(rootState.api + url + "?id=" + payload, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            //body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    console.log(response)
                    //commit('setCurrentProgramInfo', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },
    async RemoveProgramInformationMatriculation({state, commit, rootState, rootGetters}, payload){

        const url = `Programs/RemoveProgramInformationMatriculation`
        await fetch(rootState.api + url + "?id=" + payload, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            },
            //body: JSON.stringify(payload)
        })
            .then(async (data) => {
                //const apiData = data.json()
                await data.json().then(response => {
                    console.log(response)
                    //commit('setCurrentProgramInfo', response)
                })

            })
            .catch(error => {
                //console.log(error);
            });
    },

}
