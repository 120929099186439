export default {
    personalInfoGetter: state => state.personal,
    contactInfoGetter: state => state.contact,
    programInfoGetter: state => state.program,
    programNewInfoGetter: state => state.program,
    sourceInfoGetter: state => state.sourceOfFunds,
    //currentAppProcessGetter: state => state.appProcesses,
    currentAppProcessGetter: (state) => async (id) => {

        // console.log(state.programmes.filter(program => program.programInstanceId === instanceId))
        //return "blue"
        console.log(state.appProcesses)
        return state.appProcesses.filter(process => {
            console.log(process)
            return process.applicationProcessId === id
        })

    },
    getCurrentAppProcess: (state) => async (currentRoute) => {

        // console.log(state.programmes.filter(program => program.programInstanceId === instanceId))
        //return "blue"
       // alert(currentRoute.path)
        let z = state.appProcesses.filter( x => {
            /*console.log(x.processName.split(" ").join(""))
            console.log(currentRoute.path.split("/")[1])*/
            return x.processName.split(" ").join("") === currentRoute.path.split("/")[1]
        })
        //console.log(z[0].applicationProcessId)
        return z[0].applicationProcessId
        /*console.log(state.appProcesses)
        return state.appProcesses.filter(process => {
            console.log(process)
            return process.applicationProcessId === id
        })*/

    },

}
