<template>
  <div>
    Hello Prospective Student,
    <br>
    Welcome to {{ getSchoolInfo.schoolName }} ({{ getSchoolInfo.schoolShortName }}) online application platform. <br><br>
    In order to complete the online application for an undergraduate programme, you will need to submit the following  (a green check mark indicates that the document has been received):

    <ul>
      <li>Birth Certificate</li>
      <li>Passport-sized Photograph</li>

<!--      <li>Financial Memorandum of Understanding (MOU)</li>-->
      <li>Proof of External Pass (or time table of the next exam sitting)</li>
      <li>Agree to the terms of the Declaration and Agreement section</li>
    </ul>
    <br>
    <p>
      You may effectively track your application progress and view the current status of your application in the application platform. Keep Application PIN near, it makes processing queries easier.

    </p>
    <p>If you have any questions you can always contact us at {{ getSchoolInfo.admissionEmail }}.


    </p>
    <p>Sincerely, <br><br>
      Recruitment and Admissions <br>
      {{ getSchoolInfo.schoolName }}
    </p>
  </div>
</template>

<script>
  import HelloWorld from '../components/HelloWorld'
  import {mapGetters, mapState} from "vuex";

  export default {
    name: 'Home',

    components: {
      HelloWorld,
    },
    computed: {
      ...mapState({
        getSchoolInfo: state => state.utilities.schoolInfo,
      })
    }
  }
</script>
