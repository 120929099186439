<template>
  <div >

    <Basic  v-if="choice === 1"/>
    <CreateAccount v-if="choice === 2"/>
  </div>
</template>

<script>
import CreateAccount from "@/views/CreateAccount";
import Basic from "@/views/Basic";
export default {
  name: "NewAccount",
  components: {Basic, CreateAccount},
  data: () => ({
    choice: null
  }),
  mounted(){
    //console.log(this.$route.query.choice)
    this.choice = this.$route.query.choice
    if (this.choice === 2){
      //console.log("dark road")
    }
  }
}
</script>

<style scoped>

</style>