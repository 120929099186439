<script>
import {
  Chart as ChartJS,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar, Doughnut } from 'vue-chartjs'
import {mapGetters, mapState} from "vuex";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)
export default {
  name: "TermReports",
  components: {
    Bar, Doughnut
  },
  data() {
    return {
      chartStatus: false,
      data: {

        labels: this.termNames,
        datasets: [{ data: this.termValues }]
        },
        options: {
          responsive: true
        },
    }
  },
  watch:{
    termNames: function (n, o) {
      console.log(n)
      this.termLabel = n

    },
    termValues: function (n, o) {
      this.termValue = n

    }

  },
  methods: {
    r() {
      return Math.floor(Math.random() * 256);
    }

  },
  computed: {
    chartData() {
      return {
        labels: this.termNames,
        datasets: [ {
          data: this.termValues,
          label: 'Total Students by Terms ',
          backgroundColor: () => {
            return this.termNames.filter(r => {
              return "rgb(" + this.r() + "," + this.r() + "," + this.r() + ")"
            })
          }
        } ]
      }
    },
    ...mapState({
      terms: state => state.staff.termsByYear,
      status: state => state.staff.chartStatus
    }),
    ...mapGetters({
      termNames: 'staff/termsByYearNames',
      termValues: 'staff/termsByYearValues',
    })
  },
  mounted() {
  }
}
</script>

<template>
  <div>
    <h3>Report by Terms</h3>
    <Bar v-if="status" ref="chart" :data="chartData" :options="options"></Bar>
  </div>
</template>

<style scoped>

</style>