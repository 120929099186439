<template>
  <v-container>
    <v-row >
      <v-col v-if="Object.keys(ContactGet).length === 0" cols="12">
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-col>
      <v-col v-if="Object.keys(ContactGet).length !== 0" cols="12" sm="12"  >

        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(onSubmit)">
                <v-row>
                  <v-col  cols="12"  xs="12" sm="12" md="6" >
                    <div>
                      <ValidationProvider name="Email Address" :rules="{ required: true, regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }" v-slot="{ errors }" >
                        <v-text-field
                            label="Email Address"
                            :error-messages="errors"
                            hide-details="auto" dense outlined
                            v-model="emailAddress"
                        ></v-text-field>
                      </ValidationProvider>
                    </div>
                  </v-col>
                  <v-col cols="12"  xs="12" sm="12" md="6" >
                    <div>
                      <ValidationProvider name="Phone Number" rules="required" v-slot="{ errors }" >
                        <v-text-field
                            label="Phone Number"
                            :error-messages="errors"
                            hide-details="auto" dense outlined
                            v-model="phoneNumber"
                        ></v-text-field>

                      </ValidationProvider>
                    </div>
                  </v-col>
                  <v-col  cols="12"  xs="12" sm="12" md="6"  >
                    <div>
                      <ValidationProvider name="Address Line 1" rules="required" v-slot="{ errors }" >

                        <v-textarea
                            label="Address Line 1 "
                            v-model="addressLine1"
                            :error-messages="errors"
                            hide-details="auto" dense outlined
                            auto-grow
                            rows="3"
                        ></v-textarea>
                      </ValidationProvider>
                    </div>
                  </v-col>
                  <v-col cols="12"  xs="12" sm="12" md="6"  >
                    <div>
                      <ValidationProvider name="Address Line 2" rules="required" v-slot="{ errors }" >

                        <v-textarea
                            label="Address Line 2 "
                            v-model="addressLine2"
                            :error-messages="errors"
                            hide-details="auto" dense outlined
                            auto-grow
                            rows="3"
                        ></v-textarea>
                      </ValidationProvider>
                    </div>
                  </v-col>
                  <v-col  cols="12"  xs="12" sm="12" md="6"  >
                    <div>
                      <ValidationProvider name="Country" rules="required" v-slot="{ errors }" >
                        <v-autocomplete
                            v-model="countryId"
                            :items="getCountries"
                            :error-messages="errors"
                            hide-details="auto"
                            clearable dense outlined
                            hide-selected
                            item-text="name"
                            item-value="id"
                            label="Country"

                        >
                        </v-autocomplete>
                      </ValidationProvider>
                    </div>
                  </v-col>
                  <v-col cols="12"  xs="12" sm="12" md="6"   v-show="countryId === 'JM'">
                    <div>
                      <ValidationProvider name="Parish" rules="required" v-slot="{ errors }" >
                        <v-select
                            :error-messages="errors"
                            :items="getParishes"
                            label="Parish"

                            hide-details="auto"
                            v-model="parishId"
                            item-text="name" dense outlined
                            item-value="id"

                        ></v-select>

                      </ValidationProvider>
                    </div>
                  </v-col>
                </v-row>


            <v-row>
<!--              <v-col class="d-flex justify-lg-start " style="margin-top: -15px">
                <help-modal  source="https://www.youtube.com/embed/os5ebayI-oY"/>
              </v-col>-->
              <v-col class="d-flex justify-end mt-4" >


                <v-btn
                    color="primary"
                    loading :loading="loading" :disabled="loading"
                    type="submit"> Save & Next</v-btn>
              </v-col>
            </v-row>
          </form>
        </ValidationObserver>
      </v-col>
<!--      <v-col v-if="Object.keys(ContactGet).length !== 0" cols="12" sm="1" md="5" lg="4" >
        <help-video  source="https://www.youtube.com/embed/os5ebayI-oY"/>
      </v-col>-->
    </v-row>
    <snackbar-notifier  :color="color" :text="text" />
  </v-container>
</template>

<script>
import TopBar from "@/components/TopBar";
import SnackbarNotifier from "@/components/SnackbarNotifier";
import { mapState, mapGetters } from 'vuex'
import {formSubmitter, updateProcessStep} from "@/services/userService";
import HelpVideo from "@/components/helpVideo";
import HelpModal from "@/components/helpModal";
export default {
  name: "ContactInfoView",
  components: {HelpModal, HelpVideo, TopBar, SnackbarNotifier},
  data: ()=>({
    loading: false,
    emailAddress: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    parishId: null,
    countryId: '',
    text: '',
    snackbar: false,
    color: ''
  }),

  computed: {
    contactInfo: function () {
      return this.$store.state.user.contact
    },
    ...mapState ({
      getParishes: state => state.utilities.parishes,
      getCountries: state => state.utilities.countries,
    }),
    ...mapGetters({
      ContactGet: 'user/contactInfoGetter'
    })
  },
  watch: {
    countryId: {
      handler: function (n, o) {
        //this.countryId
        /*if(this.parishId !== 0 && this.countryId !== 'JM'){

        }else{
          this.parishId = 0
        }*/
      }
    },
    ContactGet: {
      handler: function (n, o) {
        this.emailAddress = n.email
        this.phoneNumber = n.phone
        this.addressLine1 = n.addressLine1
        this.addressLine2 = n.addressLine2
        this.countryId = n.countryId
        this.parishId = n.stateParishId
      },
      deep: true
    }
  },
  methods: {
    onSubmit() {

      if(this.parishId === 0 && this.countryId === 'JM'){
        this.parishId = ''
      }else {
        this.loading = true
        if (this.countryId !=='JM'){
          this.parishId = 0
        }
      formSubmitter('user/UpdateContactInformation', {
        pin: this.ContactGet.pin,
        contactId: this.ContactGet.contactId,
        email: this.emailAddress,
        phone: this.phoneNumber,
        addressId: this.ContactGet.addressId,
        addressTypeId: this.ContactGet.addressTypeId,
        addressLine1: this.addressLine1,
        addressLine2: this.addressLine2,
        stateParishId: this.parishId,
        countryId: this.countryId,
      }).then(async response => {
        this.text = "Your Contact Information was successfully updated "
        this.color = "success"
        this.snackbar = true

        //this.$router.push('/PreviousSchools')
        updateProcessStep((await this.$store.getters["user/getCurrentAppProcess"](this.$route)), true)
            .then(response => {
              //alert()
              this.$store.dispatch('user/GetApplicantPercent')
              this.$store.dispatch('user/GetApplicationCost')
              this.$store.dispatch('user/MoveToNextStep', {route: this.$route})
            })
        this.loading = false
      }).catch(error =>{
        this.loading = false
        this.text = error
        this.color = "red"
        this.loading = false
      })

      }
    }
  },
  mounted() {
    this.emailAddress = this.contactInfo.email
    this.phoneNumber = this.contactInfo.phone
    this.addressLine1 = this.contactInfo.addressLine1
    this.addressLine2 = this.contactInfo.addressLine2
    this.countryId = this.contactInfo.countryId
    this.parishId = this.contactInfo.stateParishId
    this.$store.dispatch('user/GetApplicantPin').then(pin =>{
      this.$store.dispatch('user/SetPin', {pin: pin})
      let self = this
      setTimeout(function (){
        self.$store.dispatch('user/GetApplicantPercent')
        //self.$store.dispatch('user/GetContactInformation')
        /*.then(response =>{
          loading.close()
        })*/
      }, 1000)
    }).catch(error=>{

    })
  }
}
</script>

<style scoped>

</style>
