<template>
  <v-container>
    <v-row >
      <v-col v-if="Object.keys(PersonGet).length === 0" cols="12">
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-col>
      <v-col v-if="Object.keys(PersonGet).length !== 0" cols="12">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <v-row>
              <v-col cols="12" sm="12" md="6" >
                <div>
                  <ValidationProvider name="First name" rules="required" v-slot="{ errors }" >
                    <v-text-field
                        label="First Name" dense
                        :error-messages="errors"
                        hide-details="auto"
                        v-model="firstName" outlined

                    ></v-text-field>

                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6"  >
                <div>
                  <ValidationProvider name="Middle name" rules="" v-slot="{ errors }" >
                    <v-text-field
                        label="Middle Name" dense
                        :error-messages="errors"
                        hide-details="auto"
                        v-model="middleName" outlined
                    ></v-text-field>

                  </ValidationProvider>
                </div>
              </v-col>
              <v-col  cols="12" xs="12" sm="12" md="6"  >
                <div>
                  <ValidationProvider name="Last name" rules="required" v-slot="{ errors }" >
                    <v-text-field
                        hide-details="auto"
                        :error-messages="errors"
                        dense
                        label="Last Name"

                        outlined
                        v-model="lastName"
                    ></v-text-field>

                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6"  >
                <div>
                  <ValidationProvider name="Maiden name" rules="" v-slot="{ errors }" >
                    <v-text-field
                        hide-details="auto" dense
                        label="Maiden Name"
                        :error-messages="errors"
                        outlined
                        v-model="maidenName"
                    ></v-text-field>

                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6" >
                <div>
                  <ValidationProvider name="Gender" rules="required" v-slot="{ errors }" >

                    <v-select
                        :error-messages="errors"
                        :items="getGenders"
                        label="Gender" dense
                        hide-details="auto" required
                        v-model="gender" outlined
                        item-text="name"
                        item-value="id"
                    ></v-select>

                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6">
                <div>

                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false" dense
                        :return-value.sync="dateOfBirth"
                        transition="scale-transition" outlined
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider name="Date Of Birth" rules="required" v-slot="{ errors }">
                        <v-text-field
                            v-model="dateOfBirth"
                            label="Date of Birth" dense
                            prepend-icon="mdi-calendar" outlined
                            readonly required
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"

                        ></v-text-field>
                      </ValidationProvider>
                      </template>
                      <v-date-picker
                          v-model="dateOfBirth"
                          no-title dense
                          scrollable
                          :max="(new Date().getFullYear() - 14) + '-12-31'"
                          :error-messages="errors"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(dateOfBirth)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>

                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6" >
                <div>
                  <ValidationProvider name="Marital status" rules="required" v-slot="{ errors }" >

                    <v-select
                        :error-messages="errors"
                        :items="getMaritalStatuses"
                        label="Marital Status"
                        hide-details="auto" dense outlined
                        v-model="maritalStatus"
                        item-text="name"
                        item-value="id"
                    ></v-select>

                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6" >
                <div>
                  <ValidationProvider name="TRN/SSN" rules="required" v-slot="{ errors }" >
                    <v-text-field
                        label="TRN/SSN"
                        :error-messages="errors"
                        hide-details="auto" dense outlined
                        v-model="trn"
                    ></v-text-field>
                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6" >
                <div>
                  <ValidationProvider name="Nationalities" rules="required" v-slot="{ errors }" >
                    <v-autocomplete
                        v-model="nationality"
                        :items="getNationalities"

                        :error-messages="errors"
                        clearable dense outlined
                        hide-details="auto"
                        hide-selected
                        item-text="name"
                        item-value="id"
                        label="Of which country are you a national?"

                    >
                    </v-autocomplete>
                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6" >
                <div>
                  <ValidationProvider name="Religion" rules="required" v-slot="{ errors }" >
                    <v-autocomplete
                        v-model="religion"
                        :items="getReligions"
                        dense outlined
                        :error-messages="errors"
                        clearable
                        hide-details="auto"
                        hide-selected
                        item-text="name"
                        item-value="id"
                        label="Religion"

                    >
                    </v-autocomplete>
                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6" v-if="religion !== 0">
                <div>
                  <ValidationProvider name="Denomination" rules="required" v-slot="{ errors }" >
                    <v-autocomplete
                        v-model="denomination"
                        :items="getDenominations"

                        :error-messages="errors"
                        clearable dense outlined
                        hide-details="auto"
                        hide-selected
                        item-text="name"
                        item-value="id"
                        label="Denomination"

                    >
                    </v-autocomplete>
                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6">
                <div>
                  <ValidationProvider name="Disability" rules="" v-slot="{ errors }" >
                    <v-text-field
                        label="Disability"
                        :error-messages="errors"
                        hide-details="auto" dense outlined
                        v-model="disability"
                    ></v-text-field>
                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12" sm="12">
                <h4 class="blue--text"> Emergency Contact </h4>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6">
                <div>
                  <ValidationProvider name="E First name" rules="required" v-slot="{ errors }" >
                    <v-text-field
                        label="First Name"
                        :error-messages="errors" dense outlined
                        hide-details="auto"
                        v-model="emergencyFirstName"
                    ></v-text-field>

                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6" >
                <div>
                  <ValidationProvider name="E Last name" rules="required" v-slot="{ errors }" >
                    <v-text-field
                        label="Last Name"
                        :error-messages="errors" dense outlined
                        hide-details="auto"
                        v-model="emergencyLastName"
                    ></v-text-field>

                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6">
                <div>
                  <ValidationProvider name="Relation" rules="required" v-slot="{ errors }" >
                    <v-autocomplete
                        :error-messages="errors"
                        :items="getRelationTypes"
                        label="Relation"
                        hide-details="auto"
                        v-model="emergencyRelation"
                        item-text="name"
                        item-value="id"
                        outlined dense clearable
                        hide-selected
                    >
                    </v-autocomplete>
                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6">
                <div>
                  <ValidationProvider name="E Email Address" rules="required" v-slot="{ errors }" >
                    <v-text-field
                        label="Email Address"
                        :error-messages="errors" dense outlined
                        hide-details="auto"
                        v-model="emergencyEmailAddress"
                    ></v-text-field>

                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="6" >
                <div>
                  <ValidationProvider name="E Phone number" rules="required" v-slot="{ errors }" >
                    <v-text-field
                        label="Phone Number"
                        :error-messages="errors"
                        hide-details="auto" dense outlined
                        v-model="emergencyPhoneNumber"
                    ></v-text-field>

                  </ValidationProvider>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end mt-4" >


                <v-btn
                    color="primary"
                    loading :loading="loading" :disabled="loading"
                    type="submit"> Save & Next</v-btn>
              </v-col>
            </v-row>
          </form>
        </ValidationObserver>
      </v-col>
    </v-row>
    <snackbar-notifier  :color="color" :text="text" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import TopBar from "@/components/TopBar";
import SnackbarNotifier from "@/components/SnackbarNotifier";
import {formSubmitter, updateProcessStep} from "@/services/userService";
export default {
  name: "PersonalInfoView",
  data: ()=>({
    loading: false,
    //date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    firstName: '',
    middleName: '',
    lastName: '',
    maidenName: '',
    gender: '',
    maritalStatus: '',
    nationality: '',
    religion: '',
    denomination: '',
    disability: '',
    dateOfBirth: undefined,
    dateDefault: null,
    trn: '',
    emergencyFirstName: '',
    emergencyLastName: '',
    emergencyRelation: '',
    emergencyEmailAddress: '',
    emergencyPhoneNumber: '',
    text: '',
    snackbar: false,
    color: ''
  }),
  watch: {
    religion: function (n, o){
      //this.denomination = ''
      this.$store.dispatch('utilities/FilterDenominations', { id: n})
    },
    PersonGet: {
      handler: function (n, o ) {
        this.firstName = n.firstName
        this.lastName = n.lastName
        this.middleName = n.middleName
        this.trn = n.trn_Ssn
        //console.log(n.denominationId)

        n.genderId !== null && n.genderId > 0 ? this.gender = n.genderId : this.gender = ""
        this.dateOfBirth = n.dateOfBirth !== null ? this.convertDate(n.dateOfBirth, 1) : null
        //this.maritalStatus = n.martialStatusId
        n.martialStatusId !== null ? this.maritalStatus = n.martialStatusId : this.maritalStatus = ""
        n.nationalityId !== null ? this.nationality = n.nationalityId : this.nationality = ""
        n.religionId !== null ? this.religion = n.religionId : this.religion = ""
        n.denominationId !== null ? this.denomination = n.denominationId : this.denomination = ""
        n.emergencyContactRelationId !== null ? this.emergencyRelation = n.emergencyContactRelationId : this.emergencyRelation = ""
        this.disability = n.disability
        this.emergencyFirstName = n.emergencyContactFirstName
        this.emergencyLastName = n.emergencyContactLastName
        this.emergencyPhoneNumber = n.emergencyContactPhone
        this.emergencyEmailAddress = n.emergencyContactEmail
        //this.emergencyRelation = n.emergencyContactRelationId
      },
      deep: true
    }
  },
  methods: {
    onSubmit() {
      this.loading = true
      formSubmitter('user/UpdatePersonalInformation', {
        firstName: this.firstName,
        middleName: this.middleName,
        lastName: this.lastName,
        maidenName: this.maidenName,
        genderId: this.gender,
        martialStatusId: this.maritalStatus,
        NationalityId: this.nationality,
        religionId: this.religion,
        denominationId: this.denomination,
        disability: this.disability,
        dateOfBirth: this.convertDate(this.dateOfBirth, 2),
        trn_Ssn: this.trn,
        emergencyContactFirstName: this.emergencyFirstName,
        emergencyContactLastName: this.emergencyLastName,
        emergencyContactRelationId: this.emergencyRelation,
        emergencyContactEmail: this.emergencyEmailAddress,
        emergencyContactPhone: this.emergencyPhoneNumber
      }).then(async response => {
        this.loading = false
        this.text = "Your Personal Information was successfully updated "
        this.color = "success"
        this.snackbar = true
        updateProcessStep((await this.$store.getters["user/getCurrentAppProcess"](this.$route)), true).then(response => {
          this.$store.dispatch('user/GetApplicantPercent')
        })
        this.$store.dispatch('user/MoveToNextStep', {route: this.$route})
        //this.$router.push('/ContactInformation')
      }).catch(error => {
        this.loading = false
        this.text = error
        this.color = "red"
        this.snackbar = true
      })

    },
    convertDate(inputFormat, type) {
      function pad(s) { return (s < 10) ? '0' + s : s; }
      //console.log(inputFormat)
      //console.log(new Date(inputFormat))
      if( type === 1){
        let d = new Date(inputFormat)
        return [d.getFullYear(), pad(d.getMonth()+1), pad(d.getDate()) ].join('-')
      }else {
        let d = this.dateOfBirth.split("-")
        //console.log(d)
        //console.log()

        return new Date(d[1] + "/" + d[2] + "/" + d[0])
      }

    }
  },
  computed: {
    personalInfo: function () {
      return this.$store.state.user.personal
    },
    ...mapState({
      getGenders:  state => state.utilities.gender,
      getMaritalStatuses:  state => state.utilities.maritalStatuses,
      getNationalities:  state => state.utilities.nationalities,
      getReligions:  state => state.utilities.religions,
      getDenominations:  state => state.utilities.filteredDenominations,
      getRelationTypes: state => state.utilities.relationTypes
    }),
    ...mapGetters({
      PersonGet: 'user/personalInfoGetter'
    })
  },
  mounted() {
    /*const loading = this.$vs.loading({
      target: this.$refs.personalInfo,
      color: 'dark',
      text: 'Getting your personal information'
    })*/
    /*const birthday = new Date('August 19, 1975');
    const xmas = new Date.getUTCDate();
    alert(xmas)*/
    /*const year = xmas.getYear(); // returns -100
    alert(year)
    new Date.now().getUTCFullYear + '-01-01'*/


    const today = new Date();
    const year = new Date().getFullYear();
    console.log(year)
    this.firstName = this.personalInfo.firstName
    this.lastName = this.personalInfo.lastName
    this.middleName = this.personalInfo.middleName
    this.trn = this.personalInfo.trn_Ssn
    //console.log(n.denominationId)

    this.personalInfo.genderId !== null ? this.gender = this.personalInfo.genderId : this.gender = ""
    this.dateOfBirth = this.personalInfo.dateOfBirth !== null ? this.convertDate(this.personalInfo.dateOfBirth, 1) : null
    //this.maritalStatus = n.martialStatusId
    this.personalInfo.martialStatusId !== null ? this.maritalStatus = this.personalInfo.martialStatusId : this.maritalStatus = ""
    this.personalInfo.nationalityId !== null ? this.nationality = this.personalInfo.nationalityId : this.nationality = ""
    this.personalInfo.religionId !== null ? this.religion = this.personalInfo.religionId : this.religion = ""
    this.personalInfo.denominationId !== null ? this.denomination = this.personalInfo.denominationId : this.denomination = ""
    this.personalInfo.emergencyContactRelationId !== null ? this.emergencyRelation = this.personalInfo.emergencyContactRelationId : this.emergencyRelation = ""
    this.disability = this.personalInfo.disability
    this.emergencyFirstName = this.personalInfo.emergencyContactFirstName
    this.emergencyLastName = this.personalInfo.emergencyContactLastName
    this.emergencyPhoneNumber = this.personalInfo.emergencyContactPhone
    this.emergencyEmailAddress = this.personalInfo.emergencyContactEmail
    this.$store.dispatch('user/GetApplicantPin').then(pin =>{
      this.$store.dispatch('user/SetPin', {pin: pin})
      let self = this
      this.$store.dispatch('user/GetApplicantPercent')
      setTimeout(function (){

        //self.$store.dispatch('user/GetPersonalInformation')
        /*.then(response =>{
          loading.close()
        })*/

      }, 1000)
    }).catch(error=>{

    })
  },
  components: {SnackbarNotifier, TopBar}
}
</script>

<style scoped>

</style>
