
<script>
import {mapState} from "vuex";

export default {
  name: "FrequentlyAskedQuestionByProgram",
  data: () => ({
    faqs: [{question: '', answer: ''}]
  }),
  computed: {
    ...mapState({
      //getPrograms: state => state.program.searchPrograms,
      currentProgram: state => state.program.programInfo
    }),
  },
  methods: {
    add(index) {
      this.currentProgram.faqs.push({ question: '', answer: '', programId: this.$route.params.id });
    },
    remove(index, id) {
      this.currentProgram.faqs.splice(index, 1);
      this.$store.dispatch("program/RemoveProgramInformationFaq", id)
    }
  }
}
</script>

<template>
  <div style="padding: 15px; background: white">


    <v-row  v-for="(input,k) in currentProgram.faqs" :key="k" style="margin-bottom: -30px">

      <v-col cols="12" sm="12">
        <div>
          <v-text-field
              label="Enter Question"
              solo
              v-model="input.question"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12" sm="11">
        <div>
          <v-textarea
              label="Enter Answer"
              solo
              v-model="input.answer"
          ></v-textarea>
        </div>
      </v-col>

      <v-col cols="12" sm="1">
        <v-btn color="red" class="mt-2" icon dark @click="remove(k, input.id)" v-show="k || ( !k && faqs.length > 0)">
          <v-icon class=""  >mdi-close</v-icon>
        </v-btn>
      </v-col>


    </v-row>
    <div style="text-align: right">
      <v-btn  @click="add()" color="secondary" dark small>
       <v-icon class=" mr-3">mdi-plus</v-icon> Add
      </v-btn>
    </div>
  </div>
</template>

<style scoped>

</style>