<template>
  <v-row >
    <v-col cols="12">
      <v-dialog overlay-color="white" overlay-opacity="1"
                v-model="modalTrigger"
                persistent
                max-width="900"
      >

        <v-card>
          <v-card-title class="text-h5 blue--text">
           Thank you for applying
          </v-card-title>
          <v-card-text>
            <h3>You have successfully completed the application for the
              {{ this.getProgram.degreeName}} {{ this.getProgram.programName}} to commence at {{ this.getProgram.termEntry }}</h3>
            <h5>The current status of your application is <span style="color: #2196f3">{{ getStatus.statusName }}</span></h5>
            <br><br>

            <span >
              Your application will expire on <span style="color: #2196f3">{{ getStatus.expirationDate | formatDate }}</span>
            </span>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div>

              <v-btn
                  color="blue darken-1"
                  dark small
                  @click="modalTrigger = false"
                  to="/OtherDocuments"
              >
                Upload Supplementary Documents
              </v-btn>

              <br class="d-sm-none">
<!--              <br class="d-sm-none">-->
              <v-btn
                  color="blue darken-1"
                  text small
                  @click="modalTrigger = false"

              >
                <b>I want to review my application</b>
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import {mapState} from "vuex";
import {bus} from "@/main";

export default {
  name: "CompletionPage",
  data: () => ({
    modalTrigger: false
  }),
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value)
      return value.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        //weekday: 'short',
      })
    }
  },
  computed: {
    ...mapState({
      AppPercent: state => state.user.percent,
      getProgram: state => state.user.program,
      getStatus: state => state.user.paymentStatus
    })
  },
  mounted(){
    this.AppPercent === 100 && Object.keys(this.getProgram).length !== 0 ? this.modalTrigger = true : ''

    bus.$on('checkCompletion', (data) => {
      this.modalTrigger = data;
    })
  }
}
</script>

<style scoped>

</style>