<template>
  <div style="padding: 0 15px" ref="programInfo">
    <vs-row>
      <vs-col>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <vs-row>

          <vs-col w="12">
            <div>
              <ValidationProvider name="Campus" rules="required" v-slot="{ errors }" >
                <vs-select state="dark"  label="Campus"  v-model="campusId" v-if="getCampuses.length > 0" block  data-vv-as="gender">
                  <vs-option  :label="item.campusName" :value="item.campusId" :key="item.campusId" v-for="item in getCampuses">
                    {{ item.campusName }}
                  </vs-option>
                  <template #message-danger>
                    {{ errors[0] }}
                  </template>
                </vs-select>

              </ValidationProvider>
            </div>
          </vs-col>
          <vs-col w="12">
            <div>
              <ValidationProvider name="Degree Level" rules="required" v-slot="{ errors }" >
                <vs-select state="dark"  label="Degree Level"  v-model="courseLevelId" v-if="getDegreeLevels.length > 0" block  data-vv-as="gender">
                  <vs-option  :label="item.courseLevelName" :value="item.courseLevelId" :key="item.campusId" v-for="item in getDegreeLevels">
                    {{ item.courseLevelName }}
                  </vs-option>
                  <template #message-danger>
                    {{ errors[0] }}
                  </template>
                </vs-select>

              </ValidationProvider>
            </div>
          </vs-col>
          <vs-col w="12">
            <div>
              <ValidationProvider name="Department" rules="required" v-slot="{ errors }" >
                <vs-select state="dark"  label="Department"  v-model="departmentId" v-if="getDepartments.length > 0" block  data-vv-as="gender">
                  <vs-option  :label="item.departmentName" :value="item.departmentId" :key="item.departmentId" v-for="item in getDepartments">
                    {{ item.departmentName }}
                  </vs-option>
                  <template #message-danger>
                    {{ errors[0] }}
                  </template>
                </vs-select>

              </ValidationProvider>
            </div>
          </vs-col>
          <vs-col w="12">
            <div>
              <ValidationProvider name="Program" rules="required" v-slot="{ errors }" >
                <vs-select state="dark" label="Program"  v-model="programId" v-if="getProgrammes.length > 0" block  data-vv-as="gender">
                  <vs-option  :label="item.programName" :value="item.programInstanceId" :key="item.programInstanceId" v-for="item in getProgrammes">
                    {{item.degreeAbbreviation + " - " +  item.programName }}
                  </vs-option>
                  <template #message-danger>
                    {{ errors[0] }}
                  </template>
                </vs-select>

              </ValidationProvider>
            </div>
          </vs-col>
          <vs-col w="12">
            <div>
              <ValidationProvider name="Open Terms" rules="required" v-slot="{ errors }" >
                <vs-select state="dark"  label="Open Terms"  v-model="termId" v-if="getTerms.length > 0" block  data-vv-as="gender">
                  <vs-option  :label="item.termEntry" :value="item.termId" :key="item.termId" v-for="item in getTerms">
                    {{item.termEntry }}
                  </vs-option>
                  <template #message-danger>
                    {{ errors[0] }}
                  </template>
                </vs-select>

              </ValidationProvider>
            </div>
          </vs-col>
          <vs-col w="12">
            <div style="float: right">
              <vs-button type="submit" square >Save & Continue</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </form>
    </ValidationObserver>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "Program",
  data: () => ({
    campusId: '',
    courseLevelId: '',
    departmentId: '',
    programId: '',
    termId: '',

  }),
  watch:{
    ProgramGet: {
      handler: function (n, o) {
        this.campusId = n.campusId
        this.courseLevelId = n.degreeId
        this.departmentId = n.departmentId
        console.log(n.programInstanceId)
        this.programId = n.programInstanceId
        this.termId = n.termId
      }, deep: true
    },
    campusId: function (newCampus, oldCampus) {
      this.$store.dispatch('program/SetDegreeLevel',{
        id: this.campusId
      })
      this.$store.dispatch('program/GetOpenTerms', {
        campusId: this.campusId,
        programId: this.programId
      })
      if(newCampus !== this.ProgramGet.campusId){

        this.courseLevelId = ''
        this.departmentId = ''
        this.programId = ''
      }
    },
    courseLevelId: function (newDg, oldDg) {
      this.$store.dispatch('program/SetAreaOfInterest', {
        id: this.courseLevelId
      })
      if(newDg !== this.ProgramGet.courseLevelId) {

        this.departmentId = ''
        this.programId = ''
      }
    },
    departmentId: function (newArea, oldArea) {
      this.$store.dispatch('program/SetProgramOfInterest', {
        id: this.departmentId
      })
      if(newArea !== this.ProgramGet.departmentId) {
        this.programId = ''
      }
    },
    programId: function(newVal, oldVal) {

      this.$store.dispatch('program/GetOpenTerms', {
        campusId: this.campusId,
        programId: this.programId
      })
      if(this.ProgramGet.campusId && this.ProgramGet.programId){

      }

    },
  },
  computed: {
    ...mapState({
      getCampuses: state => state.program.campus,
      getDegreeLevels: state => state.program.degreeLevel,
      getDepartments: state => state.program.departments,
      getProgrammes: state => state.program.programmes,
      getTerms: state => state.program.terms,
    }),
    ...mapGetters ({
      ProgramGet: 'user/programInfoGetter'
    })
  },
  methods: {
    onSubmit() {
      this.$store.dispatch('user/UpdateProgramInformation', {
        pin: this.$store.state.user.pin,
        campusId: this.campusId,
        //campusName: "string",
       // courseLevelId: this.degreeId,
        //courseLevelName: "string",
        degreeId: this.courseLevelId,
        //degreeAbbreviation: "string",
        //degreeName: "string",
        departmentId: this.departmentId,
        //departmentName: "string",
        //programId: 0,
        programName: "string",
        termId: this.termId,
        //termEntry: "string",
        programInstanceId: this.programId

      }).then(response =>{
        this.$vs.notification({
          flat: true,
          color: "success",
          position: "top-center",
          title: 'Success',
          text: "Your Personal Information was successfully updated "
        })
      }).catch(error=> {
        this.$vs.notification({
          flat: true,
          color: "warn",
          position: "top-center",
          title: 'Update Error',
          text: "Please check to see if all the forms are added"
        })
      })
    },
  },
  mounted() {
    /*const loading = this.$vs.loading({
      target: this.$refs.programInfo,
      color: 'dark',
      text: 'Getting your programme information'
    })*/
    this.$store.dispatch('user/GetApplicantPin').then(pin =>{
      this.$store.dispatch('user/SetPin', {pin: pin})
      let self = this
      setTimeout(function (){
        self.$store.dispatch('user/GetProgramInformation')/*.then(response =>{
          loading.close()
        })*/
      }, 1000)
    }).catch(error=>{

    })
  }
}
</script>

<style scoped>

</style>