<template>
  <v-row style="min-height: 100vh">
    <v-col cols="12">
      <v-row class="cont">
        <img-container />
<!--        <v-col cols="12" xs="12">
          <div  class="d-flex justify-start">
            <img  src="../assets/ncu_logo.png" class="d-none d-lg-block" alt="">
          </div>

        </v-col>
        <v-col cols="12" xs="12">
          <div class="d-flex justify-center">
            <img  class="d-lg-none"  src="../assets/ncu_logo_small.png" alt="">
          </div>
        </v-col>-->
        <v-col cols="12" xs="12">
          <h2 class="heading_for_welcome"  style="color: #317eac">Resend Verification</h2><br>
          <v-alert type="info" text dense >Please enter the email you used to create the account</v-alert>
          <ValidationObserver ref="observer" v-slot="{ invalid, validated, handleSubmit, validate }">
            <v-form @submit.prevent="handleSubmit(onSubmit)">
              <div>
                <ValidationProvider name="Email address" :rules="{ required: true, regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }" v-slot="{ errors }" >
                  <v-text-field
                      label="Email Address"
                      :error-messages="errors"


                      v-model="email"
                  ></v-text-field>

                </ValidationProvider>
              </div>

              <div style="text-align: center">
                <v-btn

                    color="primary"
                    loading :loading="loading" :disabled="loading"
                    block rounded
                    type="submit"
                >
                  Send Verification Link
                </v-btn>
                <div style="margin-top: 5px">
                  <v-btn
                      text
                      color="primary"
                      to="/login"
                  >
                    I dont need to resend anymore
                  </v-btn>

                </div>
              </div>
            </v-form>
          </ValidationObserver>
        </v-col>
      </v-row>
      <v-dialog
          v-model="modalTrigger"
          persistent
          max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            Verification Link has been resent!
          </v-card-title>
          <v-card-text>
            Please visit your email to get link to verify your account. Upon successful activation you will be redirected to login
            <br><br>
            <v-alert
                text
                color="info"
            >
              <span >If the email verification is not found in your inbox, please check your spam folder or wait a few minutes and check for it again.</span>
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                text
                @click="modalTrigger = false"

            >
              I understand
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <snackbar-notifier :color="color" :text="text" />
    </v-col>
  </v-row>
</template>

<script>
import SnackbarNotifier from "@/components/SnackbarNotifier";
import ImgContainer from "@/components/imgContainer";
export default {
  name: "ForgotPassword",
  data: () => ({
    email: '',
    loading: false,
    modalTrigger: false,
    color: '',
    text: ''
  }),
  components: {ImgContainer, SnackbarNotifier },
  methods: {
    onSubmit() {
      this.$refs.observer.validate()
      this.loading = true
      this.$store.dispatch('user/ResendVerificationLink', {
        emailAddress: this.email,
        ActivationLink : this.$store.state.resetLink
      }).then(response =>{
        this.loading = false
        this.modalTrigger = true
      }).catch(error=> {

        this.loading = false
        this.text = error
        this.color = "red"
        this.snackbar = true
      })
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 320px){
  .cont{
    margin: 15px;
    margin-top: 15vh;
    background: white;
    padding: 0;
    border-radius: 5px;

  }
}
@media screen and (min-width: 600px){
  .cont{
    margin: 15px;
    margin-top: 6vh;
    background: white;
    padding: 0;
    border-radius: 5px;

  }
}
img{
  max-width: 400px;
  max-height: 400px;
  height: auto;
  width: auto;
}
</style>
