<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-dialog
            transition="dialog-top-transition"
            max-width="600" persistent
            v-model="active"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                depressed
                v-bind="attrs"
                v-on="on"
                class="blue--text font-weight-bold"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Source Of Funds</v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar
                  color="primary"
                  dark
              >Add Source Of Funding</v-toolbar>
              <v-card-text>
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(onSubmit)">
                    <v-row>
                      <v-col  md="6" sm="12"  >
                        <div>
                          <ValidationProvider name="Source of funding" rules="required" v-slot="{ errors }" >

                            <v-select
                                :error-messages="errors"
                                :items="getSources"
                                label="Source"
                                hide-details="auto"
                                v-model="sourceId"
                                item-text="name"
                                item-value="id"
                            ></v-select>

                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col  md="6" sm="12"  >
                        <div>
                          <ValidationProvider name="Amount" rules="" v-slot="{ errors }" >
                            <v-text-field
                                label="Amount"
                                :error-messages="errors"
                                hide-details="auto"
                                v-model="amount"
                            ></v-text-field>

                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col  md="6" sm="12"  >
                        <div>
                          <ValidationProvider name="Organization" rules="" v-slot="{ errors }" >
                            <v-text-field
                                label="Organization"
                                :error-messages="errors"
                                hide-details="auto"
                                v-model="organization"
                            ></v-text-field>

                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col  md="6" sm="12"  >
                        <div>
                          <ValidationProvider name="Authorizing personnel" rules="" v-slot="{ errors }" >
                            <v-text-field
                                label="Authorizing Personnel"
                                :error-messages="errors"
                                hide-details="auto"
                                v-model="authPersonnel"
                            ></v-text-field>

                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col  md="6" sm="12"  >
                        <div>
                          <ValidationProvider name="Position" rules="" v-slot="{ errors }" >
                            <v-text-field
                                label="Position"
                                :error-messages="errors"
                                hide-details="auto"
                                v-model="position"
                            ></v-text-field>

                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col  md="6" sm="12"  >
                        <div>
                          <ValidationProvider name="Telephone" rules="" v-slot="{ errors }" >
                            <v-text-field
                                label="Telephone"
                                :error-messages="errors"
                                hide-details="auto"
                                v-model="telephone"
                            ></v-text-field>

                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col  md="12" sm="12"  >
                        <div>
                          <ValidationProvider name="Level of sponsorship" rules="required" v-slot="{ errors }" >
                            <h5>Level of Sponsorship</h5>
                            <v-radio-group
                                v-model="sponsorLevel"
                                row
                                :error-messages="errors"
                                hide-details="auto"
                            >
                              <v-radio
                                  label="Partial"
                                  value="Partial"
                              ></v-radio>
                              <v-radio
                                  label="Complete"
                                  value="Complete"
                              ></v-radio>
                            </v-radio-group>
                          </ValidationProvider>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex justify-end mt-4" >

                        <v-btn
                            text
                            @click="dialog.value = false"
                        >Cancel</v-btn>
                        <v-btn
                            color="primary"
                            type="submit"> Add</v-btn>
                      </v-col>
                    </v-row>
                  </form>
                </ValidationObserver>

              </v-card-text>
              <!--            <v-card-actions class="justify-end">
                            <v-btn
                                text
                                @click="dialog.value = false"
                            >Cancel</v-btn>
                            <v-btn
                                color="primary"

                                type="submit"> Add</v-btn>
                          </v-card-actions>-->
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
    <snackbar-notifier  :color="color" :text="text" />
  </v-container>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import SnackbarNotifier from "@/components/SnackbarNotifier";
export default {
  name: "addSourceOfFunding",
  components: {SnackbarNotifier},
  data: ()=>({
    sourceId: '',
    amount: '',
    organization: '',
    authPersonnel: '',
    position: '',
    telephone: '',
    sponsorLevel: null,
    text: '',
    color: '',
    active: false
  }),
  computed: {
    ...mapState({
      getSources: state => state.utilities.sourcesOfFunding,
      getExamBoards: state => state.utilities.examBoards,
      getSubjects: state => state.utilities.subjects
    }),

  },
  methods: {
    onSubmit(){
      console.log(this.year)
      this.$store.dispatch('user/AddSourceOfFunding', {

        //"pin": string,
        "sourceOfFundsId": this.sourceId,
        //"schoolName": "string",
        //"area": "string",
        "head": this.organization,
        "name": this.authPersonnel,
        "position": this.position,
        "phone": this.telephone,
        "level": this.sponsorLevel,
        "amount": this.amount
      }).then(response =>{
        this.text = "The source of funding was added"
        this.color = "success"
        this.snackbar = true
        this.active = false
        this.$store.dispatch('user/GetSourceOfFunding')

      }).catch(error=> {
        this.text = "Something went wrong, please try again "
        this.color = "red"
        this.snackbar = true
        this.active = false
        //this.$store.dispatch('user/GetSourceOfFunding')
      })
    },
  }
}
</script>

<style scoped>

</style>