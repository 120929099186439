export default {
    FilterDenominations: (state) => async (id) => {
        return state.denominations.filter(denomination => denomination.id === id)
    },
    FilterSchoolByType: (state) => async (id) => {
       return  state.schoolsListByTypes.filter(async school => {
           if (school.schoolTypeId === id) {
               console.log(school)
               return await school.schools
           }
       })
        //return state.schoolsListByTypes.filter(school => school.schoolTypeId === id)
    },

}