import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from "@/views/LoginView";
import CreateAccount from "@/views/CreateAccount";
import Welcome from "@/views/Welcome";
import Basic from "@/views/Basic";
import NewAccount from "@/views/NewAccount";
import ForgotPassword from "@/views/ForgotPassword";
import RecoverPassword from "@/views/RecoverPassword";
import AccountVerification from "@/views/AccountVerification";
import PersonalInfoView from "@/views/PersonalInfoView";
import ContactInfoView from "@/views/ContactInfoView";
import ProgramView from "@/views/ProgramView";
import PreviousSchools from "@/views/PreviousSchools";
import RequiredDocuments from "@/views/RequiredDocuments";
import OtherDocuments from "@/views/OtherDocuments";
import SourceOfFunds from "@/views/SourceOfFunds";
import DeclarationAgreements from "@/views/DeclarationAgreements";
import PayApplicationFee from "@/views/PayApplicationFee";
import CurrentApplication from "@/views/CurrentApplication";
import Logout from "@/views/Logout";
import ResendVerification from "@/views/ResendVerification.vue";
import Dashboard from "@/views/Dashboard.vue";
import PersonOfInterest from "@/views/PersonOfInterest.vue";
import AdminProgramInformation from "@/views/AdminProgramInformation.vue";
import ManageProgramInformation from "@/views/ManageProgramInformation.vue";
import AdminReports from "@/views/AdminReports.vue";

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Applicant Welcome ', component: HomeView },
  { path: '/PersonalInformation', name: 'Personal Information', component: PersonalInfoView },
  { path: '/ContactInformation', name: 'Contact Information', component: ContactInfoView },
  { path: '/ProgrammeSelection', name: 'Choose when you want to start', component: ProgramView },
  { path: '/PreviousSchools', name: 'Previous Education', component: PreviousSchools },
  { path: '/SourceofFunding', name: 'Source Of Funding', component: SourceOfFunds },
  { path: '/RequiredDocuments', name: 'Required Documents', component: RequiredDocuments },
  { path: '/OtherDocuments', name: 'Other Documents', component: OtherDocuments },
  { path: '/Declaration&Agreement', name: 'Declaration & Agreement', component: DeclarationAgreements },
  { path: '/PayApplicationFee', name: 'Pay Application Fee', component: PayApplicationFee },
  { path: '/current_application', name: 'Current Application', component: CurrentApplication, meta:  { layout: 'current'} },
  { path: '/logout', name: 'Logout', component: Logout, meta:  { layout: 'empty'} },
  /*{
    path: '/logout',
    name: 'Logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ '../views/Logout.vue')
  },*/

  { path: '/login', name: 'Login', component: LoginView, meta:  { layout: 'login'} },
  { path: '/create_account_by_search', name: 'Create Account - Search', component: Basic, meta:  { layout: 'login'} },
  { path: '/create_account_by_filter', name: 'Create Account - Filter', component: CreateAccount, meta:  { layout: 'login'} },
  { path: '/forgot_password', name: 'Forgot Password', component: ForgotPassword, meta:  { layout: 'login'} },
  { path: '/resend_verification', name: 'Resend Verification', component: ResendVerification, meta:  { layout: 'login'} },
  { path: '/recover_password', name: 'Recover Password', component: RecoverPassword, meta:  { layout: 'login'} },
  { path: '/AccountVerification', name: 'Account Verification', component: AccountVerification, meta:  { layout: 'empty'} },
  { path: '/Welcome', name: 'Welcome', component: Welcome, meta:  { layout: 'login'} },

    //Prospect Management
  { path: '/admin/prospects',  meta: { layout: 'admin' }, name: 'Prospect Management', component: Dashboard  },
  { path: '/admin/prospects/:id', name: 'Admin - Student', component: PersonOfInterest, meta: { layout: 'admin' } },


  { path: '/admin/program-info', name: 'Admin - Program Information', component: AdminProgramInformation, meta: { layout: 'admin' } },
  { path: '/admin/program-info/program/:id', name: 'Admin - Manage Single Program Information', component: ManageProgramInformation, meta: { layout: 'admin' } },

  { path: '/admin/reports', name: 'Reports', component: AdminReports, meta: { layout: 'admin' } },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
