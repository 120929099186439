<template>
    <v-row style="min-height: 100vh;">

      <v-col cols="12" xs="12" >
        <v-row>
          <v-col cols="12">
            <br>
            <img-container />

          </v-col>
<!--          <v-col cols="12" xs="12" sm="12" md="6" lg="5" >

            <help-video class="d-none d-md-block  video-container" source="https://www.youtube.com/embed/os5ebayI-oY" />
          </v-col>-->
          <v-col cols="12" xs="12"  sm="12"   >

            <v-row no-gutters>

              <v-col cols="12">
                <h2 class="heading_for_welcome" >Create Account</h2><br>
                <v-alert dense type="success" text>Complete the form to below to start your application</v-alert>
<!--                <help-modal  source="https://www.youtube.com/embed/os5ebayI-oY" />-->
              </v-col>
              <v-col cols="12" xs="12">
                <br>
                <h4 v-if="currentStep === 1">What programme are you interested in pursing?</h4>
                <h4 v-if="currentStep === 2">Tell us about yourself</h4>
              </v-col>
              <v-col cols="12" xs="12">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(onSubmit)">
                    <ValidationObserver v-if="currentStep === 1">
                      <fieldset style="border: none">

                        <v-row>
                          <v-col cols="12" xs="12" sm="12" md="12"   >
                            <div>
                              <ValidationProvider name="Area Of Interest" rules="required" v-slot="{ errors }" >

                                <v-select class="fix_overflow"
                                    :error-messages="errors"
                                    :items="getAreaOfInterest"
                                    label="Area Of Interest"
                                    hide-details="auto"
                                    v-model="departmentId"
                                    item-text="departmentName"
                                    item-value="departmentId"
                                ></v-select>

                              </ValidationProvider>
                            </div>
                          </v-col>

                          <v-col cols="12" xs="12" sm="12" md="12" v-if="getDegreeLevels.length !== 0" >
                            <div>
                              <ValidationProvider name="Degree Level" rules="required" v-slot="{ errors }" >

                                <v-select  class="fix_overflow"
                                    :error-messages="errors"
                                    :items="getDegreeLevels"
                                    label="Degree Level"
                                    hide-details="auto"
                                    v-model="courseLevelId"
                                    item-text="courseLevelName"
                                    item-value="courseLevelId"
                                ></v-select>

                              </ValidationProvider>
                            </div>
                          </v-col>

                          <v-col cols="12" xs="12"   sm="12" md="12" v-if="getProgrammes.length !== 0" >
                            <div>
                              <ValidationProvider name="Programme Of Interest" rules="required" v-slot="{ errors }" >

                                <v-select  class="fix_overflow"
                                    :error-messages="errors"
                                    :items="getProgrammes"
                                    label="Programme Of Interest"
                                    hide-details="auto"
                                    v-model="programId"
                                    :item-text="item => item.degreeAbbreviation +' - '+ item.programName"
                                    item-value="programId"
                                    return-object
                                ></v-select>

                              </ValidationProvider>
                            </div>
                          </v-col>
                          <v-col cols="12" xs="12" sm="12" md="12"  v-if="getCampuses.length !== 0" >
                            <div>
                              <ValidationProvider name="Campus" rules="required" v-slot="{ errors }" >

                                <v-select class="fix_overflow"
                                    :error-messages="errors"
                                    :items="getCampuses"
                                    label="Where do you want to study?"
                                    hide-details="auto"
                                    v-model="campusId"
                                    item-text="campusName"
                                    item-value="campusId"
                                ></v-select>

                              </ValidationProvider>
                            </div>
                          </v-col>
                          <v-col cols="12"  xs="12" sm="12" md="12"   v-if="getCampuses.length !== 0 ">
                            <div>
                              <ValidationProvider name="When do you want to start" rules="required" v-slot="{ errors }" >

                                <v-select
                                    :error-messages="errors"
                                    :items="getTerms"
                                    label="Open Terms"
                                    hide-details="auto"
                                    v-model="termId"
                                    item-text="termEntry"
                                    item-value="termId"
                                ></v-select>

                              </ValidationProvider>
                            </div>
                          </v-col>

                        </v-row>
                      </fieldset>
                    </ValidationObserver>
                    <ValidationObserver v-else-if="currentStep === 2">
                      <fieldset style="border: none; padding: 15px">
                        <legend></legend>
                        <v-row >
                          <v-col cols="12" xs="12" md="6" sm="12"  >
                            <div>
                              <ValidationProvider name="First name" rules="required" v-slot="{ errors }" >
                                <v-text-field
                                    label="First Name"
                                    :error-messages="errors"
                                    hide-details="auto"
                                    v-model="firstName"
                                ></v-text-field>

                              </ValidationProvider>
                            </div>
                          </v-col>
                          <v-col cols="12" xs="12"  md="6" sm="12"  >
                            <div>
                              <ValidationProvider name="Last name" rules="required" v-slot="{ errors }" >
                                <v-text-field
                                    hide-details="auto"
                                    label="Last Name"
                                    :error-messages="errors"

                                    v-model="lastName"
                                ></v-text-field>

                              </ValidationProvider>
                            </div>
                          </v-col>
                          <v-col cols="12" xs="12"   md="6" sm="12"  >
                            <div>
                              <ValidationProvider name="Email Address"
                                                  :rules="{ required: true, regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }"
                                                  v-slot="{ errors }" >
                                <v-text-field
                                    label="Email Address"
                                    :error-messages="errors"
                                    hide-details="auto"
                                    v-model="email"
                                ></v-text-field>

                              </ValidationProvider>
                            </div>
                          </v-col>
                          <v-col cols="12" xs="12"   md="6" sm="12"  >
                            <div>
                              <ValidationProvider name="Phone Number" rules="required" v-slot="{ errors }" >
                                <v-text-field
                                    label="Phone Number"
                                    :error-messages="errors"

                                    hide-details="auto"

                                    v-model="phoneNumber"
                                ></v-text-field>

                              </ValidationProvider>
                            </div>
                          </v-col>
                          <v-col cols="12" xs="12"   md="6" sm="12"  >
                            <div>
                              <ValidationProvider name="Gender" rules="required" v-slot="{ errors }" >

                                <v-select
                                    :error-messages="errors"
                                    :items="getGenders"
                                    label="Gender"
                                    hide-details="auto"
                                    v-model="gender"
                                    item-text="name"
                                    item-value="id"
                                ></v-select>

                              </ValidationProvider>
                            </div>
                          </v-col>
                          <v-col cols="12" xs="12"   md="6" sm="12"  >
                            <div>
                              <ValidationProvider name="Password" rules="required" v-slot="{ errors }" >
                                <v-text-field
                                    label="Password"
                                    :error-messages="errors"
                                    type="password"
                                    hide-details="auto"
                                    v-model="password"
                                    :type="show1 ? 'text' : 'password'"
                                    @click:append="show1 = !show1"
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                ></v-text-field>
                              </ValidationProvider>
                            </div>
                          </v-col>
                        </v-row>
                      </fieldset>
                    </ValidationObserver>

                    <v-row>
                      <v-col cols="12" xs="12"  class="d-flex justify-end mt-4" >
                        <v-btn
                            v-if="currentStep === 1"
                            class="mr-3"
                            color="text"
                            to="/login"

                        >Take me to Login</v-btn>
                        <v-btn
                            v-if="currentStep === 2"
                            class="mr-3"
                            color="text"
                            @click="stepBack"

                        >Back</v-btn>
                        <v-btn

                            color="primary"

                            loading :loading="loading" :disabled="loading"
                            type="submit">{{ currentStep === 1 ? "Next" : "Create Account"}}</v-btn>
                      </v-col>
                    </v-row>
                  </form>
                </ValidationObserver>
              </v-col>
            </v-row>


          </v-col>


          <v-snackbar
              v-model="snackbar" multi-line
          >
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                  color="pink"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
          <v-dialog
              v-model="modalTrigger"
              persistent
              max-width="600"
          >

            <v-card>
              <v-card-title class="text-h5">
                Account Created Successfully
              </v-card-title>
              <v-card-text >
                <span style="color: #111 !important;"></span>
                <br><br>
                <v-alert
                    text
                    color="info"
                >
                      <span  style="color: #111 !important;">If the activation email is not found in your inbox, please check your spam folder or wait a few minutes and check for it again.</span>
                </v-alert>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="green darken-1"
                    text
                    @click="modalTrigger = false"
                    to="/login"
                >
                  Ok, Take me to Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>




      </v-col>
    </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AccountSuccess from "@/components/accountSuccess";
import HelpVideo from "@/components/helpVideo";
import HelpModal from "@/components/helpModal";
import ImgContainer from "@/components/imgContainer";
export default {
  name: "CreateAccount",
  components: {ImgContainer, HelpModal, HelpVideo, AccountSuccess},
  data:() => ({
    show1: false,
    loading: false,
    filterSwitch: true,
    e1: 1,
    currentStep: 1,
    active: false,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password  : '',
    gender: '',
    campusId: '',
    termId: '',
    courseLevelId: '',
    departmentId: '',
    programId: '',
    degreeId: '',
    terms: false,
    genderList: ["blue"],
    hasVisiblePassword: false,
    snackbar: false,
    text: '',
    modalTrigger: false
  }),
  methods: {
    stepBack(){
      this.currentStep = 1
    },
    onSubmit () {

      let self = this
      if(this.currentStep === 2){
        this.loading = true
        this.$store.dispatch('user/CreateAccount', {
          emailAddress: this.email,
          Phone: this.phoneNumber,
          password: this.password,
          firstName: this.firstName,
          middleName: "",
          lastName: this.lastName,
          genderId: this.gender,
          campusId: this.campusId,
          degreeLevel: this.degreeId,
          programId: this.programId?.programId,
          departmentId: this.departmentId,
          termId: this.termId,
        }).then(response => {
          this.loading = false
          this.modalTrigger = true
        }).catch(error => {
          this.loading = false
          //console.log(error)
          this.text = error.info
          this.snackbar = true
        })
      }else{
        this.currentStep++;
      }

    }
  },
  watch: {
    // whenever question changes, this function will run
    departmentId: function (newArea, oldArea) {
      //console.log(newArea)
      this.$store.dispatch('program/SetDegreeLevel',{
        id: this.departmentId
      })
      if(newArea !== this.ProgramGet.departmentId) {
        this.courseLevelId = ''
        this.campusId = ''
        this.programId = ''
      }
    },
    courseLevelId: function (newDg, oldDg) {

      this.$store.dispatch('program/SetProgramOfInterest', {
        id: this.courseLevelId
      })
      //alert("change")
      if(newDg !== this.ProgramGet.courseLevelId) {
        this.programId = ''
        this.campusId = ''
        this.termId = ''
      }
    },

    programId: function(newVal, oldVal) {
      //console.log(this.programId)
      this.degreeId = this.programId?.degreeId
      console.log(typeof this.programId)
      console.log(typeof this.programId === "object" ? this.programId?.programId : this.programId)
      this.$store.dispatch('program/setCampuses',{
        programId: typeof this.programId === "object" ? this.programId?.programId : this.programId
      })
      /* if (this.programId && this.campusId ){
         this.$store.dispatch('program/GetOpenTerms', {
           campusId: this.campusId,
           programId: this.programId
         })
       }*/
      if(newVal !== this.ProgramGet.programId?.programId){
        this.campusId = ''
        this.termId = ''
      }

    },
    campusId: function (newCampus, oldCampus) {
      if (this.programId?.programId && this.campusId ){
        this.$store.dispatch('program/GetOpenTerms', {
          campusId: this.campusId,
          programId: this.programId?.programId
        })

      }
      if(newCampus !== this.ProgramGet.campusId){
        this.termId = ''
      }
    },

  },
  computed: {
    ...mapState({

      getAreaOfInterest: state => state.program.programDataByAreaOfInterest,
      getGenders:  state => state.utilities.gender,
      getCampuses: state => state.program.campus,
      getDegreeLevels: state => state.program.degreeLevel,
      getDepartments: state => state.program.departments,
      getProgrammes: state => state.program.programmes,
      getTerms: state => state.program.terms
    }),
    ...mapGetters ({
      ProgramGet: 'user/programInfoGetter'
    })
  }
}
</script>

<style scoped>
.bg_cover{
  background-image: url('~@/assets/bg4.jpg');
  min-height: 100vh;
}

.cont{

  margin-top: 5vh;
  background: white;
  padding: 15px;
  border-radius: 5px;

}
img{
  max-width: 400px;
  max-height: 400px;
  height: auto;
  width: auto;
}

@media screen and (min-width:320px) {
  .cont {
    margin-top: 3vh;
  }
  .long_img{
    max-width: 150px;
    max-height: 150px;
    width: auto;
    height: auto;
  }
  .heading_for_welcome{
    color: #317eac;
    font-size: 18px;
  }
}
@media screen and (min-width:600px){
  .heading_for_welcome{
    color: #317eac;
    font-size: 20px;
  }
  .cont{
    margin-top: 3vh;
  }
  img{
    max-width: 500px;
    max-height: 500px;
    height: auto;
    width: auto;
  }
}
@media screen and (min-width:1000px){
  img{
    max-width: 600px;
    max-height: 600px;
    height: auto;
    width: auto;
  }
  .heading_for_welcome{
    color: #317eac;
    font-size: 24px;
  }
  .cont{
    margin-top: 3vh;
  }
}

/*img{
  max-width: 600px;
  max-height: 400px;
  height: auto;
  width: auto;
}*/
</style>
