<template>
  <div>
    <div v-if="this.$route.query.code === null || this.$route.query.code === '' || this.$route.query.code === undefined && show">

      <div class="content">
        <div style="text-align: center; margin-top: 5%; color: #880000">
          No verification code found. Please check your email for your verification link and try again

        </div>
      </div>
    </div>
    <v-dialog  v-else
        v-model="dialog"
        hide-overlay
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Please wait while we verify your account ...
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AccountVerification",
  data:() => ({
    text: 'Please wait while we verify your account ...',
    color: '#42b983',
    dialog: false,
    show: false
  }),
  methods: {
    verify (){
      let self = this
      this.$store.dispatch("user/AccountVerification", { code: this.$route.query.code})
          .then(response =>{
            self.dialog = false
            //self.show = false


            setTimeout(function () {
              window.location.replace(process.env.VUE_APP_URL + "login")
            }, 2000)
          }).catch(err=>{
        self.show = true
        self.dialog = false
        this.text = 'Verification Failure'
        this.color = "#800"
      })
    }
  },
  mounted() {
    if(this.$route.query.code === null || this.$route.query.code === ""){

    }else{
      this.dialog = true
      this.verify()
    }
   // console.log(this.$route.query.code)
  }
}
</script>

<style scoped>
.content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>