<template>
  <div>
    <v-app>
      <v-container fluid>
        <v-row>
          <v-app-bar
              absolute
              color="white"
              elevate-on-scroll
              scroll-target="#scrolling-techniques-7"
          >
            <v-app-bar-nav-icon></v-app-bar-nav-icon>

            <v-toolbar-title>Aeorion Apply</v-toolbar-title>

            <v-spacer></v-spacer>



            <v-btn color="error" to="/logout">
              Logout
            </v-btn>
          </v-app-bar>
          <router-view />
        </v-row>

      </v-container>

    </v-app>
  </div>
</template>

<script>
export default {
  name: "currentApplication.layout.vue",
  mounted() {


    this.$store.dispatch('utilities/GetGenders')
    this.$store.dispatch('program/GetProgram')
    this.$store.dispatch("program/GetProgramDataForSearch")
  }
}
</script>

<style scoped>

</style>