<template>
  <v-container>
    <v-row >
      <v-col v-if="Object.keys(ProgramGet).length === 0" cols="12">
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-col>
      <v-col v-if="Object.keys(ProgramGet).length !== 0" cols="12">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <v-row>
              <v-col  cols="12"  xs="12" sm="12" md="12"  >
                <div>
                  <ValidationProvider name="Area Of Interest" rules="required" v-slot="{ errors }" >

                    <v-select
                        :error-messages="errors"
                        :items="getAreaOfInterest"
                        label="Area Of Interest"
                        hide-details="auto"
                        v-model="departmentId"
                        item-text="departmentName"
                        item-value="departmentId"
                        outlined
                        dense
                    ></v-select>

                  </ValidationProvider>
                </div>
              </v-col>

              <v-col  cols="12"  xs="12" sm="12" md="12"   >
                <div>
                  <ValidationProvider name="Degree Level" rules="required" v-slot="{ errors }" >

                    <v-select
                        :error-messages="errors"
                        :items="getDegreeLevels"
                        label="Degree Level"
                        hide-details="auto"
                        v-model="courseLevelId"
                        item-text="courseLevelName"
                        item-value="courseLevelId"
                    ></v-select>

                  </ValidationProvider>
                </div>
              </v-col>

              <v-col cols="12"  xs="12" sm="12" md="12"   >
                <div>
                  <ValidationProvider name="Programme Of Interest" rules="required" v-slot="{ errors }" >

                    <v-select
                        :error-messages="errors"
                        :items="getProgrammes"
                        label="Programme Of Interest"
                        hide-details="auto"
                        v-model="programId"
                        :item-text="item => item.degreeAbbreviation +' - '+ item.programName"
                        item-value="programId"
                        return-object
                    ></v-select>

                  </ValidationProvider>
                </div>
              </v-col>
              <v-col  cols="12"  xs="12" sm="12" md="12" >
                <div>
                  <ValidationProvider name="Campus" rules="required" v-slot="{ errors }" >

                    <v-select
                        :error-messages="errors"
                        :items="getCampuses"
                        label="Where do you want to study?"
                        hide-details="auto"
                        v-model="campusId"
                        item-text="campusName"
                        item-value="campusId"
                    ></v-select>

                  </ValidationProvider>
                </div>
              </v-col>
              <v-col cols="12"  xs="12" sm="12" md="12"   >
                <div>
                  <ValidationProvider name="Open Terms" rules="required" v-slot="{ errors }" >

                    <v-select
                        :error-messages="errors"
                        :items="getTerms"
                        label="Open Terms"
                        hide-details="auto"
                        v-model="termId"
                        item-text="termEntry"
                        item-value="termId"
                    ></v-select>

                  </ValidationProvider>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end mt-4" >


                <v-btn
                    color="primary"
                    loading :loading="loading" :disabled="loading"
                    type="submit"> Save & Next</v-btn>
              </v-col>
            </v-row>
          </form>
        </ValidationObserver>
      </v-col>
    </v-row>
    <snackbar-notifier  :color="color" :text="text" />
  </v-container>

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SnackbarNotifier from "@/components/SnackbarNotifier";
import {formSubmitter, updateProcessStep} from "@/services/userService";
export default {
  name: "ProgramView",
  components: {SnackbarNotifier},
  data: () => ({
    loading: false,
    campusId: '',
    courseLevelId: '',
    departmentId: '',
    programId: '',
    degreeId: '',
    termId: '',
    text: '',
    snackbar: false,
    color: ''
  }),
  watch:{
    ProgramGet: {
      handler: function (n, o) {
        //console.log(n)
        this.campusId = n.campusId
        this.courseLevelId = n.courseLevelId
        this.departmentId = n.departmentId
        //console.log(n.programId === "object")
        this.programId = n.programId === "object" ? n?.programId?.programId : n.programId
        this.termId = n.termId
      }, deep: true
    },


    departmentId: function (newArea, oldArea) {
      //console.log(newArea)
      this.$store.dispatch('program/SetDegreeLevel',{
        id: this.departmentId
      })
      if(newArea !== this.ProgramGet.departmentId) {
        this.courseLevelId = ''
        this.campusId = ''
        this.programId = ''
      }
    },
    courseLevelId: function (newDg, oldDg) {
      //alert(newDg)
      this.$store.dispatch('program/SetProgramOfInterest', {
        id: this.courseLevelId
      })
      //alert("change")
      if(newDg !== this.ProgramGet.courseLevelId) {
        this.programId = ''
        this.campusId = ''
        this.termId = ''
      }
    },

    programId: function(newVal, oldVal) {
      /*
      console.log(this.programId?.degreeId)
      console.log(this.programId?.programId)*/
      //console.log(this.programId)
      this.degreeId = this.programId?.degreeId
      //alert(newVal)
      this.$store.dispatch('program/setCampuses',{
        programId: (typeof this.programId === "object") ? this.programId?.programId : this.programId
      })
      //alert()
     /* if (this.programId && this.campusId ){
        this.$store.dispatch('program/GetOpenTerms', {
          campusId: this.campusId,
          programId: this.programId
        })
      }*/
      if(newVal !== this.ProgramGet.programId){
        this.campusId = ''
        this.termId = ''
      }

    },
    campusId: function (newCampus, oldCampus) {
      /*console.log(this.programId)
      console.log("------------------")*/
      if ((typeof this.programId === "object") ? this.programId?.programId : this.programId && this.campusId ){
        this.$store.dispatch('program/GetOpenTerms', {
          campusId: this.campusId,
          programId: (typeof this.programId === "object") ? this.programId?.programId : this.programId
        })

      }
      if(newCampus !== this.ProgramGet.campusId){
        this.termId = ''
      }
    },
  },
  computed: {
    programInfo: function () {

      return this.$store.state.user.program
    },
    ...mapState({
      getAreaOfInterest: state => state.program.programDataByAreaOfInterest,
      getCampuses: state => state.program.campus,
      getDegreeLevels: state => state.program.degreeLevel,
      getDepartments: state => state.program.departments,
      getProgrammes: state => state.program.programmes,
      getTerms: state => state.program.terms,
    }),
    ...mapGetters ({
      ProgramGet: 'user/programInfoGetter'
    })
  },
  methods: {
    onSubmit() {
      this.loading = true
      //alert(this.departmentId)
      /*console.log("This is the course level " + this.courseLevelId)
      console.log("This is the program id " + this.programId)*/
      formSubmitter('user/UpdateProgramInformation', {
        pin: this.$store.state.user.pin,
        campusId: this.campusId,
        degreeId: this.degreeId,
        departmentId: this.departmentId,
        termId: this.termId,
        programId: (typeof this.programId === "object") ? this.programId?.programId : this.programId

      }).then(async response => {
        //console.log(response)

        this.text = "Your Program Information was successfully updated "
        this.color = "success"
        this.snackbar = true
        this.$store.dispatch("user/GetProgramInformation")
        updateProcessStep((await this.$store.getters["user/getCurrentAppProcess"](this.$route)), true).then(response => {
          this.$store.dispatch('user/GetApplicantPercent')
          this.$store.dispatch('user/MoveToNextStep', {route: this.$route})
        })
        this.loading = false

        //this.$router.push("/PersonalInformation")
      }).catch(error=> {

        this.text = error
        this.color = "red"
        this.snackbar = true
        this.loading = false
      })

    },
  },
  mounted() {
    this.campusId = this.programInfo.campusId
    this.courseLevelId = this.programInfo.courseLevelId
    this.departmentId = this.programInfo.departmentId
   console.log(this.programInfo)
    this.programId = this.programInfo.programId
    this.termId = this.programInfo.termId
    this.$store.dispatch('user/GetApplicantPin').then(pin =>{
      this.$store.dispatch('user/SetPin', {pin: pin})
      let self = this
      self.$store.dispatch('user/GetApplicantPercent')
      self.$store.dispatch('user/GetApplicationCost')
      setTimeout(function (){
        //self.$store.dispatch('user/GetProgramInformation')
      }, 1000)
    }).catch(error=>{

    })
  }
}
</script>

<style scoped>

</style>
