<template>
    <v-row class="" style="min-height: 100vh; ">
      <v-col cols="12">
        <br>
        <img-container />
      </v-col>
<!--      <v-col cols="12" sm="12" >

        <help-video class="d-none d-md-block  video-container" source="https://www.youtube.com/embed/os5ebayI-oY" />
      </v-col>-->
      <v-col cols="12" sm="12"   style="margin-top: -30px" >
        <v-row  style="background: white; border-radius: 5px; ">
          <v-col cols="12">
            <h2 class="text-h4" style="color: #317eac">Create Account</h2><br>
            <v-alert dense type="success" text>Complete the form to below to start your application</v-alert>
<!--            <help-modal  source="https://www.youtube.com/embed/Kkah_gnjIpE" />-->
          </v-col>
          <v-col cols="12" style="margin-top: -15px">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12"   >
                    <div>
                      <ValidationProvider name="Program choice" rules="required" v-slot="{ errors }" >
                        <v-autocomplete
                            v-model="programFilter"
                            :items="getPrograms"
                            clearable
                            hide-details="auto"


                            item-text="programName"
                            :item-value="item => item"
                            label="Program Search"
                            placeholder="Type here to start searching for your programs"
                        >
                        </v-autocomplete>

                      </ValidationProvider>
                    </div>
                  </v-col>
                  <v-col cols="12" v-if="programFilter">
                    <v-row>
                      <v-col cols="12" >
                        <p style="font-size: small" class="mb-2">Choose where you want to study</p>
                        <v-chip v-for="(item, i) in campuses" :key="item.campusId"
                                class="mr-2 mt-1"
                                :dark="currentCampus === item.campusId "
                                :id="'campus' + item.campusId"
                                @click="clickAssign(item.campusId)"
                                :color="currentCampus === item.campusId ? 'green' : '' "
                        >
                          <v-icon size="16">mdi-map-marker</v-icon>{{ item.campusName }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-if="programFilter">
                    <v-row>
                      <v-col cols="12" >
                        <p style="font-size: small" class="mb-2">When do you want to start?</p>
                        <v-chip v-for="(item, i) in getTerms" :key="item.termId"
                                class="mr-2 mt-1"
                                :dark="currentTerm === item.termId"
                                :id="'term' + item.termId"
                                @click="clickTermAssign(item.termId)"
                                :color="currentTerm === item.termId ? 'green' : '' "
                        >
                          <v-icon size="16" style="margin-right: 5px">mdi-account-school </v-icon> {{' ' +  item.termEntry }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="6"   >
                    <div>
                      <ValidationProvider name="First name" rules="required" v-slot="{ errors }" >
                        <v-text-field
                            label="First Name"
                            :error-messages="errors"
                            hide-details="auto"
                            v-model="firstName"
                        ></v-text-field>

                      </ValidationProvider>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6"   >
                    <div>
                      <ValidationProvider name="Last name" rules="required" v-slot="{ errors }" >
                        <v-text-field
                            label="Last Name"
                            :error-messages="errors"
                            hide-details="auto"
                            v-model="lastName"
                        ></v-text-field>

                      </ValidationProvider>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12"   md="6" sm="12"  >
                    <div>
                      <ValidationProvider name="Email Address" :rules="{ required: true, regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }" v-slot="{ errors }" >
                        <v-text-field
                            label="Email Address"
                            :error-messages="errors"
                            hide-details="auto"
                            v-model="email"
                        ></v-text-field>

                      </ValidationProvider>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12"   md="6" sm="12"  >
                    <div>
                      <ValidationProvider name="Phone Number" rules="required" v-slot="{ errors }" >
                        <v-text-field
                            label="Phone Number"
                            :error-messages="errors"

                            hide-details="auto"

                            v-model="phoneNumber"
                        ></v-text-field>

                      </ValidationProvider>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12"   md="6" sm="12"  >
                    <div>
                      <ValidationProvider name="Gender" rules="required" v-slot="{ errors }" >

                        <v-select
                            :error-messages="errors"
                            :items="getGenders"
                            label="Gender"
                            hide-details="auto"
                            v-model="gender"
                            item-text="name"
                            item-value="id"
                        ></v-select>

                      </ValidationProvider>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12"   md="6" sm="12"  >
                    <div>
                      <ValidationProvider name="Password" rules="required" v-slot="{ errors }" >
                        <v-text-field
                            label="Password"
                            :error-messages="errors"
                            type="password"
                            hide-details="auto"
                            v-model="password"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        ></v-text-field>
                      </ValidationProvider>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12"  class="d-flex justify-end mt-4" >
                    <v-btn
                        to="/login"
                        class="mr-3"
                        color="text"
                    >Take me to Login</v-btn>
                    <v-btn

                        color="primary"

                        loading :loading="loading" :disabled="loading"
                        type="submit">Create Account</v-btn>
                  </v-col>
                </v-row>
              </form>
            </ValidationObserver>
            <v-snackbar
                v-model="snackbar" multi-line
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
            <v-dialog
                v-model="modalTrigger"
                persistent
                max-width="600"
            >

              <v-card>
                <v-card-title class="text-h5">
                  Account Created Successfully
                </v-card-title>
                <v-card-text>

                  Please visit your email to activate your account. Upon successful activation you will be redirected to login
                  <br><br>
                  <v-alert
                      text
                      color="info"
                  >
                    <span >If the activation email is not found in your inbox, please check your spam folder or wait a few minutes and check for it again.</span>
                  </v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                      color="green darken-1"
                      text
                      @click="modalTrigger = false"
                      to="/login"
                  >
                    Ok, Take me to Login
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>


        </v-row>
      </v-col>
      <v-col cols="12" sm="3" md="2" lg="3" ></v-col>
      <v-snackbar
          v-model="snackbar" multi-line
      >
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog
          v-model="modalTrigger"
          persistent
          max-width="600"
      >

        <v-card>
          <v-card-title class="text-h5">
            Account Created Successfully
          </v-card-title>
          <v-card-text>
            Please visit your email to activate your account. Upon successful activation you will be redirected to login.
            <br><br>

            <span style="font-size: small">If the activation email is not found in your inbox, please check your spam folder or wait a few minutes and check for it again.</span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="green darken-1"
                text
                @click="modalTrigger = false"
                to="/login"
            >
              Ok, Take me to Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</template>

<script>
import {mapState} from "vuex";
import {formSubmitter} from "@/services/userService";
import HelpVideo from "@/components/helpVideo";
import HelpModal from "@/components/helpModal";
import ImgContainer from "@/components/imgContainer";

export default {
  name: "Basic",
  components: {ImgContainer, HelpModal, HelpVideo},
  data: () => ({
    loading: false,
    show1: false,
    programFilter: '',
    campuses: [],
    currentCampus: 1,
    currentTerm: 0,
    termId: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    gender: '',
    password: '',
    programId: null,
    degreeId: null,
    snackbar: false,
    text: '',
    modalTrigger: false

  }),
  watch:{
    getPrograms: {
      async handler(n, o) {
        if (this.$route.query.programId) {
          let b = await n.filter(x => {
            if (x.programId === parseInt(this.$route.query.programId)) {
              return x
            }
          })

          if(b.length ===1){
            this.programFilter = b[0]
          }
        }
      }
    },
    programFilter: {
      deep: true,
      handler(n, o){
        if (this.programFilter !== null){
          this.campuses = n.campuses
          this.degreeId = n.degreeId
          this.programId = n.programId
          //this.termId = n.termId


          console.log(n)
        }
        else{
          this.programFilter = {}
        }
      }
    },
    getTerms: {
      deep: true,
      handler(n, o){
        console.log(n)
        n.length > 0 ? this.currentTerm  = n[0].termId : console.log()
      }
    },
    currentCampus: {
      deep: true,
      handler(n, o){
        this.$store.dispatch('program/GetOpenTerms', {
          campusId: this.currentCampus,
          programId: this.programId
        })
      }
    },
    currentTerm: {
      deep: true,
      handler(n, o){

      }
    },
    programId: {
      deep: true,
      handler(n, o){
        this.$store.dispatch('program/GetOpenTerms', {
          campusId: this.currentCampus,
          programId: this.programId
        })
      }
    },


  },
  computed: {
    ...mapState({
      getPrograms: state => state.program.searchPrograms,
      getGenders:  state => state.utilities.gender,
      getTerms: state => state.program.terms
    })
  },
  methods: {
    onSubmit(){
      this.loading = true
      this.$store.dispatch('user/CreateAccountFromSearch', {
        emailAddress: this.email,
        Phone: this.phoneNumber,
        password: this.password,
        firstName: this.firstName,
        middleName: "",
        lastName: this.lastName,
        genderId: this.gender,
        campusId: this.currentCampus,
        degreeId: this.degreeId,
        programId: this.programId,
        termId: this.currentTerm
      }).then(response => {
        this.loading = false
        this.modalTrigger = true
        /*this.text = error.info
        this.snackbar = true*/
      }).catch(error => {
        this.loading = false
        //console.log(error)
        this.text = error.info
        this.snackbar = true
      })
    },
    clickAssign(id){
      this.currentCampus = id
     // console.log(id)
    },
    clickTermAssign(id){
      this.currentTerm = id
     // console.log(id)
    }

  },
  mounted() {
    //console.log(this.$route.params.choice)

  }
}
</script>

<style scoped>
  .bg_cover{
    background-image: url('~@/assets/bg4.jpg');
    min-height: 100vh;
  }
  .changeColor{

  }
  img{
    max-width: 400px;
    max-height: 400px;
    height: auto;
    width: auto;
  }


  @media screen and (min-width:320px) {
    .cont {
      margin-top: 3vh;
    }
    .long_img{
      max-width: 150px;
      max-height: 150px;
      width: auto;
      height: auto;
    }
    .heading_for_welcome{
      color: #317eac;
      font-size: 18px;
    }
  }
  @media screen and (min-width:600px){
    .heading_for_welcome{
      color: #317eac;
      font-size: 20px;
    }
    .cont{
      margin-top: 3vh;
    }
    img{
      max-width: 500px;
      max-height: 500px;
      height: auto;
      width: auto;
    }
  }
  @media screen and (min-width:1000px){
    img{
      max-width: 600px;
      max-height: 600px;
      height: auto;
      width: auto;
    }
    .heading_for_welcome{
      color: #317eac;
      font-size: 24px;
    }
    .cont{
      margin-top: 3vh;
    }
  }
</style>
