<template>
  <v-row >
    <v-col cols="12">
      <br>
      <v-alert type="info" text >
        If you see a download icon <v-icon color="#555">mdi-download</v-icon> in the links below it means you can click to download the attachment to be filled out.
      </v-alert>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in getDocumentListing"
                           :key="item.documentId">
          <v-expansion-panel-header style="background: #f5f5f5">
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col cols="4" >
                  <span >{{ item.documentName }} <v-icon v-if="item.downloadable">mdi-download</v-icon></span>
                </v-col>
                <v-col
                    cols="8"
                    class="text--secondary"
                >
                  <v-fade-transition leave-absolute>
                <span
                    v-if="open"
                    key="0"
                    class="blue--text"
                >
                  Upload {{ item.documentName }}
                </span>

                  </v-fade-transition>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <br>
                <v-alert v-if="item.description !== ''" dense outlined color="info" style="font-size: 14px"
                >
                  {{ item.description}}
                </v-alert>
                <v-btn outlined
                       color="primary"
                       v-if="item.downloadable" style="text-transform: unset !important;" text :href="'data:application/octet-stream;base64,' + item.file" :download="item.documentName + item.extension">
                  <v-icon
                      v-if="item.extension === '.pdf' && item.downloadable"
                      color="warning"
                      size="24"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                  <v-icon v-else-if="item.extension === '.jpg' && item.downloadable"
                          color="primary"
                          size="24">
                    mdi-image
                  </v-icon>
                  <v-icon v-else-if="item.extension !== '.jpg' && item.extension !== '.pdf' && item.downloadable"
                          color="secondary"
                          size="24">
                    mdi-file
                  </v-icon>
                  <span>{{ item.documentName }} </span><span><v-icon>mdi-download</v-icon></span>
                </v-btn>

              </v-col>
            </v-row>
            <ValidationObserver v-slot="{ handleSubmit }" :ref="'observer' + i">
              <form @submit.prevent="handleSubmit(onSubmit(item.documentID, i))">
                <v-row>

                  <v-col  md="12" sm="12"  >
                    <div>
                      <ValidationProvider name="Document" rules="required" v-slot="{ errors }" >
                        <v-file-input
                            :accept="fileCheck"
                            show-size
                            :label="'Click to upload ' + item.documentName"
                            :error-messages="errors"
                            hide-details="auto"
                            v-model="uploadHolder[i]" outlined
                        ></v-file-input>

                      </ValidationProvider>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex justify-end " >
                    <v-btn
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        class="ma-2 white--text"
                        @click="loader = 'loading3'" type="submit"
                    >
                      Upload
                      <v-icon
                          right
                          dark
                      >
                        mdi-cloud-upload
                      </v-icon>
                    </v-btn>
                    <!--              <v-btn
                                      color="primary"

                                      type="submit"> Save & Next</v-btn>-->
                  </v-col>
                </v-row>
              </form>
            </ValidationObserver>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col class="d-flex justify-end " >
          <v-btn
              color="primary"
              :loading="loading1"
              :disabled="loading1"
              @click="updateRequiredDocumentStep"> Mark Complete</v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col cols="12">
      <h3>Uploaded Documents</h3>

      <v-list v-for="item in getDocuments" :key="item.id"
      two-line
      subheader dense
      >

      <v-list-item>
        <v-list-item-icon >

          <v-icon
              v-if="item.extension === '.pdf'"
               color="warning"
              size="28"
          >
            mdi-file-pdf-box
          </v-icon>
          <v-icon v-else-if="item.extension === '.jpg'"
                   color="primary"
                  size="28">
            mdi-image
          </v-icon>
          <v-icon v-else
                   color="secondary"
                  size="">
            mdi-file
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title  style="font-size: medium">{{ item.fileName}}</v-list-item-title>
          <v-list-item-subtitle style="color: rgb(33,150,243, 0.8)"> {{ item.documentName}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn-toggle tile >
          <v-btn icon
                 color="primary"
                 text :href="'data:application/octet-stream;base64,' + item.file" :download="item.documentName + item.extension"
          >
            <v-icon color="primary">mdi-download</v-icon>
          </v-btn>
          <v-btn
              color="red lighten-1" icon
              text @click="deleteFile(item.docStatusId)"
          >
            <v-icon size="20" color="red darken-3">mdi-delete</v-icon>
          </v-btn>
          </v-btn-toggle>
        </v-list-item-action>
      </v-list-item>
      </v-list>


      <snackbar-notifier  :color="color" :text="text" />

    </v-col>
  </v-row>
</template>

<script>
import {mapState} from "vuex";
import SnackbarNotifier from "@/components/SnackbarNotifier";
import {updateProcessStep} from "@/services/userService";
export default {
  name: "RequiredDocuments",
  components: {SnackbarNotifier},
  data:() => ({
    date: null,
    fileCheck: '',
    trip: {
      name: '',
      location: null,
      start: null,
      end: null,
    },
    locations: ['Australia', 'Barbados', 'Chile', 'Denmark', 'Ecuador', 'France'],
    uploadHolder: [],
    show: false,
    picked: null,
    loader: null,
    selectedFile:  [],
    hidden: true,
    loading: false,
    loading1: false,
    snackbar: false,
    text: '',
    color: '',
    currentFileType: null,
    acceptedType: "application/pdf"
  }),
  methods: {
    updateRequiredDocumentStep(){
      this.loading1 = true
      updateProcessStep(7, true).then((response) => {
        this.$store.dispatch('user/GetApplicantPercent')
        this.$store.dispatch('user/MoveToNextStep', {route: this.$route})
        //this.$router.push('/Declaration&Agreement')
      }).catch(error => {
        //console.log(error)
        this.loading1 = false
        this.text = error.info
        this.color = "red accent-2"
        this.snackbar = true
      })

    },
    onFileSelected(event){
      this.selectedFile = event.target.files[0]
      //console.log(this.selectedFile)
    },

    deleteFile(x){
      this.$store.dispatch('user/RemoveApplicantDocument', {
        docStatusId: x,
        documentId: 0
      })
          .then(response =>{
            this.text = "The file was deleted"
            this.color = "success"
            this.snackbar = true
            this.active = false
            this.$store.dispatch('user/GetApplicantDocuments')
          }).catch(error=> {
            this.text = "Something went wrong, please try again"
            this.color = "red"
            this.snackbar = true
            console.log(error)

      })
    },
    onSubmit(x, y){
      const formData = new FormData();
      formData.append("File", this.uploadHolder[y])

      formData.append("DocumentId", x)
      formData.append("documentName", this.uploadHolder[y].name)
      //formData.append("extension", "string")
      //formData.append("fileId", 0)
      this.loading = true
      let self = this
      this.$store.dispatch('user/AddDocumentsByType', formData)
          .then(response =>{
            this.loading = false
            this.text = "Upload was successfully added"
            this.color = "success"
            this.snackbar = true
            //this.uploadHolder = []

            this.$store.dispatch('user/GetApplicantDocuments');
            `'this.$refs.observer. + ${y} + '.reset()'`
          }).catch(error=> {
           // console.log(error)
            this.loading = false
            this.text = error
            this.color = "red accent-2"
            this.snackbar = true

      })
    }
  },
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 2000)

      this.loader = null
    },
    picked: function (n, o) {
      //console.log(n)
      this.currentFileType = this.getDocumentListing.filter(item => item.documentID === n)
      //console.log(this.currentFileType[0])
    },
    getDocType:{
      handler(n, o) {
        //console.log(n)
        for(let i = 0; i < n.length; i++){
          //console.log(n[i])
          this.fileCheck = this.fileCheck.concat(" " + n[i] + ',')
        }
      },
      deep: true
    }

  },
  computed: {
    getDocType: function (){
      return this.$store.state.utilities.fileTypes
    },
    ...mapState({
     // getDocType: state => state => state.utilities.fileTypes,
      getDocumentListing: state => state.user.documentListing,
      getDocuments: state => state.user.documentList
    })
  },
  mounted() {
    this.$store.dispatch('user/GetApplicantPercent')
  }
}
</script>

<style scoped>

</style>
