export default {
    termsByYearNames: (state) => {
        return state.termsByYear.map(term => {
            //console.log(term)
            return term.category !== null ? term.category: ""
        })

    },
    termsByYearValues: (state) => {
        return state.termsByYear.map(term => {
            //console.log(term)
            return term.amount
        })
    },
    genderByYearNames: (state) => {
        return state.genderByYear.map(term => {
            //console.log(term)
            return term.category !== null ? term.category: ""
        })

    },
    genderByYearValues: (state) => {
        return state.genderByYear.map(term => {
            //console.log(term)
            return term.amount
        })
    },

}