import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import InfiniteLoading from 'vue-infinite-loading';

Vue.use(VueMeta)
Vue.use(InfiniteLoading, { /* options */ });
Vue.use(mavonEditor)

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';

import authLayout from '@/layouts/auth.layout'
import guestLayout from '@/layouts/guest.layout'
import emptyLayout from '@/layouts/LogoutLayout'
import createLayout from '@/layouts/create.layout'
import currentLayout from '@/layouts/currentApplication.layout'
import adminLayout from '@/layouts/admin.layout'


Vue.component('default-layout', authLayout)
Vue.component('login-layout', guestLayout)
Vue.component('empty-layout', emptyLayout)
Vue.component('create-layout', createLayout)
Vue.component('current-layout', currentLayout)
Vue.component('admin-layout', adminLayout)
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import * as rules from 'vee-validate/dist/rules';
import mavonEditor from "mavon-editor";
// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

export const bus = new Vue();


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
