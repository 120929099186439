import Vue from 'vue'
import Vuex from 'vuex'
import stateUtilities from './utilities/utilities.state'
import mutationsUtilities from './utilities/utilities.mutation'
import actionUtilities from './utilities/utilities.actions'
import stateProgram from './program/program.state'
import mutationsProgram from './program/program.mutation'
import actionProgram from './program/program.actions'
import gettersProgram from './program/program.getter'
import userState from "./user/user.state";
import userMutations from "./user/user.mutations";
import userActions from "./user/user.actions";
import userGetter from "@/store/user/user.getter";
import utilitiesGetter from "@/store/utilities/utilities.getter";
import staffState from "@/store/staff/staff.state"
import staffMutations from "@/store/staff/staff.mutation"
import staffActions from "@/store/staff/staff.actions"
import staffGetter from "@/store/staff/staff.getter";


Vue.use(Vuex)


const user = {
  namespaced: true,
  state: userState,
  mutations: userMutations,
  actions: userActions,
  getters: userGetter

}
const staff = {
  namespaced: true,
  state: staffState,
  mutations: staffMutations,
  actions: staffActions,
  getters: staffGetter

}

const program = {
  namespaced: true,
  state: stateProgram,
  mutations: mutationsProgram,
  actions: actionProgram,
  getters: gettersProgram
}

const utilities = {
  namespaced: true,
  state: stateUtilities,
  mutations: mutationsUtilities,
  actions: actionUtilities,
  getters: utilitiesGetter
}

const app = {
  namespaced: true,
  state: {},
  mutations: {  },
  actions: {  }
}

export default new Vuex.Store({

  state: {
    api: process.env.VUE_APP_API_ADDRESS,
    photoUrl: process.env.VUE_APP_PHOTO_URL,
    resetLink: process.env.VUE_APP_URL + 'recover_password?code='
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    utilities,
    program,
    user,
    staff
  }
})
