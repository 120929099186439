export default {
    programData: [],
    programDataByAreaOfInterest: [],
    searchPrograms: [],
    campus: [],
    degreeLevel: [],
    departments: [],
    programmes: [],
    terms: [],
    logs: [],
    adminAgents: [],
    log: {},
    programInfo: {},


}
