<script>




export default {
  name: "PersonOfInterest",
  data () {
    return {
      studentInfo: {},
      tab: null,
      items: [
        'General', 'Academic', 'Contact'
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }
  },
  computed: {
    getCurrentPerson(){
      return this.$store.state.program.log;
    }
  },
  watch: {
    '$store.state.program.log': function(n, o) {
      this.studentInfo = this.$store.state.program.log
      console.log(this.$store.state.program.log)
    }
  },
  mounted() {
    this.$store.dispatch("program/GetLog", {pin: this.$route.params.id})
  }

}
</script>

<template>
  <div style="background-color: #F5F5F5; min-height: 93vh">
    <v-row>
      <v-col cols="12" sm="3"></v-col>
      <v-col cols="12" sm="6">
        <br><br>
        <h2>Person of Interest - {{ this.getCurrentPerson.fullName }}</h2>
        <div class="pa-3">
          Pin: {{ this.getCurrentPerson.pin }} <br>
          Assign to: {{ this.getCurrentPerson.assignedTo }} <br>
          Interest Level: {{ this.getCurrentPerson.interestType }} <br>
        </div>
        <br>
        <div>
          <v-card flat class="pa-3">
            <h3>General</h3>
            <hr>
            <div class="d-flex justify-space-between">
              <div>Gender</div>
              <div>{{ this.getCurrentPerson.gender }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Denomination</div>
              <div>{{ this.getCurrentPerson.denomination }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Religion</div>
              <div>{{ this.getCurrentPerson.religion }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>TRN</div>
              <div>{{ this.getCurrentPerson.trn }}</div>
            </div>

          </v-card>
        </div>
        <br>
        <div>
          <v-card flat class="pa-3">
            <h3>Academic</h3>
            <hr>

            <div class="d-flex justify-space-between">
              <div>Student Id</div>
              <div>{{ this.getCurrentPerson.studentId }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Programme</div>
              <div>{{ this.getCurrentPerson.programme }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>College</div>
              <div>{{ this.getCurrentPerson.college }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Campus</div>
              <div>{{ this.getCurrentPerson.campus }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>College</div>
              <div>{{ this.getCurrentPerson.college }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Degree</div>
              <div>{{ this.getCurrentPerson.Degree }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Letter Sent</div>
              <div>{{ this.getCurrentPerson.letterSent }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Application Fee</div>
              <div>{{ this.getCurrentPerson.applicationFee }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Application Status</div>
              <div>{{ this.getCurrentPerson.applicationStatus }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Date Accepted</div>
              <div>{{ this.getCurrentPerson.dateAccepted }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Pre-Acceptance Matriculation</div>
              <div>{{ this.getCurrentPerson.matriculationPreAcceptance }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>External Passees Access Granted</div>
              <div>{{ this.getCurrentPerson.externalPasseesAccessGranted }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Interview Required</div>
              <div>{{ this.getCurrentPerson.interviewRequired }}</div>
            </div>
            <br>
            <div>
              <i>Documents</i>
            </div>
            <div class="d-flex align-content-start flex-wrap">
              <div class="px-2 " v-for="item in this.getCurrentPerson?.documents">
                <v-chip
                    class="ma-2"

                    label
                     outlined
                >
                  <v-icon left>
                    {{ item.checked ? "mdi-check" : "mdi-close" }}
                  </v-icon>
                  {{ item.name }}
                </v-chip>

<!--                <v-icon class="" color="success" v-if="item.checked">mdi-check</v-icon>
                <v-icon class="" color="danger" v-else>mdi-close</v-icon>-->
              </div>
            </div>
            <br>
            <div>
              <i>Declarations</i>
            </div>
            <div class="d-flex align-content-start flex-wrap">
              <div class="px-2 " v-for="item in this.getCurrentPerson?.declarations">
                <v-chip
                    class="ma-2"
                    :color="!item.checked ? 'pink' : 'green'"
                    label
                     outlined
                >
                  <v-icon left>
                    {{ item.checked ? "mdi-check" : "mdi-close" }}
                  </v-icon>
                  {{ item.name }}
                </v-chip>

<!--                <v-icon class="" color="success" v-if="item.checked">mdi-check</v-icon>
                <v-icon class="" color="danger" v-else>mdi-close</v-icon>-->
              </div>
            </div>

          </v-card>
        </div>
        <br>
        <div>
          <v-card flat class="pa-3">
            <h3>Contact</h3>
            <hr>

            <div class="d-flex justify-space-between">
              <div>Email Address</div>
              <div>{{ this.getCurrentPerson.email }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Telephone</div>
              <div>{{ this.getCurrentPerson.telephone }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Parish</div>
              <div>{{ this.getCurrentPerson.parish }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Country</div>
              <div>{{ this.getCurrentPerson.country }}</div>
            </div>
          </v-card>
        </div>



<!--        {{ this.getCurrentPerson }}-->
      </v-col>
      <v-col cols="12" sm="3"></v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>
