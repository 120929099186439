
<script>

import axios from 'axios';
import {mapState} from "vuex";
import InfiniteLoading from 'vue-infinite-loading';

window.onscroll = function(ev) {
  if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
    // you're at the bottom of the page
    console.log("Bottom of page");
  }
};

export default {
  name: 'AdminDashboardTable',
  components:{
    InfiniteLoading,
  },
  data () {
    return {
      loading: false,
      pin: null,
      assignTo: null,
      applicationStatus: null,
      idNumber: null,
      page: 1,
      list: [],
      reveal: false,
      dynamicModels: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'fullName',
        },
        { text: 'PIN', value: 'pin' },
        { text: 'Gender', value: 'gender' },
        { text: 'Phone', value: 'telephone' },
        { text: 'Assignee', value: 'assignedTo' },
        { text: 'Interest Type', value: 'interestType' },
        { text: '', value: 'actions' },
      ],
    }
  },
  computed: {
    ...mapState({
      logs: state => state.staff.logs,
      statuses: state => state.staff.appStatuses,
      /*pinNumber: state => state.staff.pin,
      assignTo: state => state.staff.assignTo,*/
      getAdminAgents: state => state.user.adminAgents,
    })
  },
  methods: {
    onSubmit() {
      this.$refs.observer.validate()
      this.loading = true
      this.$store.state.staff.logs = []
      this.$store.dispatch('staff/GetLogs', {
        pin: this.$store.state.staff.pin,
        assignTo: this.$store.state.staff.assignTo,
        applicationStatus: this.applicationStatus,
        idNumber: this.idNumber
      }).then(response => {
        this.loading = false
        //this.modalTrigger = true
      }).catch(error => {
        this.loading = false
        this.text = error
        this.color = "red"
        this.snackbar = true
      })
    },
    assignToAgent(interestId, agentId, currentIndex){
      this.$store.dispatch("staff/AssignAgentToInterest", { interestId: interestId, agentId: agentId})
          .then(response => {
            console.log(this.dynamicModels[currentIndex])
            this.logs[currentIndex].assignedTo = agentId
          })
    },
    infiniteHandler($state) {
      //alert()

      console.log($state)
      axios.get(`${process.env.VUE_APP_API_ADDRESS}Staff/GetLogSummary?pageSize=10&pageNumber=${this.page}`, {

        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("token")
        }
      }).then((response) => {
        console.log(response.data)
        if (response.data.length) {
          this.page += 1;
          this.$store.commit('staff/setLogs', response.data)
          this.list.push(...response.data);
          $state.loaded();
        } else {
          $state.complete();
        }
      });

    },
  },
  watch:{
    pin: function (n, o){
      this.$store.commit("staff/setPin", n)
    },
    assignTo: function (n, o) {
      this.$store.commit("staff/setAgentId", n)
    },
    applicationStatus: function (n, o) {
      this.$store.commit("staff/setAppStatuses", n)
    },
    idNumber: function (n, o) {
      this.$store.commit("staff/setIdNumber", n)
    },

  },
  mounted() {
    this.idNumber = this.$store.state.staff.idnumber
    this.pin = this.$store.state.staff.pin
    this.assignTo = this.$store.state.staff.assignTo
    //this.applicationStatus = this.$store.state.staff.statuses
   /* this.$store.dispatch('staff/GetLogs', {
      pin: this.$store.state.staff.pin,
      assignTo: this.$store.state.staff.assignTo
    })*/
    //this.$store.dispatch("program/GetLogs", { page: this.page })
    //this.infiniteHandler()
  }
}
</script>


<template>
  <div >
    <ValidationObserver ref="observer" v-slot="{ invalid, validated, handleSubmit, validate }">
      <v-form @submit.prevent="handleSubmit(onSubmit)">
        <v-row>
          <v-col cols="12" sm="3">
            <div>
              <ValidationProvider name="pin" rules="" v-slot="{ errors }">
                <v-text-field solo
                              label="Pin"
                              :error-messages="errors"
                              v-model="pin"
                ></v-text-field>

              </ValidationProvider>
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <div>
              <ValidationProvider name="idNumber" rules="" v-slot="{ errors }">
                <v-text-field solo
                              label="Id Number"
                              :error-messages="errors"
                              v-model="idNumber"
                ></v-text-field>

              </ValidationProvider>
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <div>
              <ValidationProvider name="assignTo" rules="" v-slot="{ errors }" >
                <v-autocomplete
                    label="Choose Agent Name"
                    :error-messages="errors"
                    solo
                    :items="getAdminAgents"
                    :item-text="value => { return value.firstName + ' ' + value.lastName}"
                    item-value="id"
                    clearable
                    v-model="assignTo"
                ></v-autocomplete>
              </ValidationProvider>
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <div>
              <ValidationProvider name="applicationStatus" rules="" v-slot="{ errors }" >
                <v-select
                    multiple
                    chips
                    label="Application Status"
                    :error-messages="errors"
                    solo
                    :items="statuses"

                    v-model="applicationStatus"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                            (+{{ applicationStatus.length - 1 }} others)
                          </span>
                  </template>
                </v-select>
              </ValidationProvider>
            </div>
          </v-col>
          <v-col cols="12" style="margin-top: -30px">
            <div >
              <v-btn
                  color="primary"
                  loading :loading="loading" :disabled="loading"
                  rounded
                  type="submit"
              >
                Submit
              </v-btn>

            </div>
          </v-col>


        </v-row>
      </v-form>
    </ValidationObserver>
    <br>
    <br>
    <div v-for="(item, index) in logs" :key="index" >

<!--      <div>{{ item }}</div>-->
<!--      <router-link :to="'/admin/person/' + item.pin" class="text-decoration-none">-->
        <v-card class="mx-auto my-2"
        >
          <v-card-text>

            <div><span style="color: green">{{ item.interestType }}</span> | {{ item.pin}} | {{ item.gender }} | {{ item.email }}</div>
            <v-divider class="px-3"></v-divider>
            <div class="d-flex">
              <div class="pr-2" style="border-right: 1px solid #ccc">
                <div style="color: #aaa; font-size: 12px">Name</div>
                <div  style="font-size: 18px; color: black">{{ item.fullName }}</div>
              </div>
              <div class="px-2" style="border-right: 1px solid #ccc">
                <div style="color: #aaa; font-size: 12px">Programme</div>
                <div  style="font-size: 18px; color: black">{{ item.programme }}</div>
              </div>
              <div class="px-2" style="border-right: 1px solid #ccc">
                <div style="color: #aaa; font-size: 12px">Assign To</div>
                <div  style="font-size: 18px; color: black">{{ item.assignedTo }}</div>
              </div>
              <div class="px-2" style="border-right: 1px solid #ccc">
                <div style="color: #aaa; font-size: 12px">Status</div>
                <div  style="font-size: 18px; color: black">{{ item.applicationStatus }}</div>
              </div>


            </div>

            <!--          <p>adjective</p>
                      <div class="text&#45;&#45;primary">
                        relating to or dependent on charity; charitable.<br>
                        "an eleemosynary educational institution."
                      </div>-->
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>

            <v-btn :to="'/admin/prospects/' + item.pin" outlined small class="mr-3">
              View
            </v-btn>
            <v-menu
                class=""
                :close-on-content-click="false"
                :nudge-width="200"

            >
              <template v-slot:activator="{ on, attrs }">
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    dark small
                    v-bind="attrs"
                    v-on="on"
                >
                  Assign to Agent
                </v-btn>
              </template>

              <v-card>
                <v-list>
                  <v-list-item>

<!--                    <v-select
                        :items="agents"
                        label="Choose Agent"
                        solo
                        :item-text="item => item.firstName + ' ' + item.lastName"
                        :item-value="item => item.id"
                    ></v-select>-->
                    <v-autocomplete
                        v-model="dynamicModels[index]"
                        @change="assignToAgent(item.pin, dynamicModels[index], index)"
                        :items="getAdminAgents"
                        auto-select-first
                        dense solo
                        label="Choose Agent"
                        :item-text="item => item.firstName + ' ' + item.lastName"
                        :item-value="item => item.id"
                    ></v-autocomplete>

                  </v-list-item>
                </v-list>
                <v-card-actions style="margin-top: -15px;">
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text

                  >
                    Assign
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-card-actions>
        </v-card>
<!--      </router-link>-->

    </div>
    <infinite-loading @infinite="infiniteHandler($event)"></infinite-loading>
  </div>
</template>

<style scoped>

</style>
