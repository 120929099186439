<template>
  <v-list color="transparent" dense>
<!--    <v-subheader>REPORTS</v-subheader>-->
    <v-list-item-group
        color="primary"
    >
      <v-list-item
          link
          color="primary lighten-1" to="/"
      >
        <v-list-item-icon >
          <v-icon size="16" color="primary">mdi-human-greeting</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="">
            Welcome
          </v-list-item-title>
        </v-list-item-content>

      </v-list-item>
      <template v-for="(n, index) in getLinks" >

        <v-list-item
            :key="n.applicationProcessId"
            link dense
            :to='n.processName.split(" ").join("")'
            :disabled="n.applicationProcessId === 9 && appFeeStatus"

        >
          <v-list-item-icon>
              <v-icon v-if="n.isComplete" color="green lighten-1">mdi-check</v-icon>
              <v-icon v-else color="red lighten-1">mdi-close</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="n.processName"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider
            v-if="index < getLinks.length - 1"
            :key="index"
        ></v-divider>
      </template>
      <v-divider/>
    </v-list-item-group>
    <v-list-item
        link
        to="/OtherDocuments"

    >
      <v-list-item-icon >
        <v-icon size="18" color="#aaa">mdi-upload</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title >
          Other Documents
        </v-list-item-title>
      </v-list-item-content>

    </v-list-item>
    <v-list-item
        link
        color="primary lighten-1" @click="restart()"
        active-class="primary "
    >
      <v-list-item-icon >
        <v-icon size="16" color="primary">mdi-plus</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="color: #1976d2">
          New Application
        </v-list-item-title>
      </v-list-item-content>

    </v-list-item>
    <v-list-item
        link
        color="red lighten-1" to="/logout"
        active-class="red "
    >
      <v-list-item-icon >
        <v-icon size="16" color="red">mdi-logout</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title style="color: crimson">
          Logout
        </v-list-item-title>
      </v-list-item-content>

    </v-list-item>

    <v-divider class=""></v-divider>


  </v-list>

</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "sidebar",
  data(){
    return{
      appFeeStatus: false
    }
  },
  watch: {
    getLinks: {
      async handler(n, o) {
        const contactStatus = await n.filter(process => process.applicationProcessId === 2)
        const programStatus = n.filter(process => process.applicationProcessId === 3)
       // console.log(contactStatus[0].isComplete)
        this.appFeeStatus = contactStatus[0].isComplete === false || programStatus[0].isComplete === false
       // console.log(this.appFeeStatus)
      }
    },
    getCurrentIndex: {
      async handler(n, o) {
       // alert(n)
        let xy = this.getLinks.filter(x => {
          return x.stepOrder === (n + 1)
        })
        /*console.log("xy")
        console.log(xy)*/
        this.$router.push("/" + xy[0].processName.split(" ").join("")) //
      }, deep: true
    },
  },
  methods: {
    moveToNextStep: function(x){
      this.$store.dispatch("user/MoveToNextStep", {processId: x})
    },
    restart: function () {

      localStorage.removeItem("user_pin")

      this.$router.push("/current_application")
    }
  },
  computed: {
    ...mapState ({
      getLinks: state => state.user.appProcesses,
      getCurrentIndex: state => state.user.currentPageIndex,
    }),
    ...mapGetters ({
      currentIndexGetter: state => state.user["user/getCurrentAppProcess"](this.$route)
    })
  }
}
</script>

<style scoped>

</style>
