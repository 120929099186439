<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";

export default defineComponent({
  name: "AddProgramInformation",
  data: () => ({
    programFilter: "",
    ProgramDescription: '',
    TuitionCost: '',
    TuitionCurrency: '',
    Duration: '',
    DurationType: '',
    ProgramOverview: '',

  }),
  watch: {
    GetProgram: {
      async handler(n, o){
        this.ProgramOverview = n?.overview
        this.ProgramDescription = n?.description
        this.TuitionCost = n?.tuitionCost
        this.TuitionCurrency = n?.tuitionCurrency
        this.Duration = n?.duration
        this.DurationType = n?.durationType
      }
    }
  },
  computed: {
    ...mapState({
      GetProgram: state => state.program.programInfo
    })
  },
  props: {
    programId: {
      required: true
    }
  }
})
</script>

<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12"   >
            <div>
              <ValidationProvider name="ProgramDescription" rules="required" v-slot="{ errors }" >
                <v-textarea
                    label="Program Description"
                    :error-messages="errors"
                    solo
                    hide-details="auto"
                    auto-grow
                    v-model="ProgramDescription"
                ></v-textarea>
              </ValidationProvider>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6"   >
            <div>
              <ValidationProvider name="TuitionCost" rules="required" v-slot="{ errors }" >
                <v-text-field
                    label="Tuition Cost"
                    :error-messages="errors"
                    solo dense
                    hide-details="auto"
                    v-model="TuitionCost"
                ></v-text-field>
              </ValidationProvider>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6"   >
            <div>
              <ValidationProvider name="TuitionCurrency" rules="required" v-slot="{ errors }" >
                <v-text-field
                    label="Tuition Currency"
                    :error-messages="errors"
                    solo dense
                    hide-details="auto"
                    v-model="TuitionCurrency"
                ></v-text-field>
              </ValidationProvider>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6"   >
            <div>
              <ValidationProvider name="Duration" rules="required" v-slot="{ errors }" >
                <v-text-field
                    label="Duration"
                    :error-messages="errors"
                    solo dense
                    hide-details="auto"
                    v-model="Duration"
                ></v-text-field>
              </ValidationProvider>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6"   >
            <div>
              <ValidationProvider name="DurationType" rules="required" v-slot="{ errors }" >
                <v-text-field
                    label="Duration Type"
                    :error-messages="errors"
                    solo dense
                    hide-details="auto"
                    v-model="DurationType"
                ></v-text-field>
              </ValidationProvider>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12"   >
            <div>
              <ValidationProvider name="ProgramOverview" rules="required" v-slot="{ errors }" >
                <v-textarea
                    label="Program Overview"
                    :error-messages="errors"
                    solo
                    hide-details="auto"
                    auto-grow
                    v-model="ProgramOverview"
                ></v-textarea>
              </ValidationProvider>
            </div>
          </v-col>
        </v-row>
        <div class="pa-3">
          <div class="text-right">
            <v-btn color="primary">Update</v-btn>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<style scoped>

</style>
