<template>
  <v-row>
    <v-col cols="12" style="background: #f5f5f5">
      <ValidationObserver ref="observer" v-slot="{ invalid, validated, handleSubmit, validate }">
        <v-form @submit.prevent="handleSubmit(onSubmit)">
          <br>
          <div>
            <ValidationProvider name="Document Type" rules="required" v-slot="{ errors }" >
              <v-select
                  :error-messages="errors"
                  :items="getDocuments"
                  label="Choose document type to upload" dense
                  hide-details="auto"
                  v-model="documentType" outlined
                  item-text="documentName"
                  item-value="documentID"
              ></v-select>

            </ValidationProvider>
          </div>
          <br>
          <div v-if="description !== ''" >
            <v-alert text dense color="info"  style="font-size: small">{{ description }}</v-alert>
          </div>
          <div v-if="fileStatus">
            <v-btn  style="text-transform: unset !important;" text :href="'data:application/octet-stream;base64,' + file" :download="fileName + extension">
              <v-icon
                  v-if="extension === '.pdf' && fileStatus"
                  color="warning"
                  size="24"
              >
                mdi-file-pdf-box
              </v-icon>
              <v-icon v-else-if="extension === '.jpg' && fileStatus"
                      color="primary"
                      size="24">
                mdi-image
              </v-icon>
              <v-icon v-else-if="extension !== '.jpg' && extension !== '.pdf' && fileStatus"
                      color="secondary"
                      size="24">
                mdi-file
              </v-icon>
              <span>{{ fileName }} </span><span><v-icon>mdi-download</v-icon></span>
            </v-btn>
            <br> <br>
          </div>
          <div >
            <ValidationProvider name="File" rules="required" v-slot="{ errors }" >
              <v-file-input
                  accept="image/*, .pdf, .doc, .docx"
                  show-size
                  label="Select file you wish to upload"
                  :error-messages="errors"
                  hide-details="auto"
                  v-model="uploadHolder" outlined
              ></v-file-input>
            </ValidationProvider>
          </div>
          <div style="margin-top: -15px">

            <br>
          </div>

          <div style="text-align: right">

            <v-btn color="primary" loading :loading="loading" :disabled="loading" type="submit"
            >
              <v-icon>mdi-upload</v-icon>
              Upload
            </v-btn>

          </div>
        </v-form>
      </ValidationObserver>
    </v-col>
    <v-col cols="12">
      <v-col cols="12">
        <v-divider></v-divider>
        <br>
        <h3>Uploaded Documents</h3>

        <v-list v-for="item in getUploadedDocuments" :key="item.id"
                two-line
                subheader dense
        >

          <v-list-item>
            <v-list-item-icon >

              <v-icon
                  v-if="item.extension === '.pdf'"
                  color="warning"
                  size="28"
              >
                mdi-file-pdf-box
              </v-icon>
              <v-icon v-else-if="item.extension === '.jpg'"
                      color="primary"
                      size="28">
                mdi-image
              </v-icon>
              <v-icon v-else
                      color="secondary"
                      size="">
                mdi-file
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.fileName}}</v-list-item-title>
              <v-list-item-subtitle>{{ item.documentName}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn-toggle tile >
                <v-btn icon
                       color="primary"
                       text :href="'data:application/octet-stream;base64,' + item.file" :download="item.documentName + item.extension"
                >
                  <v-icon color="primary">mdi-download</v-icon>
                </v-btn>
                <v-btn
                    color="red lighten-1" icon
                    text @click="deleteFile(item.documentId)"
                >
                  <v-icon size="20" color="red darken-3">mdi-delete</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <snackbar-notifier  :color="color" :text="text" />

      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import {mapState} from "vuex";
import SnackbarNotifier from "@/components/SnackbarNotifier";

export default {
  name: "OtherDocuments",
  data: () => ({
    file: null,
    fileName: '',
    fileStatus: false,
    extension: null,
    documentType: null,
    documents: [],
    description: '',
    doc: {},
    loading: false,
    uploadHolder: null,
    selectedFile: [],
    currentFileType: null,
    snackbar: false,
    text: '',
    color: '',
  }),
  components: {
    SnackbarNotifier
  },
  computed: {

    ...mapState({
      getDocuments: state => state.user.otherDocuments,
      getUploadedDocuments: state => state.user.otherUploadedDocuments
    }),

  },
  watch:{
    getDocuments: {
      handler(n, o){
        //console.log(n)
        this.documents = n
      },
      deep: true
    },
    documentType: function (n, o) {
      const document = this.getDocuments.filter(doc => doc.documentID === n)
      //console.log(document[0])
      this.description = document[0].description
      this.fileStatus = document[0].downloadable
      this.file = document[0].file
      this.extension = document[0].extension
      this.fileName = document[0].documentName
    },
    picked: function (n, o) {
      //console.log(n)
      this.currentFileType = this.getDocumentListing.filter(item => item.documentID === n)
      //console.log(this.currentFileType[0])
    }
  },
  methods: {
    onSubmit(){
      const formData = new FormData();
      formData.append("File", this.uploadHolder)

      formData.append("DocumentId", this.documentType)
      formData.append("documentName", this.uploadHolder.name)
      //formData.append("extension", "string")
      //formData.append("fileId", 0)
      this.loading = true
      let self = this
      this.$store.dispatch('user/AddDocumentsByType', formData)
          .then(response =>{
            this.loading = false
            this.$store.dispatch('user/GetApplicantUploadedOtherDocuments')
            this.text = "Upload was successfully added"
            this.color = "success"
            this.snackbar = true
            //this.uploadHolder = []
            this.$nextTick(() => {
              this.$refs.observer.reset();
            });

          }).catch(error=> {
        //console.log(error)
        this.loading = false
        this.text = error
        this.color = "red accent-2"
        this.snackbar = true

      })
    },
    deleteFile(x){
      this.$store.dispatch('user/RemoveApplicantDocument', {
        documentId: x
      })
          .then(response =>{
            this.text = "The file was deleted"
            this.color = "success"
            this.snackbar = true
            this.active = false
            this.$store.dispatch('user/GetApplicantDocuments')
          }).catch(error=> {
        this.text = "Something went wrong, please try again"
        this.color = "red"
        this.snackbar = true

      })
    },
    onFileSelected(event){
      this.selectedFile = event.target.files[0]
      //console.log(this.selectedFile)
    },
  },
  mounted() {
    //console.log(this.getDocuments)

  }
}
</script>

<style scoped>

</style>