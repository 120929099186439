<template>
  <v-row>
    <v-col cols="auto">
      <v-dialog v-model="dialog"
          transition="dialog-top-transition"
          max-width="600" persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              depressed
              v-bind="attrs"
              v-on="on"
              class="blue--text font-weight-bold"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
             School</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
                color="primary"
                dark
            >Add School</v-toolbar>
            <v-card-text>
              <ValidationObserver ref="observer" v-slot="{ handleSubmit, reset  }">
                <form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">
                  <v-row>
                    <v-col cols="12"  xs="12" sm="12" md="6" >
                      <div>
                        <ValidationProvider name="School Level" rules="required" v-slot="{ errors }" >

                          <v-select
                              :error-messages="errors"
                              :items="getSchoolType"
                              label="School Level"
                              hide-details="auto"
                              v-model="schoolLevelId"
                              item-text="name"
                              item-value="id"
                          ></v-select>


                        </ValidationProvider>
                      </div>
                    </v-col>
                    <v-col cols="12"  xs="12" sm="12" md="6" >
                      <div>
                        <ValidationProvider name="School" rules="required" v-slot="{ errors }" >
                          <v-autocomplete
                              v-model="schoolId"
                              :items="getFilteredSchools"
                              :error-messages="errors"
                              hide-details="auto"
                              clearable
                              hide-selected
                              item-text="schoolName"
                              item-value="schoolId"
                              label="School"
                              v-if="!otherSchool"
                          >
                          </v-autocomplete>

                          <v-text-field
                              v-else
                              label="School Name"
                              :error-messages="errors"
                              hide-details="auto"
                              v-model="schoolName"
                          ></v-text-field>
                        </ValidationProvider>
                        <v-checkbox
                            hide-details="auto"
                            v-model="otherSchool"
                            label="I don't see my school"
                            class="small"
                            style="font-size: 10px; margin-top: 0"
                        ></v-checkbox>
                      </div>
                    </v-col>
                    <v-col cols="12"  xs="12" sm="12" md="6" >
                      <div>
                        <ValidationProvider name="Date Started" rules="required" v-slot="{ errors }" >
                          <v-menu
                              ref="startPicker"
                              v-model="startPicker"
                              :close-on-content-click="false"
                              :return-value.sync="startDate"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="startDate"
                                  label="Year/Month Started"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="startDate"
                                no-title
                                scrollable
                                type="month"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="startPicker = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.startPicker.save(startDate)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>

                        </ValidationProvider>
                      </div>
                    </v-col>
                    <v-col cols="12"  xs="12" sm="12" md="6" >
                      <div>
                        <ValidationProvider name="Date Completed" rules="required" v-slot="{ errors }" >
                          <v-menu
                              ref="endPicker"
                              v-model="endPicker"
                              :close-on-content-click="false"
                              :return-value.sync="endDate"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="endDate"
                                  label="Year/Month Completed"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="endDate"
                                type="month"
                                no-title
                                scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="endPicker = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.endPicker.save(endDate)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>

                        </ValidationProvider>
                      </div>
                    </v-col>
                    <v-col cols="12"  xs="12" sm="12" md="6" >
                      <div>
                        <ValidationProvider name="Awards Earned" rules="" v-slot="{ errors }" >
                          <v-text-field
                              label="Awards Earned"
                              :error-messages="errors"
                              hide-details="auto"
                              v-model="awardsEarned"
                          ></v-text-field>

                        </ValidationProvider>
                      </div>
                    </v-col>
                    <v-col  cols="12"  xs="12" sm="12" md="6" >
                      <div>
                        <ValidationProvider name="Credits Earned" rules="" v-slot="{ errors }" >
                          <v-text-field
                              label="Credits Earned"
                              :error-messages="errors"
                              hide-details="auto"
                              v-model="creditsEarned"
                          ></v-text-field>

                        </ValidationProvider>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex justify-end mt-4" >

                      <v-btn
                          text
                          @click="dialog.value = false"
                      >Cancel</v-btn>
                      <v-btn
                          color="primary"
                          type="submit"> Add</v-btn>
                    </v-col>
                  </v-row>
                </form>
              </ValidationObserver>

            </v-card-text>
<!--            <v-card-actions class="justify-end">
              <v-btn
                  text
                  @click="dialog.value = false"
              >Cancel</v-btn>
              <v-btn
                  color="primary"

                  type="submit"> Add</v-btn>
            </v-card-actions>-->
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters, mapState} from "vuex";
export default {
  name: "addSchool",
  data:() => ({
    active: false,
    dialog: false,
    startPicker: false,
    endPicker: false,
    otherSchool: false,
    schoolName: '',
    schoolLevelId: null,
    schoolId: null,
    startDate: '',
    endDate: '',
    awardsEarned: '',
    creditsEarned: '',
    currentSchoolList: []
  }),
  watch: {
    schoolLevelId: function (n, o) {
      //this.currentSchoolList = this.FilterSchools(n)
      this.$store.dispatch('utilities/FilterSchoolByType', {
        id: n
      })
      // console.log(this.currentSchoolList)
    },
    endDate: function (n, o){
      console.log(n)
      console.log(new Date(n))
    }
  },
  computed: {
    ...mapState({
      getSchoolType: state => state.utilities.schoolTypes,
      getFilteredSchools: state => state.utilities.filteredSchool
    }),
    ...mapGetters ({
      FilterSchools: 'utilities/FilterSchoolByType'
    })
  },
  mounted() {
    this.$refs.observer.reset();
  },
  methods: {
    formatDate(x){
      //let y = x.split('-')

      return  new Date(x)
    },
    onSubmit(){
      this.$refs.observer.validate()

        this.$store.dispatch('user/AddPreviousSchool', {

          //"pin": string,
          "schoolTypeId": this.schoolLevelId,
          "schoolId": this.otherSchool ? 0 : this.schoolId ,
          "schoolName": this.otherSchool ? this.schoolName : "",
          //"schoolName": "string",
          //"area": "string",
          "award": this.awardsEarned,
          "credit": this.creditsEarned,
          "startDate": this.formatDate(this.startDate),
          "endDate": this.formatDate(this.endDate)
        }).then(response =>{
          this.$refs.observer.reset();
          this.schoolName = ""
          this.schoolId = null
          this.schoolTypeId = null
          this.awardsEarned = "";
          this.creditsEarned = null
          this.startDate = null
          this.endDate = null
          this.dialog = false
          this.action = false
          this.text = response.schoolName + " was successfully added "
          this.text = "Something went wrong, please ensure that all fields are filled out properly"
          this.color = "red"
          this.snackbar = true
          this.$store.dispatch('user/GetPreviousSchools')

        }).catch(error=> {
          this.text = "Something went wrong, please ensure that all fields are filled out properly"
          this.color = "red"
          this.snackbar = true
        })

    }
  }
}
</script>

<style scoped>
.small > label{
  font-size: 10px;
}
</style>
