import {getAuthData} from "@/services/storeService";
import store from "@/store";


export default {

    async GetApplicationProcesses ({ state, commit, rootState }) {
        getAuthData('Applicant/GetApplicationProcesses?', 'user/setApplicationProcess')
    },
    async GetApplicantPercent ({ state, commit, rootState }) {
        getAuthData('Applicant/GetApplicationPercent?', 'user/setApplicantPercent')
    },
    async GetApplicantUserInfo ({ state, commit, rootState }) {
        getAuthData('Account/GetApplicantUserInfo?', 'user/setApplicantUserInfo')
    },

    GetApplicantPercent2({  rootState  }){
        //console.log(rootState.user)
        return new Promise(async (resolve, reject) => {
            fetch(rootState.api + `Account/GetApplicationPercent?` + new URLSearchParams({ pin: rootState.user.pin }), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            })
                .then(async data => {
                    if(!data.ok) throw new Error(data.status);
                    const apiData = await data.json()
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    CreateApplicantPin ({ state, commit, rootState, rootGetters }, payload) {
        return new Promise(async (resolve, reject) => {
            //console.log(payload.programInstanceId)
           // const currentProgram = await rootGetters["program/FilterProgram"](payload.programInstanceId)
            //console.log(currentProgram[0])
            await fetch(rootState.api + 'Applicant/CreateApplicantPin', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify({
                    emailAddress: payload.emailAddress,
                    genderId: payload.genderId,
                    campusId: payload.campusId,
                    degreeId: payload.degreeId,
                    programId: payload.programId,
                    termId: payload.termId,
                })
            })
                .then(async (data) => {
                    //const apiData = data.json()
                    if (data.status === 400) {
                        await data.json().then(response => {
                            resolve({data, info: response})
                        })

                    }
                    else if(data.status === 200){
                        await data.json().then(response => {
                            console.log(response)
                            resolve({data, info: response})
                            //commit('setUser', response)
                        })
                    }

                })
                .catch(error => {
                    console.log(error);
                });
        })
    },
    GetApplicantPin({  commit, dispatch, rootState  }){
        return new Promise(async (resolve, reject) => {
            fetch(rootState.api + 'Account/GetApplicantPin', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            })
                .then(async data => {
                    if(!data.ok) throw new Error(data.status);
                    const apiData = await data.json()
                    //console.log(apiData)
                    dispatch('SetPin', {pin: apiData})
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    SetPin({commit}, payload){
        commit('setPin', localStorage)
    },
     UpdateApplicantProcessStep ({ state, commit, rootState, rootGetters }, payload) {
        return new Promise(async (resolve, reject) => {
            const currentProgram = await rootGetters["program/FilterProgram"](payload.programInstanceId)
           // console.log(currentProgram[0])
            payload["Pin"] = rootState.user.pin
            await fetch(rootState.api + 'Applicant/UpsertApplicantProcessStep', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async (data) => {
                    //if(!data.ok) throw new Error();
                    //const apiData = data.json()

                     if(data.status === 200){
                        await data.json().then(response => {
                            //console.log(response)
                            commit('setApplicationProcess', response)
                            resolve({data, info: response})
                        })
                    }
                    else if (data.status === 400) {
                        await data.json().then(response => {
                            reject({data, info: response})
                        })
                    }

                    if(!data.ok) throw new Error();
                })
                .catch(error => {
                   // console.log(error);
                });
        })
    },
    async MoveToNextStep({state, commit, rootState, rootGetters}, payload) {
        console.log(payload)
        /*
        console.log(state)
        console.log(rootState)*/
        let x = await rootState.user.appProcesses.filter((item, index) => {
            /*console.log(item.processName.split(" ").join(""))
            console.log(payload.route)*/
            //console.log(payload.route.path.split("/")[1])
            return item.processName.split(" ").join("") === payload.route.path.split("/")[1]
        })
        console.log(x[0]?.stepOrder)
        commit('setCurrentNavIndex', x[0]?.stepOrder)
        //alert(payload)
        //n.processName.split(" ").join("")
    },
     CreateAccount ({ state, commit, rootState, rootGetters }, payload) {
        return new Promise(async (resolve, reject) => {
            //console.log(payload)
            const currentProgram = await rootGetters["program/FilterProgram"](payload.programInstanceId)
            /*console.log("-------====-------")
            console.log(currentProgram)*/
            await fetch(rootState.api + 'Account/CreateAccount', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    emailAddress: payload.emailAddress,
                    Phone: payload.Phone,
                    password: payload.password,
                    firstName: payload.firstName,
                    middleName: "",
                    lastName: payload.lastName,
                    genderId: payload.genderId,
                    campusId: payload.campusId,
                    termId: payload.termId,
                    degreeId: payload.degreeId,
                    programId: payload.programId,
                    departmentId: payload.departmentId,
                    ActivationLink: process.env.VUE_APP_URL + "AccountVerification?code="
                })
            })
                .then(async (data) => {
                    //if(!data.ok) throw new Error();
                    //const apiData = data.json()

                     if(data.status === 200){
                        await data.json().then(response => {
                            //console.log(response)
                            //commit('setUser', response)
                            resolve({data, info: response})
                        })
                    }
                    else if (data.status === 400) {
                        await data.json().then(response => {
                            reject({data, info: response})
                        })
                    }

                    if(!data.ok) throw new Error();
                })
                .catch(error => {
                   // console.log(error);
                });
        })
    },
      CreateAccountFromSearch ({ state, commit, rootState, rootGetters }, payload) {
        return new Promise(async (resolve, reject) => {

           // console.log(currentProgram[0])
            await fetch(rootState.api + 'Account/CreateAccount', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    emailAddress: payload.emailAddress,
                    Phone: payload.Phone,
                    password: payload.password,
                    firstName: payload.firstName,
                    middleName: "",
                    lastName: payload.lastName,
                    genderId: payload.genderId,
                    campusId: payload.campusId,
                    degreeId: payload.degreeId,
                    programId: payload.programId,
                    termId: payload.termId,
                    ActivationLink: process.env.VUE_APP_URL + "AccountVerification?code="
                })
            })
                .then(async (data) => {
                    //if(!data.ok) throw new Error();
                    //const apiData = data.json()

                     if(data.status === 200){
                        await data.json().then(response => {
                           // console.log(response)
                            commit('setUser', response)
                            resolve({data, info: response})
                        })
                    }
                    else if (data.status === 400) {
                        await data.json().then(response => {
                            reject({data, info: response})
                        })
                    }

                    if(!data.ok) throw new Error();
                })
                .catch(error => {
                   // console.log(error);
                });
        })
    },

     AccountVerification({ state, commit, rootState, rootGetters }, payload){
        return new Promise(async (resolve, reject) => {
            fetch(rootState.api + 'Account/ActivateAccount?' + new URLSearchParams({
                activationCode: payload.code
            }), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                //body: JSON.stringify(code)
            })
                .then(async data => {
                    if(!data.ok) throw new Error(data.status);
                    const apiData = await data.json()
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {


                reject(error)
            })
        })
    },
    ForgotPassword({ state, commit, rootState, rootGetters }, payload){
        return new Promise(async (resolve, reject) => {
            fetch(rootState.api + 'Account/RequestPasswordReset?', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {

                    const apiData = await data.json()
                    if(!data.ok) throw new Error(apiData);
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {


                reject(error)
            })
        })
    },
    ResendVerificationLink({ state, commit, rootState, rootGetters }, payload){
        return new Promise(async (resolve, reject) => {
            fetch(rootState.api + 'Account/RequestNewActivationLink?', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {

                    const apiData = await data.json()
                    if(!data.ok) throw new Error(apiData);
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {


                reject(error)
            })
        })
    },
    ChangePassword({ state, commit, rootState, rootGetters }, payload){
        return new Promise(async (resolve, reject) => {
            fetch(rootState.api + 'Account/ChangePassword', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    if(!data.ok) throw new Error(data.status);
                    const apiData = await data.json()
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
     Login({ state, commit, rootState, rootGetters }, payload){
        return new Promise(async (resolve, reject) => {
            fetch(rootState.api + 'Account/Login?', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: payload.emailAddress,
                    password: payload.password
                })
            })
                .then(async data => {

                    const apiData = await data.json()
                    if(!data.ok) throw new Error(apiData);
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },



    async GetPersonalInformation ({ state, commit, rootState }) {
        getAuthData('Applicant/GetPersonalInformation?', 'user/setPersonalInformation')
    },
    async GetContactInformation ({ state, commit, rootState }) {
        getAuthData('Applicant/GetContactInformation?', 'user/setContactInformation')
    },
    async GetProgramInformation ({ state, commit, rootState }) {
        getAuthData('Applicant/GetProgram?', 'user/setProgram')
    },
    async GetPreviousSchools ({ state, commit, rootState }) {
        getAuthData('Applicant/GetPreviousSchools?', 'user/setPreviousSchools')
    },
    async GetApplicantRequiredDocuments ({ state, commit, rootState }) {
        getAuthData('Applicant/GetApplicantRequiredDocuments?', 'user/setRequiredDocuments')
    },
    async GetApplicantDocuments ({ state, commit, rootState }) {
        getAuthData('Applicant/GetApplicantUploadedRequiredDocuments?', 'user/setApplicationDocuments')
    },
    async GetApplicantUploadedOtherDocuments ({ state, commit, rootState }) {
        getAuthData('Applicant/GetApplicantUploadedOtherDocuments?', 'user/setApplicationOtherUploadedDocuments')
    },
    async GetSourceOfFunding ({ state, commit, rootState }) {
        getAuthData('Applicant/GetApplicantSourceOfFunds?', 'user/setSourceOfFunds')
    },
    async GetApplicantDeclarations ({ state, commit, rootState }) {
        getAuthData('Applicant/GetApplicantDeclarations?', 'user/setAppDeclarations')
    },
    async GetApplicationPaymentStatus ({ state, commit, rootState }) {
        getAuthData('Applicant/GetApplicationStatus?', 'user/setApplicationPaymentStatus')
    },

    async GetApplicationCost ({ state, commit, rootState }) {
        getAuthData('Applicant/GetApplicationCost?', 'user/setApplicationCost')
    },
    async GetExternalPasses ({ state, commit, rootState }) {
        getAuthData('Applicant/GetExternalPasses?', 'user/setExternalPasses')
    },
    async GetApplicantPins ({ state, commit, rootState }) {
        getAuthData('Account/GetApplicantPinsWithProgram?', 'user/setApplicantPins')
    },
    async GetApplicantOtherDocuments ({ state, commit, rootState }) {
        getAuthData('Applicant/GetApplicantOtherDocuments?', 'user/setOtherDocuments')
    },
    async GetApplicationPaymentReceipt ({ state, commit, rootState }, payload) {
        //getAuthData('Applicant/GetApplicationPaymentReceipt?', 'user/setReceiptInfo')
        fetch(store.state.api + 'Applicant/GetApplicationPaymentReceipt?' + new URLSearchParams({ referenceNo: payload.referenceNo }), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }).then(async (data) =>{
            await data.json().then(response => {
                //console.log(response)
                store.commit('user/setReceiptInfo', response)

            })
        }).catch(error => {
            //console.log(error);
        })
    },


    UpdatePersonalInformation({ state, commit, rootState, rootGetters }, payload){
        //console.log(payload)
         const personalInfo = rootState.user.personal
        return new Promise(async (resolve, reject) => {

            payload["Pin"] = rootState.user.pin
            //personalInfo.emergencyContactRelationId !== 0 ? payload["emergencyContactRelationId"] = personalInfo.emergencyContactRelationId : ""
            payload["disabilityId"] = personalInfo.disabilityId
            fetch(rootState.api + 'Applicant/UpsertPersonalInformation', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    const apiData = await data.json()
                    if(!data.ok) throw new Error(apiData);
                    //console.log(apiData)
                    commit('setPersonalInformation', apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    UpdateContactInformation({ state, commit, rootState, rootGetters }, payload){

        return new Promise(async (resolve, reject) => {

            fetch(rootState.api + 'Applicant/UpsertContactInformation', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    const apiData = await data.json()
                    if(!data.ok) throw new Error(apiData);
                    //console.log(apiData)
                    commit('setContactInformation', apiData)
                   // console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    async UpdateProgramInformation({state, commit, rootState, rootGetters}, payload) {
        //console.log(payload)
        const currentProgram = await rootGetters["program/FilterProgram"](payload.programInstanceId)
        console.log(currentProgram)
        return new Promise(async (resolve, reject) => {
           // payload["programId"] = currentProgram[0].programId
            //payload["degreeId"] = currentProgram[0].degreeId
            fetch(rootState.api + 'Applicant/UpsertProgram', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    const apiData = await data.json()
                    if(!data.ok) throw new Error(apiData);
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    async AddPreviousSchool({state, commit, rootState, rootGetters}, payload) {
        const currentProgram = await rootGetters["program/FilterProgram"](payload.programInstanceId)
        //console.log(currentProgram)
        return new Promise(async (resolve, reject) => {
            payload["pin"] = rootState.user.pin
            fetch(rootState.api + 'Applicant/UpsertApplicantPreviousSchool', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    if (!data.ok) throw new Error(data.status);
                    const apiData = await data.json()
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    async DeletePreviousSchool({state, commit, rootState, rootGetters}, payload) {
        return new Promise(async (resolve, reject) => {
            payload["pin"] = rootState.user.pin
            fetch(rootState.api + 'Applicant/DeleteApplicantPreviousSchool', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    if (data.status === 200){
                        resolve("apiData");
                    }
                    else{
                        throw new Error(data.status)
                    }
                }).catch(async error => {
                    reject(error);
            })
        })
    },
    async AddExternalPass({state, commit, rootState, rootGetters}, payload) {
        const currentProgram = await rootGetters["program/FilterProgram"](payload.programInstanceId)
        //console.log(currentProgram)
        return new Promise(async (resolve, reject) => {
            payload["pin"] = rootState.user.pin
            fetch(rootState.api + 'Applicant/UpsertApplicantExternalPass', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    if (!data.ok) throw new Error(data.status);
                    const apiData = await data.json()
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    async DeleteExternalPass({state, commit, rootState, rootGetters}, payload) {
       // const currentProgram = await rootGetters["program/FilterProgram"](payload.programInstanceId)
        //console.log(currentProgram)
        return new Promise(async (resolve, reject) => {
            payload["pin"] = rootState.user.pin
            fetch(rootState.api + 'Applicant/DeleteApplicantExternalPass', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    if (data.status === 200){
                        resolve("apiData");
                    }
                    else{
                        throw new Error(data.status)
                    }

                }).catch(async error => {
                    reject(error);
            })
        })
    },
    async AddDocumentsByType({state, commit, rootState, rootGetters}, payload) {

        return new Promise(async (resolve, reject) => {
            //console.log(payload)
            payload.append("Pin", rootState.user.pin)
            fetch(rootState.api + 'Applicant/UpsertApplicantDocument', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    //'Content-Type' : 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: payload
            })
                .then(async data => {
                    const apiData = await data.json()
                    if(!data.ok) throw new Error(apiData);
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {

                reject(error)
            })
        })
    },
    async RemoveApplicantDocument({state, commit, rootState, rootGetters}, payload) {
        const currentProgram = await rootGetters["program/FilterProgram"](payload.programInstanceId)
        //console.log(currentProgram)
        return new Promise(async (resolve, reject) => {
            payload["pin"] = rootState.user.pin
            fetch(rootState.api + 'Applicant/RemoveApplicantDocument', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {

                    resolve()
                }).catch(async error => {
                reject(error)
            })
        })
    },
    async PayApplicationFee({state, commit, rootState, rootGetters}, payload) {
        return new Promise(async (resolve, reject) => {
            payload["pin"] = rootState.user.pin
            fetch(rootState.api + 'Applicant/PayApplicationFee', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    const apiData = await data.json()
                    if(!data.ok) throw new Error(apiData);
                    //console.log(apiData)
                    resolve({data: apiData, code: apiData.status})
                }).catch(async error => {
                reject(error)
            })
        })
    },
    async AddSourceOfFunding({state, commit, rootState, rootGetters}, payload) {
        const currentProgram = await rootGetters["program/FilterProgram"](payload.programInstanceId)
        //console.log(currentProgram)
        return new Promise(async (resolve, reject) => {
            payload["pin"] = rootState.user.pin
            fetch(rootState.api + 'Applicant/UpsertApplicantSourceOfFunds', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    const apiData = await data.json()
                    if(!data.ok) throw new Error(apiData);
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    async DeleteApplicantSourceOfFunds({state, commit, rootState, rootGetters}, payload) {
        const currentProgram = await rootGetters["program/FilterProgram"](payload.programInstanceId)
        //console.log(currentProgram)
        return new Promise(async (resolve, reject) => {
            payload["pin"] = rootState.user.pin
            fetch(rootState.api + 'Applicant/DeleteApplicantSourceOfFunds', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    const apiData = await data.json()
                    if(!data.ok) throw new Error(apiData);
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    UpsertApplicantDeclarations({ state, commit, rootState, rootGetters }, payload){

        return new Promise(async (resolve, reject) => {
           // console.log(payload)
            const arr = [];
            for (let i = 0; i < Object.keys(payload).length; i++){
               // console.log(Object.values(payload)[i])
                let b = Object.values(payload)[i]
                b["pin"] = rootState.user.pin
                //["terms"  + (i+1)["pin"]] = rootState.user.pin
                arr.push(b)
                b = {}
            }
           // console.log(payload)

            //payload.terms2["pin"] = rootState.user.pin



            fetch(rootState.api + 'Applicant/UpsertApplicantDeclarations', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(arr)
            })
                .then(async data => {
                    const apiData = await data.json()
                    if(!data.ok) throw new Error(apiData);

                    commit('setApplicantDeclarations', apiData)
                    // console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    async UpsertApplicantPayment({state, commit, rootState, rootGetters}, payload) {
        return new Promise(async (resolve, reject) => {
            //const personalInfo = rootState.user.personal
            payload["Pin"] = rootState.user.pin
            fetch(rootState.api + 'Applicant/UpsertApplicantPayment', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    if (!data.ok) throw new Error(data.status);
                    const apiData = await data.json()
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    async UpsertPaymentReceipt({state, commit, rootState, rootGetters}, payload) {
        console.log(payload)
        return new Promise(async (resolve, reject) => {
            //const personalInfo = rootState.user.personal
            payload.append("Pin", rootState.user.pin)
            fetch(rootState.api + 'Applicant/UpsertPaymentReceipt', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    //'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: payload
            })
                .then(async data => {
                    const apiData = await data
                    //if(!data.ok) throw new Error(apiData);
                    //console.log(apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    async IsStaff({  commit, dispatch, rootState  }){
        return new Promise(async (resolve, reject) => {
            fetch(rootState.api + 'Account/IsStaff', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            })
                .then(async data => {
                    if(!data.ok) throw new Error(data.status);
                    const apiData = await data.json()
                    //console.log(apiData)
                    commit('SetStaffStatus', {apiData})
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    async GetActiveAgents({  commit, dispatch, rootState  }){
        return new Promise(async (resolve, reject) => {
            fetch(rootState.api + 'Staff/GetActiveAgents', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            })
                .then(async data => {
                    if(!data.ok) throw new Error(data.status);
                    const apiData = await data.json()
                    //console.log(apiData)
                    commit('setAgents', apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    async AssignInterestToAgentByDepartment({  commit, dispatch, rootState  }, payload){
        console.log(payload)
        return new Promise(async (resolve, reject) => {
            fetch(rootState.api + 'Staff/AssignInterestToAgentByDepartment', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    if(!data.ok) throw new Error(data.status);
                    const apiData = await data.json()
                    //console.log(apiData)
                    //commit('AssignInterestToAgentByDepartment', apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
    async AssignInterestToAgentByCollege({  commit, dispatch, rootState  }, payload){
        console.log(payload)
        return new Promise(async (resolve, reject) => {
            fetch(rootState.api + 'Staff/AssignInterestToAgentByCollege', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(payload)
            })
                .then(async data => {
                    if(!data.ok) throw new Error(data.status);
                    const apiData = await data.json()
                    //console.log(apiData)
                    //commit('AssignInterestToAgentByDepartment', apiData)
                    resolve(apiData)
                }).catch(async error => {
                reject(error)
            })
        })
    },
}
