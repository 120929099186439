<template>
  <v-row >
    <v-col cols="12 mt-3">

      <v-list
          subheader
          two-line
          elevation="5"
          dense rounded
      >
        <v-subheader>Your Previous Schools</v-subheader>
        <v-alert
            dense
            text
            type="primary" v-if="getPreviousSchool.length === 0"
        >
          <span style="font-size: small">You have not added any schools as yet</span>
        </v-alert>

        <v-list-item v-for="(item, i) in getPreviousSchool" :key="i">
          <v-list-item-content>
            <v-list-item-title class="blue--text">{{ item.schoolName }}</v-list-item-title>
            <v-list-item-subtitle class="green--text">
              {{ showDate(item.startDate) + " -> " + showDate(item.endDate) +
            (item.award ? " -> " + item.award : "") + (item.credit ? " -> " + item.credit : "") }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon
                size="20"
                color="red lighten-1"
                @click="deleteSchool(item.id)"
            >
              mdi-delete
            </v-icon>
          </v-list-item-action>
        </v-list-item>

      </v-list>
      <br>
      <v-list
          dense
          elevation="5"
          subheader
          two-line
          rounded
      >
        <v-subheader>Your External Passes</v-subheader>
        <v-alert
            dense
            text
            type="primary" v-if="getExternalPasses.length === 0"
        >
          <span style="font-size: small">You have not added any external passes as yet</span>
        </v-alert>

        <v-list-item v-for="(item, i) in getExternalPasses" :key="i">
          <v-list-item-content>
            <v-list-item-title class="blue--text">{{ item.subjectName }}</v-list-item-title>
            <v-list-item-subtitle class="green--text">
              {{ item.examBoardName + " -> " + item.examProficiencyName + " -> " + item.subjectName + " -> Grade: " + item.grade + " -> Year: " + item.year
            /*(item.award ? " -> " + item.award : "") + (item.credit ? " -> " + item.credit : "")*/ }}
            </v-list-item-subtitle>

          </v-list-item-content>
          <v-list-item-action>
            <v-icon
                size="20"
                color="red lighten-1"
                @click="deletePass(item.id)"
            >
              mdi-delete
            </v-icon>
          </v-list-item-action>

        </v-list-item>

      </v-list>
<!--      <div>
        <br>
        <v-skeleton-loader  v-if="getPreviousSchool.length === 0"
            type="image"
        ></v-skeleton-loader>
        <br>
        <v-skeleton-loader  v-if="getExternalPasses.length === 0"
            type="image"
        ></v-skeleton-loader>

      </div>-->
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col class="d-flex justify-end " >
          <v-btn :disabled="this.getPreviousSchool.length === 0 || this.getExternalPasses.length === 0"
              color="primary" :loading="loading"
              @click="updateSchoolStep"> Mark Complete</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {updateProcessStep} from "@/services/userService";
export default {

  name: "PreviousSchools",
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState ({
      getPreviousSchool: state => state.user.previousSchools,
      getExternalPasses: state => state.user.externalPasses
    }),
    ...mapGetters({
      currentStep: state => state.user["user/getCurrentAppProcess"](this.$route)
    })
  },
  methods: {
    async updateSchoolStep() {
      /*updateProcessStep((this.currentStep), true)
          .then(response => {
            this.$store.dispatch('user/GetApplicantPercent')
            this.$store.dispatch('user/MoveToNextStep', {route: this.$route})
          })*/
      updateProcessStep((await this.$store.getters["user/getCurrentAppProcess"](this.$route)), true).then(response => {
        this.$store.dispatch('user/GetApplicantPercent')
        this.$store.dispatch('user/MoveToNextStep', {route: this.$route})
      })
      /*this.$store.dispatch('user/UpdateApplicantProcessStep',{
        "applicationProcessId": 4,
        "isCompleted": true
      }).then(response => {
        this.$store.dispatch('user/GetApplicantPercent')
        this.$store.dispatch('user/MoveToNextStep', {route: this.$route})
        //this.$router.push('/RequiredDocuments')
      })*/

    },
    showDate(x){
      return (new Date(x)).toLocaleDateString('en-US')
    },

    deleteSchool(id){
      let self = this;
      this.$store.dispatch('user/DeletePreviousSchool', {
        id: id
      }).then(response => {
        this.$store.dispatch('user/GetPreviousSchools')
      })
    },
    deletePass(id){
      let self = this;
      this.$store.dispatch('user/DeleteExternalPass', {
        id: id
      }).then(response => {
        self.$store.dispatch('user/GetExternalPasses')
      })
    }
  },
  mounted() {
    this.$store.dispatch('user/GetApplicantPercent')
  }
}
</script>

<style scoped>

</style>
