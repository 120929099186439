<script>
  export default{
    data(){
      return {
        drawer: null
      }
    },
    mounted() {
      this.$store.dispatch('user/IsStaff').then(r =>{
        /*console.log(r)
        console.log(typeof r)*/
        if(r){

          this.$store.dispatch("user/GetActiveAgents")
          this.$store.dispatch("program/GetProgramDataForSearch")
          this.$store.dispatch("utilities/GetDepartments")
          this.$store.dispatch("utilities/GetColleges")
        }else {
          this.$router.push('/current_application')

        }

      }).catch(error=>{
        this.$router.push('/current_application')
        //this.loading = false
      })
    },

  }
</script>

<template>
  <v-app id="inspire">

    <v-navigation-drawer
        v-model="drawer"
        app
        class="pt-4 "
        color="grey lighten-3"
        mini-variant
    >




      <div class="d-flex align-items-start flex-column" style="height: calc(100vh - 50px);">
        <div class="mb-auto">
          <v-avatar class="d-block text-center mx-auto mb-9">
            <img
                src="@/assets/aeorion_logo.png"
                alt="Aeorion" style="max-height: 40px; max-width: 40px; width: auto; height: auto"
            >
          </v-avatar>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon large to="/admin/program-info"
                     class="d-block text-center mx-auto mb-3 pa-2"
                     color="primary" dark v-bind="attrs"
                     v-on="on">
                <v-icon>
                  mdi-blur
                </v-icon>
              </v-btn>
            </template>
            <span>Program Information</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="d-block text-center mx-auto mb-3 pa-2" color="primary" v-bind="attrs"
                     v-on="on" to="/admin/prospects">
                <v-icon>
                  mdi-account-school
                </v-icon>
              </v-btn>
            </template>
            <span>Prospect Management</span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="d-block text-center mx-auto mb-3 pa-2" color="primary" v-bind="attrs" to="/admin/reports"
                     v-on="on">
                <v-icon>
                  mdi-chart-line
                </v-icon>
              </v-btn>
            </template>
            <span>Reports</span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="d-block text-center mx-auto mb-3 pa-2" color="primary" v-bind="attrs" href="https://apply.ncu.edu.jm/api/systemjobs"
                     v-on="on">
                <v-icon>
                  mdi-store-cog
                </v-icon>
              </v-btn>
            </template>
            <span>System Jobs</span>
          </v-tooltip>
        </div>
        <div>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="d-block text-center mx-auto mb-3 pa-2" color="red" v-bind="attrs"
                     to="/logout"
                     v-on="on">
                <v-icon>
                  mdi-logout
                </v-icon>
              </v-btn>
            </template>
            <span>Logout</span>
          </v-tooltip>
        </div>
      </div>



    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>

</template>

<style scoped>

</style>
