<template>
  <div>
    <v-container class="py-3 fill-height">
      <div class="d-flex justify-md-space-between">

        <div><h2>{{ $route.name }}</h2></div>
        <small-nav class="d-lg-none "/>
      </div>
      <help-modal :source="

       this.$route.path === '/PersonalInformation' ? tutorials[0] :
       this.$route.path === '/ContactInformation' ? tutorials[1] :
       this.$route.path === '/Declaration&Agreement' ? tutorials[2] :
       this.$route.path === '/ProgrammeSelection' ? tutorials[3] :
       this.$route.path === '/RequiredDocuments' ? tutorials[4] :
       this.$route.path === '/PreviousSchools' ? tutorials[5] :
       this.$route.path === '/PayApplicationFee' ? tutorials[6] :
       this.$route.path === '/OtherDocuments' ? tutorials[4] :
       tutorials[0]
        "/>

<!--      <v-btn
          color="primary"
          dark text target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSfaEm0KmrvsPvi6010DhUUQtIkr41DyswhRB-3pTWh3q_liHQ/viewform"
      >
        Leave some feedback?
      </v-btn>-->
      <v-spacer></v-spacer>
      <div v-if="$route.path === '/PreviousSchools'" class="d-flex flex-row" >
        <div style="margin-right: 10px">
          <add-pass />
        </div>
        <div>
          <add-school />
        </div>
      </div>
      <div v-if="$route.path === '/SourceofFunding'" class="d-flex flex-row">
        <div style="margin-right: 10px">
          <add-source-of-funding />
        </div>
      </div>
    </v-container>
    <v-divider></v-divider>
  </div>

</template>

<script>
import AddSchool from "@/components/addSchool";
import AddPass from "@/components/addPass";
import AddSourceOfFunding from "@/components/addSourceOfFunding";
import SmallNav from "@/components/smallNav";
import HelpModal from "@/components/helpModal";
export default {
  name: "TopBar",
  components: {HelpModal, SmallNav, AddSourceOfFunding, AddPass, AddSchool},
  data: () => ({
    sourceLink: '',
    tutorials: [
      "https://www.youtube.com/embed/Kkah_gnjIpE", //personal
     "https://www.youtube.com/embed/N3fFQheAuQw", //contact
      "https://www.youtube.com/embed/cRCze0dAIIw", //declaration
       "https://www.youtube.com/embed/TRQPoKqVndQ", //programmeSelection
       "https://www.youtube.com/embed/Ahv771-WB8I", //requiredDoc
      "https://www.youtube.com/embed/EM-BEhaHWtk", //previousEd
      "https://www.youtube.com/embed/WXrpORxsvTY", //payApplicationFee
    ]
  }),
  watch: {
    $route (to, from){
      //console.log(to)
      if (to.path === '/ProgrammeSelection'){
        this.sourceLink = this.tutorials[3]
      }
      this.show = false;
    }
  },
  mounted() {
    if (this.$route.path === '/ProgrammeSelection'){
      this.sourceLink = this.tutorials[3]
    }
  }
}
</script>

<style scoped>

</style>