<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";
import AddProgramInformation from "@/components/admin/AddProgramInformation.vue";
import ManageCareers from "@/components/admin/ManageCareers.vue";
import WhatWillYouLearn from "@/components/admin/WhatWillYouLearn.vue";
import ManageFrequentlyAskQuestions from "@/components/admin/ManageFrequentlyAskQuestions.vue";
import AdminAppBar from "@/components/admin/AdminAppBar.vue";


export default defineComponent({
  name: "AdminProgramInformation",
  components: {AdminAppBar, ManageFrequentlyAskQuestions, WhatWillYouLearn, ManageCareers, AddProgramInformation},
  data: () => ({
    programFilter: "",
    ProgramDescription: '',
    TuitionCost: '',
    TuitionCurrency: '',
    Duration: '',
    DurationType: '',
    ProgramOverview: '',
    Careers: '',
    Learning: '',
    Faqs1: '',
    Faas1: '',
    Faqs2: '',
    Faas2: '',
    Faqs3: '',
    Faas3: '',

    items: [
      'Info', 'FAQ', 'What you will learn', 'Careers',
    ],
    tab: null,
  }),
  watch: {
    programFilter: {
      async handler(n, o){
        console.log(n)
        this.$store.dispatch("program/GetAdditionalProgramInformation", n?.programId)

      }
    },
    getPrograms: {
      async handler(n, o) {
        if (this.$route.query.programId) {
          let b = await n.filter(x => {
            if (x.programId === parseInt(this.$route.query.programId)) {
              return x
            }
          })
          if (b.length === 1) {
            this.programFilter = b[0]
          }
        }
      }
    },
    currentProgram: {
      async handler(n, o){
        this.ProgramOverview = n?.overview
        this.ProgramDescription = n?.description
        this.TuitionCost = n?.tuitionCost
        this.TuitionCurrency = n?.tuitionCurrency
        this.Duration = n?.duration
        this.DurationType = n?.durationType
      }
    }

  },
  methods: {
    callChange: function (data) {
      this.$router.push({path: "/admin/program-info/program/" + data})

    }
  },
  computed: {
    ...mapState({
      getPrograms: state => state.program.searchPrograms,
      currentProgram: state => state.program.programInfo
    }),
  },
  mounted() {
    /*if (this.$route.query.program_id){
      console.log(this.$route.query.program_id)
      this.programFilter = this.$route.query?.program_id
    }*/
  }
})
</script>

<template>
  <div class="" style="background-color: #f5f5f5">
    <AdminAppBar />
    <div style="height: calc(100vh - 70px); overflow-y: scroll">
      <div class="pa-5">
        <div v-for="(item, index) in this.getPrograms" class="my-3 mx-1">
          <v-card
              elevation="2"
          >
            <v-list-item two-line   @click="callChange(item.programId)" style="background-color: white; border-radius: 5px">
              <v-list-item-content>
                <v-list-item-title>{{ item?.programName }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ item?.degreeName }}

                </v-list-item-subtitle>

              </v-list-item-content>
            </v-list-item>
          </v-card>

        </div>
      </div>

    </div>
<!--    <v-row style="height: calc(100vh - 70px)">
      <v-col cols="12" sm="3">

      </v-col>
      <v-col cols="12" sm="6" style="height: calc(100vh - 70px); overflow-y: scroll">
        <div class="pa-3">

          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <div>
                    <ValidationProvider name="ProgramDescription" rules="required" v-slot="{ errors }" >
                      <v-textarea
                          label="Program Description"
                          :error-messages="errors"
                          solo
                          hide-details="auto"
                          auto-grow
                          v-model="ProgramDescription"
                      ></v-textarea>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6"   >
                  <div>
                    <ValidationProvider name="TuitionCost" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Tuition Cost"
                          :error-messages="errors"
                          solo dense
                          hide-details="auto"
                          v-model="TuitionCost"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6"   >
                  <div>
                    <ValidationProvider name="TuitionCurrency" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Tuition Currency"
                          :error-messages="errors"
                          solo dense
                          hide-details="auto"
                          v-model="TuitionCurrency"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6"   >
                  <div>
                    <ValidationProvider name="Duration" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Duration"
                          :error-messages="errors"
                          solo dense
                          hide-details="auto"
                          v-model="Duration"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6"   >
                  <div>
                    <ValidationProvider name="DurationType" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Duration Type"
                          :error-messages="errors"
                          solo dense
                          hide-details="auto"
                          v-model="DurationType"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <div>
                    <ValidationProvider name="ProgramOverview" rules="required" v-slot="{ errors }" >
                      <v-textarea
                          label="Program Overview"
                          :error-messages="errors"
                          solo
                          hide-details="auto"
                          auto-grow
                          v-model="ProgramOverview"
                      ></v-textarea>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <div class="ma-md-3">
                    <ValidationProvider name="Careers" rules="required" v-slot="{ errors }" >
                      <v-textarea
                          label="Careers (list and separate by ,)"
                          :error-messages="errors"
                          solo
                          hide-details="auto"
                          auto-grow
                          v-model="Careers"
                      ></v-textarea>
                    </ValidationProvider>
                  </div>
                  <div class="">
                    <v-btn color="primary">Update</v-btn>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <div>
                    <ValidationProvider name="Learning" rules="required" v-slot="{ errors }" >
                      <v-textarea
                          label="What will you learn (list and separate by ,)"
                          :error-messages="errors"
                          solo
                          hide-details="auto"
                          auto-grow
                          v-model="Learning"
                      ></v-textarea>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <div>
                    <ValidationProvider name="Faqs1" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Frequently Asked Question 1"
                          :error-messages="errors"
                          solo

                          v-model="Faqs1"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <div>
                    <ValidationProvider name="Faas1" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Frequently Asked Answer 1"
                          :error-messages="errors"
                          solo

                          v-model="Faas1"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <div>
                    <ValidationProvider name="Faqs2" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Frequently Asked Question 2"
                          :error-messages="errors"
                          solo

                          v-model="Faqs2"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <div>
                    <ValidationProvider name="Faas2" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Frequently Asked Answer 2"
                          :error-messages="errors"
                          solo

                          v-model="Faas2"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12"   >
                  <div>
                    <ValidationProvider name="Faqs3" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Frequently Asked Question 3"
                          :error-messages="errors"
                          solo

                          v-model="Faqs3"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <div>
                    <ValidationProvider name="Faas3" rules="required" v-slot="{ errors }" >
                      <v-text-field
                          label="Frequently Asked Answer 3"
                          :error-messages="errors"
                          solo

                          v-model="Faas3"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                </v-col>

              </v-row>
              <div class="pa-3">
                <div class="text-right">
                  <v-btn color="primary">Update</v-btn>
                </div>
              </div>
            </form>
          </ValidationObserver>
          {{ this.currentProgram }}

        </div>
      </v-col>
      <v-col cols="12" sm="3"></v-col>
    </v-row>-->
  </div>
</template>

<style scoped>

</style>
